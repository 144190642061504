import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import {
  TableReporting,
  TableReportingColumn,
  ColumnReporting,
  ClientData,
} from '../types'

type ContextData = {
  filters: string
  setFilters: (filters: string) => void
  tables: null | Array<TableReporting>
  id: null | string
  isFetching: boolean
  refetchTables: () => Promise<void>
  columnsView: null | TableReportingColumn[]
  upsertColumnsOnTable?: ({
    refresh,
    columns,
  }: {
    refresh?: boolean
    columns: Array<ColumnReporting>
  }) => void
  removeColumnsOnTable?: ({
    columns,
    refresh,
  }: {
    columns: Array<ColumnReporting>
    refresh: boolean
  }) => void

  createNewClient?: (payload: ClientData) => void
  removeClient?: (id: string) => void
  duplicateClient?: (id: string) => void
  clients: ClientData[]
  setId?: Dispatch<SetStateAction<string | null>>
  type?: 'campaign' | 'client'
  setLastRefreshTime: (time: number) => void
  updateCustomFieldData: ({
    columnId,
    rowId,
    payload,
  }: {
    columnId: string
    rowId: string
    payload: string
  }) => void
  views: any
  appliedView: any
  setAppliedView: any
  createNewView: any
  updateView: any
  editView: any
  duplicateView: any
  hasUnsavedChanges: any
  deleteView: any
  setView: any
  setColumnsView: any
  tableKey: any
  refreshTableKey: any
  invalidateViewDate: any
  setInvalidateViewDate: any
  setChangeTable: any
  accounts: any
  sources: any
  handleDragEnd: any
  lastRefreshTime: any
  currentTable: null | TableReporting
  loadingCurrentTable: boolean
  refetchCurrentTable: () => void
}

const defaultContextData: ContextData = {
  filters: '[]',
  setFilters: () => {
    return
  },
  id: null,
  tables: null,
  isFetching: false,
  refetchTables: async () => {
    return
  },
  columnsView: [],
  clients: [],
  setLastRefreshTime: function (_time: number): void {
    throw new Error('Function not implemented.')
  },
  updateCustomFieldData: function (_params: {
    columnId: string
    rowId: string
    payload: string
  }): void {
    throw new Error('Function not implemented.')
  },
  views: undefined,
  appliedView: undefined,
  setAppliedView: undefined,
  createNewView: undefined,
  updateView: undefined,
  editView: undefined,
  duplicateView: undefined,
  hasUnsavedChanges: undefined,
  deleteView: undefined,
  setView: undefined,
  setColumnsView: undefined,
  tableKey: undefined,
  refreshTableKey: undefined,
  invalidateViewDate: undefined,
  setInvalidateViewDate: undefined,
  setChangeTable: undefined,
  accounts: undefined,
  sources: undefined,
  handleDragEnd: undefined,
  lastRefreshTime: undefined,
  currentTable: null,
  loadingCurrentTable: false,
  refetchCurrentTable: () => {},
}

export const Context = createContext<ContextData>(defaultContextData)

export const useTableContext = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error(
      'useTableContext must be used within a TableContextProvider'
    )
  }
  return context
}
