import { format, parseISO } from 'date-fns'
import {
  calculateLastXDayPeriod,
  toIsoString,
} from '@mm/ui/src/components/DateSelectCompareCard/utils'
import { DateRanges, getDatesArray } from '@mm/utils/src/constants/ranges'
import moment from 'moment'
import { toYesterdayOptions } from '../components/DateSelectCard'

const datesArray = getDatesArray()

export interface DashboardDate {
  dateFilterRange?: string
  dateFilterFrom?: string
  dateFilterTo?: string
  dateFilterLastDays?: string
  dateFilterUntilToday?: boolean
}

export interface WidgetDateRangeProps {
  untilToday: boolean
  lastDays: string
  range: string
  from: string
  to: string
  lock?: boolean
}

export const calculateDateRange = (
  dateChosen: DashboardDate,
  widget: WidgetDateRangeProps
) => {
  if (!dateChosen) dateChosen = {}
  const dateValues = {
    range: widget.lock
      ? widget.range
      : (dateChosen.dateFilterRange ?? widget.range),
    from: widget.lock
      ? widget.from
      : (dateChosen.dateFilterFrom ?? widget.from),
    to: widget.lock ? widget.to : (dateChosen.dateFilterTo ?? widget.to),
    lastDays: widget.lock
      ? widget.lastDays
      : (dateChosen.dateFilterLastDays ?? widget.lastDays),
    untilToday: widget.lock
      ? widget.untilToday
      : (dateChosen.dateFilterUntilToday ?? widget.untilToday),
  }
  const date = datesArray.find(item => item.name === dateValues.range)

  const formatDate = (date: string) => {
    const parsedDate = parseISO(date)
    return format(parsedDate, 'MM/dd/yyyy')
  }
  const range = dateValues.range
  if (!range) return 'range undefined'
  let from = findDate(dateValues.from, date!.id, 'from', dateValues.untilToday)
  let to = findDate(dateValues.to, date!.id, 'to', dateValues.untilToday)

  if (range === 'Last days') {
    const lastDayNewState = calculateLastXDayPeriod(
      dateValues.lastDays,
      dateValues.untilToday
    )
    from = lastDayNewState?.startDate.format('MM/DD/yyyy') ?? ''
    to = lastDayNewState?.endDate.format('MM/DD/yyyy') ?? ''
    return `${from} - ${to}`
  }

  if (range && range === 'Customize') {
    if (typeof from !== 'string') {
      from = toIsoString(from)
    }
    if (typeof to !== 'string') {
      to = toIsoString(to)
    }
    return `${formatDate(from.substring(0, 10))} - ${formatDate(
      to.substring(0, 10)
    )}`
  }

  if (
    dateValues.untilToday === false &&
    ['This week', 'This month', 'This quarter', 'This year'].includes(
      dateValues.range
    )
  ) {
    return `${formatDate(from)} - ${formatDate(to)}`
  }

  return range
}

export function findDate(
  currentDate: string,
  id: number,
  type: string,
  untilToday?: boolean
) {
  if (id && (id === DateRanges.Customize || id === DateRanges.LastDays)) {
    return currentDate
  }

  const date = datesArray.find(item => item.id === id)
  switch (type) {
    case 'from':
      return moment(date!.from).toISOString()
    case 'to':
      if (!untilToday && toYesterdayOptions.includes(id))
        return moment().subtract(1, 'days').toISOString()
      return moment(date!.to).toISOString()
  }
  return currentDate
}

export const dateStringWidgets = [
  'powertable',
  'powerchart',
  'chart',
  'powertile',
  'simpletable',
]
