import React, { useState, useMemo } from 'react'

import {
  ErrorMesagge,
  LastDaysRangeDateText,
  LastDaysSelectContainer,
} from './style'
import SimpleInput from '@mm/ui/src/components/Forms/_control/SimpleInput'
import { format, sub } from 'date-fns'
import { DATE_FNS_FORMAT } from '@mm/ui/src/components/SingleDatePicker/SingleDatePicker'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import Checkbox from '../CustomCheckbox'

interface TableDate {
  startDate: moment.Moment
  endDate: moment.Moment
  name?: string
  lastDays?: string
  untilToday?: boolean
}

interface AdvanceLastDaysSelectProps {
  onChange: (date?: TableDate) => void
  dateChosen: {
    dateFilterFrom: string
    dateFilterTo: string
    dateFilterRange: string
    dateFilterLastDays: string
    dateFilterUntilToday: boolean
  }
}

const calculateNewDate = (lastDays: string, untilToday: boolean) => {
  const xDays = Number(lastDays) - 1
  if (typeof xDays !== 'number') return undefined

  const from = sub(new Date(), {
    days: untilToday ? 0 : 1,
  })

  const lastDaysDate = sub(from, {
    days: Number(lastDays) - 1,
  })

  const newState = {
    startDate: moment(lastDaysDate),
    endDate: moment(from),
    lastDays,
    name: 'Last days',
    untilToday,
  }

  return newState
}

const regexNumber = /^[1-9]\d*$/
export const AdvanceLastDaysSelect = ({
  onChange,
  dateChosen,
}: AdvanceLastDaysSelectProps) => {
  const [lastDays, setLastDays] = useState(dateChosen?.dateFilterLastDays ?? '')
  const [untilToday, setUntilToday] = useState(
    !!dateChosen?.dateFilterUntilToday
  )
  const [dateRange, setDateRange] = useState(
    calculateNewDate(lastDays, untilToday)
  )

  const onChangePerioValue = (event: { target: { value: any } }) => {
    const newValue = event.target.value
    if (newValue > 1000) return
    setLastDays(newValue)
    const newDateRange = calculateNewDate(newValue, untilToday)
    setDateRange(newDateRange)
    onChange(newDateRange)
  }

  const onChangeUntilToday = (e: { target: { checked: boolean } }) => {
    setUntilToday(e.target.checked)
    const newDateRange = calculateNewDate(lastDays, e.target.checked)
    setDateRange(newDateRange)
    onChange(newDateRange)
  }

  const error = useMemo(() => {
    if (!lastDays) return ''
    if (!regexNumber.test(lastDays)) return 'The value must be a number'
    if (Number(lastDays) > 720) return 'The value must be less than 720 days'

    return ''
  }, [lastDays])

  const showTwoCalendar = useMemo(() => {
    if (!dateRange) return false

    return (
      dateRange.startDate.month() !== dateRange.endDate.month() ||
      dateRange.startDate.year() !== dateRange.endDate.year()
    )
  }, [dateRange])

  return (
    <LastDaysSelectContainer>
      <div>
        <SimpleInput
          value={lastDays}
          onChange={onChangePerioValue}
          name="lastDays"
          label={`Last X Days`}
          placeholder="Select the number of days"
          error={''}
          className={''}
          inputstyle={{ width: '100%' }}
          autoFocus={true}
        />
        {error ? <ErrorMesagge>{error}</ErrorMesagge> : null}

        {dateRange ? (
          <LastDaysRangeDateText>
            {`From ${format(
              dateRange.startDate.toDate(),
              DATE_FNS_FORMAT
            )} to ${format(dateRange.endDate.toDate(), DATE_FNS_FORMAT)}`}
          </LastDaysRangeDateText>
        ) : null}

        <label
          style={{
            marginBottom: '0px',
            cursor: 'pointer',
          }}
        >
          <Checkbox
            type="checkbox"
            checked={untilToday}
            onChange={onChangeUntilToday}
          />
          <span style={{ marginLeft: 2 }}>Days until today</span>
        </label>
      </div>

      {!!lastDays && !!dateRange ? (
        <div style={{ display: 'flex' }}>
          <DatePicker
            disabled={true}
            selected={dateRange.startDate}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            // selectsRange={true}
            inline
            onChange={data => console.log(data)}
          />

          {showTwoCalendar && (
            <DatePicker
              disabled={true}
              selected={dateRange.endDate}
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              // selectsRange={true}
              inline
              onChange={data => console.log(data)}
            />
          )}
        </div>
      ) : null}
    </LastDaysSelectContainer>
  )
}
