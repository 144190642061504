import { useTablesContext } from '../tables.context'

export function useOpenMenu() {
  const { state, dispatch } = useTablesContext()

  const setOpenMenu = (newOpenMenu: string) => {
    dispatch({ type: 'SET_OPEN_MENU', payload: newOpenMenu })
  }

  return { openMenu: state.openMenu, setOpenMenu }
}

export type OpenMenuActions = { type: 'SET_OPEN_MENU'; payload: string }
