import styled from 'styled-components'

export const PickerWrapper = styled.div`
  /* border: 1px solid red; */
  position: relative;
`
export const ClearButton = styled.button`
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  margin: 6px;
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
`
export const ValueInput = styled.input`
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  padding: 0 10px;
  height: 40px;
  &:focus {
    outline: none;
  }
`
