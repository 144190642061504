import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import tablesApi from '../'

export const GET_TABLES_VIEWS = 'Tables_GetViews'

export const getTableViews = async (id: string | null) => {
  if (!id) return []
  return tablesApi.get(`/${id}/views`).then(response => response.data)
}
const useGetTableViews = (id: string | null, queryOptions?: any) =>
  useQuery([GET_TABLES_VIEWS, id], () => getTableViews(id), {
    retry: false,
    staleTime: 1000 * 60 * 60, // 1 hour
    ...queryOptions,
  })

export default useGetTableViews
