// @ts-nocheck
import axios from 'axios'
import * as constants from './_constants'
import { timeZoneParam } from './helpers'
import {
  middlewareTokenError,
  middlewareTokenData,
} from '@mm/api/src/tokenError.middleware'

const headers = timezone => {
  const user = JSON.parse(localStorage.getItem('user'))
  const headers = {
    Authorization: user && user.token,
    locale: 'en',
  }
  if (timezone) headers.timezone = timeZoneParam()
  return headers
}

const waitNSeconds = seconds =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000))

export const handleJWTError = instance => {
  // Response interceptor for API calls
  instance.interceptors.response.use(
    response => {
      middlewareTokenData(response.data)
      return response
    },
    async function (error) {
      if (error.response.data.message === 'JWT_ERROR') {
        const email = JSON.parse(localStorage.getItem('user')).user.email
        const { data } = await axios.post(
          `${constants.BASE_URL}user/auth/token/refresh`,
          { email }
        )

        localStorage.setItem('user', JSON.stringify(data))
        window.location.reload()
        await waitNSeconds(100)
      } else {
        middlewareTokenError(error)
        return Promise.reject(error)
      }
    }
  )
}

export const apiInstance = (group, withTimezone, noCache) => {
  const config = {
    baseURL: `${constants.BASE_URL}${group}`,
  }
  const instance = axios.create(config)
  instance.interceptors.request.use(
    config => {
      const confHeaders = {
        ...config.headers,
        ...headers(withTimezone),
      }
      if (noCache)
        confHeaders['Cache-Control'] =
          'no-cache,no-store,must-revalidate,max-age=-1,private'
      config.headers = confHeaders
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  handleJWTError(instance)
  return instance
}
