import React, { useEffect } from 'react'
import useDashboardExportPdf from '@mm/api/src/dashboards/requests/useDashboardExportPdf'
import Loader from '../Loader'
import defaultQueryClient from '@mm/api/src/reactQuery'
import { USE_DASHBOARD_EXPORT_PDF } from '@mm/api/src/dashboards/requests/useDashboardExportPdf'
import { NotificationManager } from '@mm/utils/src/react-notifications'

type DashboardExportType = {
  id: string
  title: string
  handleExportPDF: (status: boolean) => void
  publicLink?: boolean
}

const DashboardExportComponent = ({
  id,
  title,
  handleExportPDF,
  publicLink,
}: DashboardExportType) => {
  const {
    data: response,
    error,
  }: {
    data?: any
    error: any
  } = useDashboardExportPdf(id, publicLink)

  const handleDownload = async () => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${title}.pdf`)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
    defaultQueryClient.removeQueries({
      queryKey: [USE_DASHBOARD_EXPORT_PDF],
    })
    handleExportPDF(false)
  }

  useEffect(() => {
    if (response) {
      handleDownload()
    }
  }, [response])

  useEffect(() => {
    const enc = new TextDecoder('utf-8')
    if (error?.response || error?.message) {
      handleExportPDF(false)
      NotificationManager.warning(
        error.response?.data
          ? enc.decode(error.response.data)
          : 'Failed to export PDF',
        'Error exporting Dashboard',
        5000
      )
    }
  }, [error])

  return (
    <>
      <div>
        <Loader />
      </div>
    </>
  )
}

export default DashboardExportComponent
