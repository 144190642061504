import React from 'react'
import { ImageContainter } from './ImageData'
import { TitleDescriptionContainer } from '../../style'

export const PowertableRowData = ({
  templateType,
  value,
  additionalConfiguration,
  lastColumn,
}: {
  templateType?: string
  value: string
  additionalConfiguration?: { [k: string]: any }
  lastColumn: boolean
}) => {
  if (lastColumn) {
    const imageAdditionalData =
      additionalConfiguration?.imageAdditionalData?.[value]

    if (templateType === 'image' && imageAdditionalData) {
      return (
        <ImageContainter
          description={imageAdditionalData?.description}
          externalLink={imageAdditionalData?.externalLink}
          title={imageAdditionalData?.title}
          image={value}
          hasImageAdditionalData={templateType === 'image'}
        />
      )
    }
    const rowData = additionalConfiguration?.data?.[value]
    if (templateType === 'title-description' && rowData) {
      return (
        <TitleDescriptionContainer>
          <span className="title" title={rowData.title}>
            {rowData.title}
          </span>
          <span className="description" title={rowData.description}>
            {rowData.description}
          </span>
        </TitleDescriptionContainer>
      )
    }
  }
  return <div title={value}>{value}</div>
}
