import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`

const Icon = styled.svg`
  fill: none;
  stroke-width: 4px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: white;
  border: 2px solid #cacbd0;
  border-radius: 4px;
  transition: all 150ms;
  margin-top: 0.5rem;
  margin-right: 8px;
  cursor: pointer;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #edeffe;
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
    stroke: ${props => (props.disabled ? 'grey' : '#4552e8')};
  }
`

const Checkbox = ({ className = undefined, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} {...props}>
      <Icon viewBox="0 2 24 26">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox
