import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'
import { ClientData } from '../../../customComponents/forms/AddNewTableModal/ClientModal'

const UPDATE_CLIENT_KEY = 'Tables_UpdateClient'

const updateClient = async ({
  tableId,
  data,
}: {
  tableId: string
  data: ClientData
}) => {
  if (!tableId) throw new Error('Missing Table ID')
  return tablesApi
    .put(`/${tableId}/clients/${data.id}`, data)
    .then(response => response.data)
}

const useUpdateClient = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateClient, {
    mutationKey: [UPDATE_CLIENT_KEY],
    ...queryOptions,
  })
}

export default useUpdateClient
