import styled from 'styled-components'

export const CellWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 10px;
  max-width: calc(100% - 30px);
  height: 40px;
`

export const EditButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 95%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  ${CellWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`
export const Content = styled.span`
  margin: 0;
  font-weight: inherit;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
export const Link = styled.a`
  margin: 0;
  font-weight: inherit;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  hyphens: none;
`

export const EditorContainer = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 4px 4px 4px 16px;
  background-color: white;
  position: absolute;
  min-width: 240px;
  min-height: 40px;
  z-index: 10;
  top: 0px;
  left: -16px;
`
export const Checkmark = styled.span`
  display: inline-block;
  transform: rotate(45deg);
  margin: 4px;
  height: 20px;
  width: 10px;
  border-bottom: 4px solid #2dc3d3;
  border-right: 4px solid #2dc3d3;
`

export const Emptybox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
`
