import React from 'react'
import { FieldInputProps } from '../FieldEditor'
import { StyledTextArea } from './styles'

const LongTextInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  value,
  onKeyDown,
}) => {
  return (
    <StyledTextArea
      value={value}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChange(e.target.value)
      }
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      autoFocus
    />
  )
}

export default LongTextInput
