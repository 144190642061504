import React, { useState } from 'react'
import WarningIcon from '../../../assets/icons/warning.svg'
import { WarningWrapper } from './styles'
import { Tooltip } from 'reactstrap'

const WarningAlert = ({
  errors,
  id,
}: {
  errors: { messages: string[] }
  id: string
}) => {
  const [toggle, setToggle] = useState(false)
  return (
    <>
      <WarningWrapper id={`tooltip-${id}`}>
        <img src={WarningIcon} alt="Warning Icon" />
      </WarningWrapper>
      <Tooltip
        placement={'right'}
        isOpen={toggle}
        target={`tooltip-${id}`}
        toggle={() => setToggle(!toggle)}
      >
        <div>
          There was an error while trying to fetch data, values of the following
          sources may have been afected.
        </div>
        {errors.messages?.map((msg, idx) => <div key={idx}>{msg}</div>)}
      </Tooltip>
    </>
  )
}

export default WarningAlert
