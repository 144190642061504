// @ts-nocheck

import React from 'react'
import { Transition } from 'react-transition-group'

import DeleteIcon from '../../assets/icons/delete-icon.svg'
import EditIcon from '../../assets/icons/pen.svg'
import DuplicateIcon from '../../assets/icons/duplicate-icon.svg'

const defaultStyle = {
  transition: 'opacity 150ms ease-in-out',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const items = [
  {
    name: 'Delete',
    img: DeleteIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Edit',
    img: EditIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Duplicate',
    img: DuplicateIcon,
  },
]

class Tooltip extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setRef = this.setRef.bind(this)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setRef(node) {
    if (this) this.ref = node
  }

  handleClickOutside = e => {
    const { toggle } = this.props
    if (this.ref && !this.ref.contains(e.target)) {
      toggle()
    }
  }

  handleClick(buttonName) {
    try {
      const { [`on${buttonName}`]: onButtonName, toggle } = this.props
      onButtonName()
      if (!buttonName.includes('Move')) toggle()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { show } = this.props
    const commonStyles = { width: '150px' }

    return (
      <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              ...commonStyles,
            }}
            ref={this.setRef}
            className="table-popup table-popup-action"
            onMouseDown={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
          >
            <ul style={commonStyles}>
              {items.map(item => (
                <li key={item.name}>
                  <button
                    onClick={() => {
                      this.handleClick(item.name)
                    }}
                    className={`button-container ${
                      item.name === 'Delete' ? 'hide-on-mobile' : ''
                    }`}
                    type="button"
                  >
                    <img
                      src={item.img}
                      className={`${item.name}-button preload-animation`}
                      alt=""
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Transition>
    )
  }
}

export default Tooltip
