import { useQuery } from '@tanstack/react-query'
import powerchartApi from '../'

export const GET_POWERCHART_DATA_KEY = 'Powercharts_GetPowerchartData'

export const getPowerchartData = async (
  id: string,
  shareable?: string,
  pdf?: string
) => {
  let queryParams
  if (shareable) {
    queryParams = `?shareable=${shareable}`
  } else if (pdf) {
    queryParams = `?pdf=${pdf}`
  }

  return powerchartApi
    .get(`/${id}/data${queryParams ? queryParams : ''}`)
    .then(response => response.data)
}

const useGetPowerchartData = ({
  id,
  shareable,
  pdf,
}: {
  id: string
  shareable?: string
  pdf?: string
}) =>
  useQuery(
    [GET_POWERCHART_DATA_KEY, id],
    () => getPowerchartData(id, shareable, pdf),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useGetPowerchartData
