import styled from 'styled-components'

export const StyledInput = styled.input`
  border: '0 0 4px rgba(0, 0, 0, 0.2)';
  outline: none;
  box-shadow: '0 0 4px rgba(0, 0, 0, 0.2)';
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`
