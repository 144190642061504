import React, { useEffect, useState } from 'react'

import {
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledChartTitle,
  StyledDots,
  StyledViewOptions,
  DateLabel,
} from './style'
import Tooltip from './Tooltip'
import Loader from '../Loader'
import WidgetIcon from '../WidgetIcon'
import { DashboardDate, calculateDateRange } from '../../helpers/widgetDate'
import { StyledCardFooter } from '../Powertable/style'
import { PowerAIDescription } from '../PowerAIDescription/PowerAIDescription'

const VITE_AI_DESCRIPTION_ENABLED =
  (import.meta as any).env.VITE_AI_DESCRIPTION_ENABLED === 'true'
interface ChartProps {
  onDuplicate: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  shareable?: string
  id: string | undefined
  name: string
  loading: boolean
  children: React.ReactNode
  sources: string[]
  show_icons: boolean
  date: string
  dateChosen: DashboardDate
  response: any
}

const ChartContainer = ({
  onDuplicate,
  onEdit,
  onDelete,
  name,
  loading,
  id,
  children,
  sources,
  show_icons,
  date,
  dateChosen,
  response,
}: ChartProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [hideActions] = useState(import.meta.env.VITE_APP_IS_PUBLIC === 'false')
  const [dateFormat, setDateFormat] = useState('')

  const handleToggleTooltip = () => {
    setShowTooltip(prev => !prev)
  }

  useEffect(() => {
    if (response) {
      setDateFormat(calculateDateRange(dateChosen, response))
    }
  }, [response, dateChosen])

  const aiDescription = response?.data?.aiDescription?.message
  const aiError = response?.data?.aiDescription?.error

  return (
    <>
      {show_icons && <WidgetIcon sources={sources} />}
      <StyledCard id={id}>
        <StyledCardBody className="scrollbar-color">
          {loading ? (
            <Loader />
          ) : (
            <>
              <StyledCardTitle>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StyledChartTitle>{name}</StyledChartTitle>
                  <DateLabel style={{ marginLeft: '15px' }}>
                    {dateFormat}
                  </DateLabel>
                </div>
                {hideActions && (
                  <>
                    <StyledViewOptions
                      onClick={handleToggleTooltip}
                      className="view-options"
                      onMouseDown={e => e.stopPropagation()}
                      onMouseMove={e => e.stopPropagation()}
                    >
                      <StyledDots className="dots"></StyledDots>
                    </StyledViewOptions>

                    <Tooltip
                      show={showTooltip}
                      toggle={handleToggleTooltip}
                      onDelete={onDelete}
                      onEdit={onEdit}
                      onDuplicate={onDuplicate}
                      loading={loading}
                    />
                  </>
                )}
              </StyledCardTitle>
              {children}
            </>
          )}
        </StyledCardBody>

        {VITE_AI_DESCRIPTION_ENABLED && loading ? null : (
          <StyledCardFooter style={{ marginBottom: 15 }}>
            <PowerAIDescription aiDescription={aiDescription} error={aiError} />
          </StyledCardFooter>
        )}
      </StyledCard>
    </>
  )
}
export default ChartContainer
