import React from 'react'
import { RowData } from '../../types'

export type FieldInputProps = {
  onBlur: (avoidReload?: boolean) => void
  onKeyDown: (event: React.KeyboardEvent<any>) => void
  onChange: (value: string | null, updateValue?: boolean) => void
  value: string
  options?: Array<{ key: string | number; value: string; label: string }>
  row?: RowData
  table?: { [key: string]: any }
  tableMetrics: {
    dataType: string
    id: string
    label: string
    source: string
    type: string
    value: string
  }[]
  source?: string
  filterEnabled?: boolean
}

type FieldEditorProps = {
  inputComponent: React.ComponentType<FieldInputProps>
  onBlur: (avoidReload?: boolean) => void
  onChange: (value: string | null) => void
  value: string
  options?: Array<{ key: string | number; value: string; label: string }>
  row?: RowData
  table: { [key: string]: any }
  tableMetrics: {
    dataType: string
    id: string
    label: string
    source: string
    type: string
    value: string
  }[]
  source?: string
  filterEnabled?: boolean
}

const FieldEditor: React.FC<FieldEditorProps> = ({
  inputComponent: Input,
  onBlur,
  onChange,
  value,
  options,
  row,
  table,
  tableMetrics,
  source,
  filterEnabled,
}) => {
  const handleKeyDown = (event: React.KeyboardEvent<any>) => {
    if (event.key === 'Enter' && !event.shiftKey) onBlur()
  }

  return (
    <Input
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      options={options}
      row={row}
      table={table}
      tableMetrics={tableMetrics}
      source={source}
      filterEnabled={filterEnabled}
    />
  )
}

export default FieldEditor
