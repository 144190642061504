import useMultiAxisChartData from '@mm/api/src/multiaxischarts/requests/useGetMultiAxisChartData'
import React from 'react'
import ChartContainer from '../ChartContainer'
import MultiChart from './MultiChart'
import { DashboardDate } from '../../../helpers/widgetDate'

interface ChartProps {
  onDuplicate: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  id: string
  chart: any
  shareable?: string
  dateChosen: DashboardDate
  pdf?: string
}

const MultiAxischart = ({
  chart,
  shareable,
  onDelete,
  onDuplicate,
  onEdit,
  id,
  dateChosen,
  pdf,
}: ChartProps) => {
  const {
    data: response,
    isFetching,
    error,
  }: { data: any; isFetching: boolean; error: any } = useMultiAxisChartData({
    id: id,
    shareable: shareable,
    pdf,
  })

  return (
    <>
      <ChartContainer
        id={`multiaxischart-${id}`}
        loading={isFetching}
        name={response?.name}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onEdit={onEdit}
        sources={response?.data.source}
        show_icons={response?.show_icons}
        dateChosen={dateChosen}
        response={response}
      >
        <MultiChart id={chart.id} response={response?.data} error={error} />
      </ChartContainer>
    </>
  )
}

export default MultiAxischart
