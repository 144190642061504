import useUpdateTable from '../useUpdateTable'
import queryClient from '../../../reactQuery'
import { GET_ONE_TABLE_QUERY_KEY } from '../useGetOneUserTable'
import { TableReporting } from '@mm/ui/src/components/SmartTable/types'
import { handleError } from '@mm/ui/src/components/SmartTable/context/utils'
import { GET_ONE_TABLE_DASHBOARD_QUERY_KEY } from '../../../dashboardOverviews/requests/useGetOneDashboardOverview'

const useUpdateTableHook = (overviewId: string, widgetId?: string) => {
  const queryKey = widgetId
    ? [GET_ONE_TABLE_DASHBOARD_QUERY_KEY, widgetId]
    : [GET_ONE_TABLE_QUERY_KEY, overviewId]

  const mutation = useUpdateTable({
    onSuccess: () => {
      queryClient.setQueryData(queryKey, (newTable?: TableReporting) => {
        return newTable
      })
    },
    onError: error => {
      queryClient.invalidateQueries(queryKey)
      handleError({ message: 'The table could not been updated' })
    },
  })

  const updateTableHandler = async (payload: { [key: string]: string }) => {
    try {
      const body = {
        ...payload,
        id: overviewId,
      }

      await mutation.mutateAsync(body)
    } catch (error) {
      console.error('Error in updateTableHandler:', error)
    }
  }

  return {
    updateTableHandler,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess,
  }
}

export default useUpdateTableHook
