import React from 'react'

import {
  formatCalculatedMetric,
  formatDuration,
  formatGoogleAnalyticsValue,
  formatGoogleValue,
  formatLinkedinValue,
  formatMetaValue,
  formatTiktokValue,
  formatValue,
  isHubspotDuration,
} from '@mm/utils/src/helpers/Utils'

import ResizableText from './ResizableText'
import TooltipMenu from './TooltipMenu'
import WidgetIcon from '../WidgetIcon'
import { tableIconSource } from '../WidgetIcon/utils'
import { isCalculatedMetric } from '../../helpers/calculatedMetrics'

interface Props {
  isLoading?: boolean
  onDelete: () => void
  onEdit: () => void
  onDuplicate: () => void
  handleToggleTooltip: () => void
  data: {
    error: { message: string }
    dateString: string
    isDuration: boolean
    numerator: string
    source: string
    warning: { message: string }
    value: string
  }
  hideActions: boolean
  showTooltip: boolean
  metrics: {
    type: string
    value: string
    source: string
    name: string
    numberType: string
  }[]
  widget: {
    id: string
    lock: boolean
    metrics: any
    dashboard_widgets: any
    name: string
  }
  onChangeStat: (params: {
    delta: { lock: boolean }
    onSuccess?: Function
  }) => void
}

export const Tile = ({
  isLoading,
  onDelete,
  onEdit,
  onDuplicate,
  handleToggleTooltip,
  data,
  hideActions,
  showTooltip,
  metrics,
  widget,
  onChangeStat,
}: Props) => {
  const { error, dateString, isDuration, numerator, source, warning } = data
  let { value } = data
  const metric = metrics[0]
  const altMessage = warning || error

  const altMessageColor = warning ? '#ff9800' : 'red'
  if (isCalculatedMetric(metric?.type) && metric.numberType !== 'default') {
    value = formatCalculatedMetric(value, metric)
  } else if (metric?.type === 'AM') {
    value = formatValue(value, metric)
  } else if (isCalculatedMetric(metric?.type) && isDuration) {
    value = formatDuration(value)
  } else if (isHubspotDuration(metric)) {
    value = formatDuration(value) //* Hubspot durations are in MINUTES
  } else if (metric.source === 'META') {
    value = formatMetaValue(value, metric)
  } else if (metric.source === 'GOOGLE') {
    value = formatGoogleValue(value, metric)
  } else if (metric.source === 'GOOGLE_ANALYTICS') {
    value = formatGoogleAnalyticsValue(value, metric)
  } else if (isCalculatedMetric(metric?.type) && source === 'META') {
    const formatMetric = { ...metric }
    formatMetric.type = numerator
    value = formatMetaValue(value, formatMetric)
  } else if (isCalculatedMetric(metric?.type) && source === 'GOOGLE') {
    const formatMetric = { ...metric }
    formatMetric.type = numerator
    value = formatGoogleValue(value, formatMetric)
  } else if (
    isCalculatedMetric(metric?.type) &&
    source === 'GOOGLE_ANALYTICS'
  ) {
    const formatMetric = { ...metric }
    formatMetric.type = numerator
    value = formatGoogleAnalyticsValue(value, formatMetric)
  } else if (metric.source === 'LINKEDIN') {
    value = formatLinkedinValue(value, metric)
  } else if (isCalculatedMetric(metric?.type) && source === 'LINKEDIN') {
    const formatMetric = { ...metric }
    formatMetric.type = numerator
    value = formatLinkedinValue(value, formatMetric)
  } else if (metric.source === 'TIKTOK') {
    value = formatTiktokValue(value, metric)
  } else if (isCalculatedMetric(metric?.type) && source === 'TIKTOK') {
    const formatMetric = { ...metric }
    formatMetric.type = numerator
    value = formatTiktokValue(value, formatMetric)
  }

  return (
    <>
      {widget.dashboard_widgets.show_icons && (
        <WidgetIcon sources={tableIconSource(widget.metrics)} />
      )}
      <ResizableText
        formatedValue={value}
        metric={metric}
        hideActions={hideActions}
        altMessage={altMessage}
        dateString={dateString}
        altMessageColor={altMessageColor}
        handleToggleTooltip={handleToggleTooltip}
        name={widget.name}
      />

      {hideActions && isLoading === false && (
        <TooltipMenu
          show={showTooltip}
          toggle={handleToggleTooltip}
          onDelete={onDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
        />
      )}
    </>
  )
}
