import React, { useRef } from 'react'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ErrorMessage } from './style'
import WarningIconTooltip from '../CarouselItem/WarningIconTooltip'
interface ChartProps {
  options: any
  error: any
  chartId: string
}

const Chart = ({ options, error, chartId }: ChartProps) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const errorMessage = error?.response?.data?.message || error?.message || error
  return (
    <>
      {error ? (
        <>
          <WarningIconTooltip error={errorMessage} elementId={chartId} />
          <ErrorMessage>
            {errorMessage || `Could not render chart`}
          </ErrorMessage>
        </>
      ) : (
        <HighchartsReact
          containerProps={{ style: { height: '100%' } }}
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      )}
    </>
  )
}

export default Chart
