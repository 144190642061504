// @ts-nocheck
import styled from 'styled-components'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

export const TableContainer = styled.div`
  max-width: 100%;
  max-height: 440px;
  filter: drop-shadow(0px 6px 22px rgba(0, 0, 0, 0.08));
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 40px;
`

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 16px;
  color: #787b90;
`

export const TableActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  & > div > div {
    top: 10px !important;
  }
`

export const ColumnGrip = styled.img`
  width: 24px;
  height: 24px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
`

export const InvisibleDiv = styled.div<{
  isLastSticky?: boolean
  marginRight?: string
}>`
  display: inline-flex;
  width: ${p => p.marginRight};
`

export const TableRow = styled.div<{
  isLastSticky?: boolean
  marginRight?: string
}>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1b215c;
  width: 308px;
`

export const EmptyTableRow = styled(TableRow)`
  width: 100%;
`

export const TableCell = styled.div<{
  itemXY?: string
  hide?: boolean
  isFixedColumn?: boolean
}>`
  padding: 0 10px;
  align-items: flex-start;
  padding: 8px 8px 8px 16px;
  position: relative;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dedfeb;
  background-color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${p => p.hide && '0.25'};

  ${p => p.itemXY === '0' && `border-radius: 8px 0 0 0`}
  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  & > a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
  line-height: ${p => p.isFixedColumn && '23px'};
  &.dragged-over {
    border-top: 1px solid black;
  }
`
export const ResizedBorder = styled.div`
  background-color: #dedfeb;
  border: none;
  opacity: 1;
  width: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0px 3px 3px 0px;
  padding: 0.5px;
  transition: 0.3s;
  &:hover {
    cursor: col-resize;
    background: rgba(69, 82, 232, 0.16);
    & > div {
      background: rgb(69, 82, 232);
      opacity: 1;
    }
  }
  & > div {
    background: gray;
    width: 2px;
    height: 70%;
    opacity: 0.7;
    border-radius: 8px;
    & :hover {
      background: red !important;
    }
    transition: 0.3s;
  }
`

export const DragHandle = styled.div`
  width: 20px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: move;
`

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  right: 0px;
  top: 160px;
`

export const StyledDropdownMenu = styled(DropdownMenu)`
  height: 312px;
  width: 304px;
  padding: 24px;
  border: none;
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow-y: hidden;
  top: 48px !important;
  left: 40px !important;
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0;
  width: 40px;
  height: 40px;
  background: ${p => (p.isActive ? '#d6d8f0' : '#ffffff')};
  box-shadow: 0px 6px 22px rgba(0, 0, 0, 0.08) !important;
  border-radius: 4px;
  border: none;
  &:hover {
    background: #d6d8f0;
  }
  &:active {
    background: #d6d8f0 !important;
  }
`
export const StyledDropdownIcon = styled.img`
  padding: 10px;
  filter: ${p =>
    p.isActive
      ? `invert(31%) sepia(96%) saturate(1177%) hue-rotate(218deg)
    brightness(87%) contrast(110%)`
      : `invert(58%) sepia(3%) saturate(395%) hue-rotate(201deg)
    brightness(94%) contrast(84%)`};
  &:hover {
    filter: invert(31%) sepia(96%) saturate(1177%) hue-rotate(218deg)
      brightness(87%) contrast(110%);
  }
`

export const StyledDropdownMenuContainer = styled.div`
  height: 192px;
  overflow-y: auto;
`

export const StyledDropdownItem = styled(DropdownItem)`
  height: 40px;
  margin: 0px 0px 8px;
  padding-left: 12px;
  padding-right: 6px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  color: rgb(27, 33, 92);
  cursor: pointer;
  &:hover {
    background-color: #edeffe;
  }
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const SyledSpan = styled.span`
  margin-top: 0.5rem;
  cursor: pointer;
`

export const StyledDropdownActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
`

export const DropdownIcon = styled.img`
  width: 32px;
  height: 32px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 200px;
  outline: 0px rgba(69, 82, 232, 0.16) solid;
  &:hover {
    background-color: #4552e829;
    outline: 2px rgba(69, 82, 232, 0.16) solid;
    transition: background-color 0.2s ease-in-out !important;
    transition: outline 0.2s ease-in-out !important;
    rotate: 180deg;
  }
  &:active {
    background-color: #4552e829;
    outline: 2px rgba(69, 82, 232, 0.16) solid;
    transition: background-color 0.2s ease-in-out !important;
    transition: outline 0.2s ease-in-out !important;
    rotate: 180deg;
  }
`
export const SortColumnIcon = styled.img`
  width: 28px;
  height: 28px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 200px;
  outline: 0px rgba(69, 82, 232, 0.16) solid;
  &:hover {
    background-color: #4552e829;
    outline: 2px rgba(69, 82, 232, 0.16) solid;
    transition: background-color 0.2s ease-in-out !important;
    transition: outline 0.2s ease-in-out !important;
  }
`

export const ColumnTitle = styled.div`
  display: flex;
  max-width: calc(100% - 22px);
`

export const OrderedColumnTitle = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
`

export const OrderedColumnIcon = styled.img`
  margin-left: 8px;
`

export const TableEmptyMsg = styled.div<{
  itemXY?: string
  hide?: boolean
  isTitle?: boolean
}>`
  padding: 0 10px;
  width: 100%;
  align-items: flex-start;
  padding: 8px 8px 8px 16px;
  position: relative;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #dedfeb;
  background-color: white;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
`

export const ComparedCell = styled.span<{}>`
  &&& {
    max-width: 100px;
    background: #f1f1f194;
    border-radius: 5px;
    padding: 3px 4px;
    box-shadow: 1px 1px #6c757dcf;
    padding-top: 3px;
    display: flex;
  }
`
export const ComparedCellNoData = styled.span<{}>`
  &&& {
    width: 30px;
    text-align: right;
    color: #b4a5a5;
    text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.3);
  }
`

export const ComparedArrowCell = styled.span<{
  increase: boolean
}>`
  color: ${p => (p.increase ? '#15ad64' : 'rgb(252, 99, 99)')};
  font-weight: 1000;
  font-size: 22px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  margin-left: 5px;
  font-weight: normal;
`

export const ComparedTextCell = styled.span<{ increase: boolean }>`
  color: ${p => (p.increase ? '#15ad64' : 'rgb(252, 99, 99)')};
  text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.3);
  display: inline-block;
  margin: auto;
  text-align: center;
  font-weight: normal;
`

export const ColumnCell = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  .client-actions {
    margin-right: 8px;
    cursor: pointer;
    margin-top: 2px;
  }
`
export const TooltipActions = styled.div`
  position: absolute;
  height: 30px;
  top: 5px;
  margin-left: 30px;
  left: 12px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 3px rgba(212, 212, 212, 1);
  -moz-box-shadow: 0px 0px 10px 3px rgba(212, 212, 212, 1);
  box-shadow: 0px 0px 10px 3px rgba(212, 212, 212, 1);
  border-radius: 8px;
  ul {
    height: 100%;
    display: flex;
    gap: 10px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    justify-content: start;
    list-style-type: none;
    li button {
      position: relative;
      top: 3px;
      background: transparent;
      border: none;
    }
  }
`

export const ColumnValue = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
