import {
  format,
  addHours,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  subDays,
  subWeeks,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns'

export const getDateRanges = (
  timezoneOffset: number,
  dateFormat = 'yyyyMMdd'
) => {
  const tzOffHours = Math.trunc(timezoneOffset / 60) * -1
  const hour = addHours(new Date(), tzOffHours)

  return [
    {
      id: 1,
      name: 'Today',
      value: 'T',
      from: `${format(hour, dateFormat)}`,
      to: `${format(hour, dateFormat)}`,
    },
    {
      id: 2,
      name: 'This week',
      value: 'TW',
      from: `${format(startOfWeek(hour), dateFormat)}`,
      to: `${format(endOfWeek(hour), dateFormat)}`,
    },
    {
      id: 3,
      name: 'This month',
      value: 'TM',
      from: `${format(startOfMonth(hour), dateFormat)}`,
      to: `${format(endOfMonth(hour), dateFormat)}`,
    },
    {
      id: 4,
      name: 'This quarter',
      value: 'TQ',
      from: `${format(startOfQuarter(hour), dateFormat)}`,
      to: `${format(endOfQuarter(hour), dateFormat)}`,
    },
    {
      id: 5,
      name: 'This year',
      value: 'TY',
      from: `${format(startOfYear(hour), dateFormat)}`,
      to: `${format(endOfYear(hour), dateFormat)}`,
    },
    {
      id: 6,
      name: 'Yesterday',
      value: 'Y',
      from: `${format(subDays(hour, 1), dateFormat)}`,
      to: `${format(subDays(hour, 1), dateFormat)}`,
    },
    {
      id: 7,
      name: 'Last week',
      value: 'LW',
      from: `${format(startOfWeek(subWeeks(hour, 1)), dateFormat)}`,
      to: `${format(endOfWeek(subWeeks(hour, 1)), dateFormat)}`,
    },
    {
      id: 8,
      name: 'Last month',
      value: 'LM',
      from: `${format(startOfMonth(subMonths(hour, 1)), dateFormat)}`,
      to: `${format(endOfMonth(subMonths(hour, 1)), dateFormat)}`,
    },
    {
      id: 9,
      name: 'Last quarter',
      value: 'LQ',
      from: `${format(startOfQuarter(subQuarters(hour, 1)), dateFormat)}`,
      to: `${format(endOfQuarter(subQuarters(hour, 1)), dateFormat)}`,
    },
    {
      id: 10,
      name: 'Last year',
      value: 'LY',
      from: `${format(startOfYear(subYears(hour, 1)), dateFormat)}`,
      to: `${format(endOfYear(subYears(hour, 1)), dateFormat)}`,
    },
    {
      id: 11,
      name: 'All times',
      value: 'AT',
    },
    {
      id: 12,
      name: 'Customize',
      value: 'C',
    },
    {
      id: 12,
      name: 'Previous period',
      value: 'PP',
    },
    {
      id: 14,
      name: 'Last days',
      untilTodayDefault: false,
    },
  ]
}
