import React, { useEffect, useMemo, useState } from 'react'
import { FieldInputProps } from '../FieldEditor'
import MetricSelectInput from '../MetricSelectInput/MetricSelectInput'
import { ButtonWrapper, ModalWrapper } from './style'
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap'
import useGetMetricsOptions from '../MetricSelectInput/MetricSelect.hooks'
import SmartReportingSelect from '../../../../Forms/_control/SmartReportingSelect'
import SimpleInput from '../../../../Forms/_control/SimpleInput'
import { FILTERS_OPERATORS } from '../../../Constants'
import RemoveButton from '@mm/ui/src/components/AddRemoveButtons/RemoveButton'
import AddButton from '../../../../AddRemoveButtons/AddButton'
import { v4 as uuid } from 'uuid'
import { availableFiltersBySource } from './sourceFilters'

interface FilterConfig {
  property: string
  filter: string
  value: string
}

const SourceResultInput = ({
  onBlur,
  onChange,
  onKeyDown,
  value,
  row,
  tableMetrics,
  source,
  filterEnabled,
}: FieldInputProps) => {
  const [modalOpen, setModalOpen] = useState(true)
  const [errors, setErrors] = useState<{ [k: string]: string } | null>(null)
  const [selectedMetric, setSelectedMetric] = useState<{
    metric?: string
    value?: string
    label?: string
  } | null>(null)
  const [filters, setFilters] = useState<FilterConfig[]>([
    {
      property: '',
      filter: '',
      value: '',
    },
  ])

  const availableFilters = useMemo(() => {
    if (!source || !availableFiltersBySource[source]) return []
    return availableFiltersBySource[source].map(filter => {
      return {
        value: filter.value,
        label: filter.label,
        key: filter.value,
      }
    })
  }, [source])

  useEffect(() => {
    const parsedValue = JSON.parse(value)
    if (!parsedValue) return
    setSelectedMetric({
      metric: parsedValue.metric,
      value: parsedValue.value,
      label: parsedValue.label,
    })
    if (parsedValue.filter?.AND) {
      setFilters(parsedValue.filter.AND)
    }
  }, [value])

  const handleClose = () => {
    onBlur(true)
    setModalOpen(false)
  }

  const handleConfirm = async () => {
    const data: {
      metric?: string
      value?: string
      label?: string
      filter?: { id: string; AND: FilterConfig[]; OR: FilterConfig[] }
    } = { ...selectedMetric }
    data.filter = { id: uuid(), AND: [], OR: [] }
    data.filter.AND = filters
    if (!data.metric) {
      setErrors({
        metric: 'Must select a metric',
      })
      return
    }
    await onChange(JSON.stringify(data), true)
    setErrors(null)
    setModalOpen(false)
  }

  const _mergedoptions: {
    label: string
    value: string | number
    key?: string | number | undefined
  }[] = useGetMetricsOptions({ source, tableMetrics, row })

  const handleSelect = ({ target }: { target: { [key: string]: string } }) => {
    if (!target.value) {
      setSelectedMetric(null)
      return
    }
    const metric = _mergedoptions.find(
      (mp: { value: string | number }) =>
        mp.value.toString() === target.value.toString()
    )

    setSelectedMetric({
      metric: metric?.value as string,
      label: metric?.label,
      value: metric?.value as string,
    })
  }

  useEffect(() => {
    if (filters.length === 0) {
      setFilters([
        {
          property: '',
          filter: '',
          value: '',
        },
      ])
    }
  }, [filters])

  const handleDeleteFilter = (idx: number) => {
    const filtersCopy = [...filters]
    filtersCopy.splice(idx, 1)
    setFilters(filtersCopy)
  }

  const handleAddFilter = () => {
    setFilters([
      ...filters,
      {
        property: '',
        filter: '',
        value: '',
      },
    ])
  }
  const handleFilterChange = (
    idx: number,
    property: 'property' | 'filter' | 'value',
    value: string
  ) => {
    const filterCopy = [...filters]
    const modifiedFilter = { ...filterCopy[idx] }
    modifiedFilter[property] = value
    filterCopy[idx] = modifiedFilter
    setFilters(filterCopy)
  }

  if (!filterEnabled) {
    return (
      <MetricSelectInput
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        row={row}
        tableMetrics={tableMetrics}
        source={source}
      ></MetricSelectInput>
    )
  }

  return (
    <ModalWrapper isOpen={modalOpen} className="modal-center form-custom">
      <ModalHeader toggle={handleClose}>
        <span>Source metric with filters</span>
        <div className="form-button-group">
          <button onClick={handleClose} className="close-modal-form">
            <span></span>
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <SmartReportingSelect
          label={'Result metric'}
          name={''}
          options={_mergedoptions}
          placeholder="Select one"
          className="react-select"
          classNamePrefix="react-select"
          value={selectedMetric?.value || ''}
          onChange={handleSelect}
          error={errors?.metric}
          autoFocus
        />
        <label htmlFor="">Filter Configuration</label>
        <Row>
          <Col md={3}>
            <label htmlFor="">Property</label>
          </Col>
          <Col md={3}>
            <label htmlFor="">Condition</label>
          </Col>
          <Col md={4}>
            <label htmlFor="">Value</label>
          </Col>
        </Row>
        {filters.map((filter, idx) => (
          <Row key={idx}>
            <Col md={3}>
              <SmartReportingSelect
                name="property"
                options={availableFilters}
                placeholder="Select property"
                className="react-select"
                classNamePrefix="react-select"
                value={filter.property}
                onChange={(e: any) =>
                  handleFilterChange(idx, e.target.name, e.target.value)
                }
              />
            </Col>
            <Col md={3}>
              <SmartReportingSelect
                name="filter"
                options={FILTERS_OPERATORS.string}
                placeholder="Select condition"
                className="react-select"
                classNamePrefix="react-select"
                value={filter.filter}
                onChange={(e: any) =>
                  handleFilterChange(idx, e.target.name, e.target.value)
                }
              />
            </Col>
            <Col md={4}>
              <SimpleInput
                name="value"
                placeholder="Insert value"
                value={filter.value}
                type="text"
                onChange={(e: any) =>
                  handleFilterChange(idx, e.target.name, e.target.value)
                }
              />
            </Col>
            <Col md={2}>
              <ButtonWrapper>
                <RemoveButton
                  disabled={false}
                  handleRemove={() => handleDeleteFilter(idx)}
                />
                <AddButton handleAdd={handleAddFilter} />
              </ButtonWrapper>
            </Col>
          </Row>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button className="form-btn filled" onClick={handleConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </ModalWrapper>
  )
}

export default SourceResultInput
