import { useQuery } from '@tanstack/react-query'
import dashboards from '..'

export const USE_DASHBOARD_EXPORT_PDF = 'Dashboard_Export_PDF'

export const dashboardExportPdf = async (id: string, publicLink?: boolean) => {
  let endpoint = `/generate-pdf/${id}`
  if (publicLink) {
    endpoint = `/share/${id}/generate-pdf`
  }
  return dashboards
    .get(endpoint, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.data)
}

const useDashboardExportPdf = (id: string, publicLink?: boolean) =>
  useQuery(
    [USE_DASHBOARD_EXPORT_PDF],
    () => dashboardExportPdf(id, publicLink),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useDashboardExportPdf
