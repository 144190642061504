type metric = {
  source: string
}

export const tableIconSource = (metrics: metric[]) => {
  const sources: string[] = []
  if (!metrics) return sources
  for (const metric of metrics) {
    const sourceList = metric?.source ? metric.source.split(',') : []
    sourceList.forEach(source => {
      if (!sources.includes(source) && source !== null) {
        sources.push(source)
      }
    })
  }
  return sources
}
