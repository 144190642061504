import React from 'react'

const DownloadIcon = ({ color = '#2CC1D1' }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.334 10V12.6667C14.334 13.0203 14.1935 13.3594 13.9435 13.6095C13.6934 13.8595 13.3543 14 13.0007 14H3.66732C3.3137 14 2.97456 13.8595 2.72451 13.6095C2.47446 13.3594 2.33398 13.0203 2.33398 12.6667V10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00098 6.66675L8.33431 10.0001L11.6676 6.66675"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33398 10V2"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
