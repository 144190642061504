import { QueryClient } from '@tanstack/react-query'

const defaultQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // disable retries by default
    },
  },
})

export default defaultQueryClient
