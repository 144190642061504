import { AxiosError } from 'axios'
import { getRedirectionsUriForDomains } from '@mm/utils/src/domainRedirection'
import { NotificationManager } from '@mm/utils/src/react-notifications'

const redirectionsForDomain = getRedirectionsUriForDomains()
const additionHandlers: { [k: string]: () => void } = {
  HUBSPOT: () => {
    window.location.href = redirectionsForDomain.hubspot
  },
  META: () => {
    window.location.href = redirectionsForDomain.meta
  },

  META_SOCIAL: () => {
    window.location.href = redirectionsForDomain.meta_social
  },

  INSTAGRAM_SOCIAL: () => {
    window.location.href = redirectionsForDomain.instagram_social
  },
  GOOGLE: () => {
    window.location.href = redirectionsForDomain.google
  },
  GOOGLE_ANALYTICS: () => {
    window.location.href = redirectionsForDomain.google_analytics
  },
  LINKEDIN: () => {
    window.location.href = redirectionsForDomain.linkedin
  },
  DATASOURCE: () => {
    window.location.href = '/app/data-sources?refresh_user=true'
  },
  TIKTOK: () => {
    window.location.href = redirectionsForDomain.tiktok
  },
}

export function middlewareTokenError(error: AxiosError<{ message: string }>) {
  verifyErrorMessageBySource(error?.response?.data?.message)
}

export function middlewareTokenData(data: any) {
  // Verify dashboard data
  if (data?.dashboardData && Array.isArray(data?.dashboardData)) {
    for (const widgetData of data?.dashboardData ?? []) {
      if (widgetData?.data?.error) {
        verifyErrorMessageBySource(widgetData?.data?.error)
      }
    }
  }

  // Verify dashboard errors
  if (data?.dashboardErrors) {
    for (const error of data?.dashboardErrors ?? []) {
      verifyErrorMessageBySource(error?.message)
    }
  }

  if (data?.errors && Array.isArray(data?.errors)) {
    for (const error of data?.errors ?? []) {
      verifyErrorMessageBySource(error)
    }
  }

  if (data?.data?.error) {
    verifyErrorMessageBySource(data?.data?.error)
  }

  if (data?.values && Array.isArray(data?.values)) {
    for (const overviewClient of data?.values ?? []) {
      if (overviewClient?.errors?.messages) {
        for (const message of overviewClient?.errors?.messages as string[]) {
          verifyErrorMessageBySource(message)
        }
      }
    }
  }
}

const messagesBySource: { message: string; source: string; label: string }[] = [
  {
    message:
      'Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.',
    source: 'META',
    label: 'Facebook Ads',
  },
  {
    message: 'No meta access token',
    source: 'META',
    label: 'Facebook Ads',
  },
  {
    message: 'Invalid OAuth access token - Cannot parse access token',
    source: 'META',
    label: 'Facebook Ads',
  },
  {
    message: 'Failed to obtain Google Ads refresh token.',
    source: 'GOOGLE',
    label: 'Google Ads',
  },
  {
    message: 'Your LinkedIn account has been revoke',
    source: 'LINKEDIN',
    label: 'LinkedIn Ads',
  },
  {
    message: 'Source linkedin: Your LinkedIn account has been revoke',
    source: 'LINKEDIN',
    label: 'LinkedIn Ads',
  },
  {
    message: 'Failed to obtain Google Analytics refresh token.',
    source: 'GOOGLE_ANALYTICS',
    label: 'Google Analytics',
  },
  {
    message: 'Could not get access token for GA4',
    source: 'GOOGLE_ANALYTICS',
    label: 'Google Analytics',
  },
  {
    message: 'GA4 - The access token could not be decrypted',
    source: 'GOOGLE_ANALYTICS',
    label: 'Google Analytics',
  },
  // Generic error
  {
    message: 'The access token could not be decrypted',
    source: 'DATASOURCE',
    label: 'DATASOURCE',
  },
  {
    message:
      'meta_social - Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.',
    source: 'META_SOCIAL',
    label: 'Facebook Insights',
  },
  {
    message:
      'instagram_social - Error validating access token: The session has been invalidated because the user changed their password or Facebook has changed the session for security reasons.',
    source: 'INSTAGRAM_SOCIAL',
    label: 'Instagram Insights',
  },
  {
    message:
      'meta_social - Invalid OAuth access token - Cannot parse access token',
    source: 'META_SOCIAL',
    label: 'Facebook Insights',
  },
  {
    message:
      'instagram_social - Invalid OAuth access token - Cannot parse access token',
    source: 'INSTAGRAM_SOCIAL',
    label: 'Instagram Insights',
  },
  {
    message: 'Tiktok - Access token is incorrect or has been revoked',
    source: 'TIKTOK',
    label: 'Tiktok',
  },
]

function verifyErrorMessageBySource(msg?: string) {
  if (!msg) return

  for (const messageValidator of messagesBySource) {
    if (msg && msg === messageValidator.message) {
      const handleAddition = async () => {
        additionHandlers[messageValidator.source]()
      }

      if (messageValidator.source === 'DATASOURCE') {
        const message = `An invalid token has been detected for one of your datasources. Please check your datasource list to identify the failing one and reload it.`
        const title = `Please renew your token`
        NotificationManager.refreshMessage(
          message,
          title,
          30000,
          handleAddition,
          true,
          undefined,
          'Go to Datasources',
          messageValidator.source
        )
        continue
      }

      const message = `${messageValidator.label} token has been invalidated due to a password change or session modification for security reasons.
      Please renew your token`
      const title = `Reconnect ${messageValidator.label} Token`
      NotificationManager.refreshMessage(
        message,
        title,
        30000,
        handleAddition,
        true,
        undefined,
        'Reconnect Token',
        messageValidator.source
      )
    }
  }
}
