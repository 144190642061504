import React, { useMemo } from 'react'
import { FieldInputProps } from '../FieldEditor'
import { SelectWrapper } from './styles'
import SmartReportingSelect from '../../../../Forms/_control/SmartReportingSelect'

const CLEAR_ALL_OPT = {
  key: '_clear_all_',
  value: '_clear_all_',
  label: 'Clear Value',
}

const SingleSelectInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  onKeyDown,
  value,
  options,
}) => {
  const handleSelect = ({ target }: { target: { value: string } }) => {
    if (target.value === CLEAR_ALL_OPT.value) {
      onChange(null)
      return
    }
    onChange(target.value)
  }
  const _mergedoptions = useMemo(
    () => (options && options.length > 0 ? [...options, CLEAR_ALL_OPT] : []),
    [options]
  )

  return (
    <SelectWrapper>
      <SmartReportingSelect
        label={''}
        name={''}
        options={_mergedoptions}
        placeholder="Select one"
        className="react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={handleSelect}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus
      />
    </SelectWrapper>
  )
}

export default SingleSelectInput
