export const MapCompareMetricFactor: { [key: string]: number } = {
  purchase_roas: -1,
  amount_spent: 1,
  impressions: 1,
  clicks: 1,
  frequency: 1,
  reach: 1,
  ctr: 1,
  cpm: -1,
  cpc: -1,
  link_click: 1,
  ctr_link_click: 1,
  cpc_link_click: -1,
  video_views: 1,
  cost_per_conversion: -1,
  interactions: 1,
  cpc_link_clicks: -1,
  outbound_clicks: 1,
  cpc_outbound_click: -1,
  outbound_clicks_ctr: 1,
  unique_link_clicks: 1,
  cost_per_unique_inline_link_click: -1,
  unique_outbound_clicks: 1,
  unique_clicks: 1,
  cost_per_unique_click: -1,
  unique_ctr: 1,
  unique_link_clicks_ctr: 1,
  unique_outbound_clicks_ctr: 1,
  cost_per_unique_outbound_click: -1,
  cpp: -1,
  google_conversion_name_routing_form_all_web_site_data: 1,
  google_conversion_name_cost_routing_form_all_web_site_data: -1,
  google_conversion_name_calendly_meet_scheduled_all_web_site_data: 1,
  google_conversion_name_cost_calendly_meet_scheduled_all_web_site_data: -1,
  google_conversion_name_calendly_meet_intent_all_web_site_data: 1,
  google_conversion_name_cost_calendly_meet_intent_all_web_site_data: -1,
  google_conversion_name_completed_registration_all_web_site_data: 1,
  google_conversion_name_cost_completed_registration_all_web_site_data: -1,
  google_conversion_name_completed_registration: 1,
  google_conversion_name_cost_completed_registration: -1,
  google_conversion_name_accin_de_conversin_creada_automticamente_para_los_clientes_potenciales_calificados: 1,
  google_conversion_name_cost_accin_de_conversin_creada_automticamente_para_los_clientes_potenciales_calificados:
    -1,
  google_conversion_name_master_metrics_web_purchase: 1,
  google_conversion_name_cost_master_metrics_web_purchase: -1,
  google_conversion_name_master_metrics_web_sign_up_form: 1,
  google_conversion_name_cost_master_metrics_web_sign_up_form: -1,
  google_conversion_name_master_metrics_web_form_start: 1,
  google_conversion_name_cost_master_metrics_web_form_start: -1,
  costInUsd: 1,
  linkedin_cost_per_conversion: -1,
  linkedin_return_over_ad_spent: 1,
  linkedin_conversion_rate: 1,
  actionClicks: 1,
  adUnitClicks: 1,
  cardClicks: 1,
  cardImpressions: 1,
  commentLikes: 1,
  comments: 1,
  companyPageClicks: 1,
  documentCompletions: 1,
  documentFirstQuartileCompletions: 1,
  documentMidpointCompletions: 1,
  documentThirdQuartileCompletions: 1,
  downloadClicks: 1,
  externalWebsitePostClickConversions: 1,
  externalWebsitePostViewConversions: 1,
  follows: 1,
  fullScreenPlays: 1,
  jobApplications: 1,
  jobApplyClicks: 1,
  leadGenerationMailContactInfoShares: 1,
  leadGenerationMailInterestedClicks: 1,
  likes: 1,
  oneClickLeadFormOpens: 1,
  oneClickLeads: 1,
  opens: 1,
  otherEngagements: 1,
  pivotValues: 1,
  postClickJobApplications: 1,
  postClickJobApplyClicks: 1,
  postClickRegistrations: 1,
  postViewJobApplications: 1,
  postViewJobApplyClicks: 1,
  postViewRegistrations: 1,
  reactions: 1,
  registrations: 1,
  sends: 1,
  shares: 1,
  talentLeads: 1,
  textUrlClicks: 1,
  videoCompletions: 1,
  videoFirstQuartileCompletions: 1,
  videoMidpointCompletions: 1,
  videoStarts: 1,
  videoThirdQuartileCompletions: 1,
  videoViews: 1,
  viralCardClicks: 1,
  viralCardImpressions: 1,
  viralClicks: 1,
  viralCommentLikes: 1,
  viralComments: 1,
  viralCompanyPageClicks: 1,
  viralDocumentCompletions: 1,
  viralDocumentFirstQuartileCompletions: 1,
  viralDocumentMidpointCompletions: 1,
  viralDocumentThirdQuartileCompletions: 1,
  viralDownloadClicks: 1,
  viralExternalWebsiteConversions: 1,
  viralExternalWebsitePostClickConversions: 1,
  viralExternalWebsitePostViewConversions: 1,
  viralFollows: 1,
  viralFullScreenPlays: 1,
  viralImpressions: 1,
  viralJobApplications: 1,
  viralJobApplyClicks: 1,
  viralLandingPageClicks: 1,
  viralLikes: 1,
  viralOneClickLeadFormOpens: 1,
  viralOneClickLeads: 1,
  viralOtherEngagements: 1,
  viralPostClickJobApplications: 1,
  viralPostClickJobApplyClicks: 1,
  viralPostClickRegistrations: 1,
  viralPostViewJobApplications: 1,
  viralPostViewJobApplyClicks: 1,
  viralPostViewRegistrations: 1,
  viralReactions: 1,
  viralRegistrations: 1,
  viralShares: 1,
  viralTotalEngagements: 1,
  viralVideoCompletions: 1,
  viralVideoFirstQuartileCompletions: 1,
  viralVideoMidpointCompletions: 1,
  viralVideoStarts: 1,
  viralVideoThirdQuartileCompletions: 1,
  active1DayUsers: 1,
  active28DayUsers: 1,
  active7DayUsers: 1,
  activeUsers: 1,
  addToCarts: 1,
  adUnitExposure: 1,
  advertiserAdClicks: 1,
  advertiserAdCost: 1,
  advertiserAdCostPerClick: 1,
  advertiserAdCostPerConversion: 1,
  advertiserAdImpressions: 1,
  averagePurchaseRevenue: 1,
  averagePurchaseRevenuePerPayingUser: 1,
  averagePurchaseRevenuePerUser: 1,
  averageRevenuePerUser: 1,
  averageSessionDuration: 1,
  bounceRate: 1,
  cartToViewRate: 1,
  checkouts: 1,
  cohortActiveUsers: 1,
  cohortTotalUsers: 1,
  conversions: 1,
  crashAffectedUsers: 1,
  crashFreeUsersRate: 1,
  dauPerMau: 1,
  dauPerWau: 1,
  ecommercePurchases: 1,
  engagedSessions: 1,
  engagementRate: 1,
  eventCount: 1,
  eventCountPerUser: 1,
  eventsPerSession: 1,
  eventValue: 1,
  firstTimePurchaserConversionRate: 1,
  firstTimePurchasers: 1,
  firstTimePurchasersPerNewUser: 1,
  grossItemRevenue: 1,
  grossPurchaseRevenue: 1,
  itemDiscountAmount: 1,
  itemListClickEvents: 1,
  itemListClickThroughRate: 1,
  itemListViewEvents: 1,
  itemPromotionClickThroughRate: 1,
  itemRefundAmount: 1,
  itemRevenue: 1,
  itemsAddedToCart: 1,
  itemsCheckedOut: 1,
  itemsClickedInList: 1,
  itemsClickedInPromotion: 1,
  itemsPurchased: 1,
  itemsViewed: 1,
  itemsViewedInList: 1,
  itemsViewedInPromotion: 1,
  itemViewEvents: 1,
  newUsers: 1,
  organicGoogleSearchAveragePosition: 1,
  organicGoogleSearchClicks: 1,
  organicGoogleSearchClickThroughRate: 1,
  organicGoogleSearchImpressions: 1,
  promotionClicks: 1,
  promotionViews: 1,
  publisherAdClicks: 1,
  publisherAdImpressions: 1,
  purchaserConversionRate: 1,
  purchaseRevenue: 1,
  purchaseToViewRate: 1,
  refundAmount: 1,
  returnOnAdSpend: 1,
  screenPageViews: 1,
  screenPageViewsPerSession: 1,
  screenPageViewsPerUser: 1,
  scrolledUsers: 1,
  sessionConversionRate: 1,
  sessions: 1,
  sessionsPerUser: 1,
  shippingAmount: 1,
  taxAmount: 1,
  totalAdRevenue: 1,
  totalPurchasers: 1,
  totalRevenue: 1,
  totalUsers: 1,
  transactions: 1,
  transactionsPerPurchaser: 1,
  userConversionRate: 1,
  userEngagementDuration: 1,
  wauPerMau: 1,
  'conversions:add_to_cart': 1,
  'conversions:purchase': 1,
  'sessionConversionRate:add_to_cart': 1,
  'sessionConversionRate:purchase': 1,
  'userConversionRate:add_to_cart': 1,
  'userConversionRate:purchase': 1,
  video_avg_time_watched_actions: 1,
}

const availableSources = ['meta', 'linkedin', 'google']

function removeSourcePrefix(metric: string) {
  let cleanMetric = metric
  availableSources.forEach(
    source =>
      (cleanMetric = cleanMetric.replace(`${source.toUpperCase()}_`, ''))
  )
  return cleanMetric
}

export function getFactor(metric?: string, customFactor?: number) {
  if (customFactor) return customFactor
  if (!metric) return 0
  metric = removeSourcePrefix(metric)
  const costPerConvFactor =
    metric &&
    (metric.includes('conversion_cost_') ||
      metric.includes('google_conversion_name_cost_') ||
      metric.includes('linkedin_cost_per_conversion') ||
      metric.includes('google_cost_per_conversions'))
      ? -1
      : null
  const conversionFactor =
    metric &&
    (metric.includes('conversion_') ||
      metric.includes('google_conversion_name_') ||
      metric.includes('google_conversions') ||
      metric.includes('ga4_custom_event') ||
      metric.includes('userConversionRate') ||
      metric.includes('userKeyEventRate') ||
      metric.includes('sessionsConversionRate') ||
      metric.includes('externalWebsiteConversions'))
      ? 1
      : null

  return (
    MapCompareMetricFactor[metric] ?? costPerConvFactor ?? conversionFactor ?? 1
  )
}
