import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const DELETE_VIEW_KEY = 'Tables_DeleteView'

const deleteView = async (payload: { tableId: string; id: string }) => {
  const { tableId, id } = payload
  return tablesApi
    .delete(`/${tableId}/views/${id}`)
    .then(response => response.data)
}

const useDeleteView = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(deleteView, {
    mutationKey: [DELETE_VIEW_KEY],
    ...queryOptions,
  })
}

export default useDeleteView
