import styled from 'styled-components'
import { Card, CardBody } from 'reactstrap'

export const StyledCard = styled(Card)`
  height: auto;
  width: 100%;
  border-radius: 0.75rem;
  border: initial;
  background: white;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  border: 1px solid #d3d3d3;
`

export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
`

export const StyledCardBody = styled(CardBody)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 5px;
  text-align: center !important;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`

export const StyledDots = styled('div')`
  width: 6px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
`

export const StyledCardText = styled('p')`
  color: #4552e8;
  font-weight: 400;
  margin-bottom: 8px;
`

export const StyledLead = styled('p')`
  font-size: 14px;
  color: #1b215c;
  font-weight: 600;
  line-height: 1.2;
  height: 40px;
  text-align: center !important;
`
export const StyledSpan = styled('span')`
  display: block;
  font-weight: 400;
  font-size: 12px;
  padding-top: 3px;
`
