import { format, isValid, parse, parseISO } from 'date-fns'
import {
  DATE_FNS_FORMAT,
  DATE_FNS_FORMAT_YMD,
} from '../../SingleDatePicker/SingleDatePicker'
import { ColData } from '../types'

export const parseCustomOptions = (options?: Array<string>) => {
  if (!options) return
  return options.map(opt => ({
    key: opt,
    value: opt,
    label: opt.replaceAll('.', ' '),
  }))
}

export const parseCustomOptionValue = (option?: string) => {
  if (!option) return '-'
  return option.replaceAll('.', ' ')
}

export const parseCustomOptionMultiple = (option?: string | null) => {
  if (!option) return '-'
  return option.replaceAll(/[.,]/gi, match => (match === '.' ? ' ' : ', '))
}

export const formatDateMDY = (date: string | Date | null) => {
  if (!date) return '-'
  const fecha = typeof date === 'string' ? parseDate(date) : date

  if (!isValid(fecha)) return '-'
  return format(fecha, DATE_FNS_FORMAT)
}

export const parseDate = (fecha: string) => {
  if (isDateYMD(fecha)) {
    return parse(fecha, DATE_FNS_FORMAT_YMD, new Date())
  }

  if (isDateMDY(fecha)) {
    return parse(fecha, DATE_FNS_FORMAT, new Date())
  }

  return parseISO(fecha)
}

export function isDateYMD(value: string) {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/
  return dateRegex.test(value)
}

export function isDateMDY(value: string) {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/
  return dateRegex.test(value)
}

export function isDateISO(value: string) {
  const dateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{4}/
  return dateRegex.test(value)
}

export function formatCRMValue(value: string, column: ColData) {
  if (
    column.data.crm !== 'close' ||
    !column.data.metric?.includes('value') ||
    isNaN(+value)
  )
    return value

  return Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
