import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const UPDATE_SINGLE_COLUMN_DATA_KEY = 'Tables_UpdateSingleColumn'

export const updateSingleColumnData = async (payload: {
  [key: string]: any
}) => {
  const { id, columnId, ...data } = payload
  return tablesApi
    .put(`/${id}/columns/${columnId}/data`, data)
    .then(response => response.data)
}
const useUpdateSingleColumnData = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateSingleColumnData, {
    mutationKey: [UPDATE_SINGLE_COLUMN_DATA_KEY],
    ...queryOptions,
  })
}

export default useUpdateSingleColumnData
