import useUpdateSingleColumn from '../useUpdateSingleColumn'
import queryClient from '../../../reactQuery'
import { GET_ONE_TABLE_QUERY_KEY } from '../useGetOneUserTable'
import { TableReporting } from '@mm/ui/src/components/SmartTable/types'
import { handleError } from '@mm/ui/src/components/SmartTable/context/utils'
import { GET_ONE_TABLE_DASHBOARD_QUERY_KEY } from '../../../dashboardOverviews/requests/useGetOneDashboardOverview'

const useUpdateSingleColumnHook = (overviewId: string, widgetId?: string) => {
  const queryKey = widgetId
    ? [GET_ONE_TABLE_DASHBOARD_QUERY_KEY, widgetId]
    : [GET_ONE_TABLE_QUERY_KEY, overviewId]

  const mutation = useUpdateSingleColumn({
    onSuccess: data => {
      queryClient.setQueryData(queryKey, (table?: TableReporting) => {
        if (!table) return

        const newColumns = table.columns.map(c => {
          if (c.id !== data.id) return c

          return data
        })
        const tableCopy = { ...table }
        tableCopy.columns = newColumns
        return tableCopy
      })
    },
    onError: error => {
      queryClient.invalidateQueries(queryKey)
      handleError({ message: 'The table could not been updated' })
    },
  })

  const updateSingleColumnHandler = async (payload: any) => {
    try {
      const body = {
        ...payload,
        id: overviewId,
      }

      await mutation.mutateAsync(body)
    } catch (error) {
      console.error('Error in updateSingleColumnHandler:', error)
    }
  }

  return {
    updateSingleColumnHandler,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess,
  }
}

export default useUpdateSingleColumnHook
