import React, { useState, CSSProperties } from 'react'
import styled from 'styled-components'
import WarningIcon from '../../assets/icons/warning.svg'
import { Tooltip } from 'reactstrap'

const StyledButton = styled.button`
  position: absolute;
  border: none;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  top: 10px;
  left: 10px;
`

interface WarningIconTooltipProps {
  elementId: string
  error: string
  buttonStyle?: CSSProperties
}

const WarningIconTooltip = ({
  elementId,
  error,
  buttonStyle,
}: WarningIconTooltipProps) => {
  const [toggle, setToggle] = useState(false)

  if (!error) return null

  return (
    <>
      <StyledButton id={`warning-tooltip-${elementId}`} style={buttonStyle}>
        <img src={WarningIcon} alt="Warning Icon" />
      </StyledButton>

      <Tooltip
        placement={'left'}
        isOpen={toggle}
        target={`warning-tooltip-${elementId}`}
        toggle={() => setToggle(!toggle)}
      >
        <>
          <div key={`widget-error-${elementId}`}>{error}</div>
        </>
      </Tooltip>
    </>
  )
}

export default WarningIconTooltip
