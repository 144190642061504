import React from 'react'

export function Element({ attributes, children, element }) {
  const validateHttp = url => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url
    }
    return url
  }

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>

    case 'bulleted-list':
      return (
        <ul style={{ marginBottom: '0px' }} {...attributes}>
          {children}
        </ul>
      )

    case 'heading-one':
      return (
        <h2
          style={{ margin: '0px', lineHeight: '2.5rem', fontSize: '2.5rem' }}
          {...attributes}
        >
          {children}
        </h2>
      )

    case 'heading-two':
      return (
        <h2
          style={{ margin: '0px', lineHeight: '1.6rem', fontSize: '1.6rem' }}
          {...attributes}
        >
          {children}
        </h2>
      )

    case 'link':
      return (
        <a
          style={{ cursor: 'pointer', color: '#0000EE' }}
          rel="noreferrer noopener"
          {...attributes}
          target="_blank"
          href={validateHttp(element.url)}
          title={element.url}
        >
          {children}
        </a>
      )

    case 'list-item':
      return (
        <li style={{ fontSize: '1rem' }} {...attributes}>
          {children}
        </li>
      )

    case 'numbered-list':
      return (
        <ol style={{ fontSize: '1rem', marginBottom: '0px' }} {...attributes}>
          {children}
        </ol>
      )

    default:
      return (
        <p style={{ marginBottom: '0px', fontSize: '1rem' }} {...attributes}>
          {children}
        </p>
      )
  }
}

export function Leaf({ attributes, children, leaf }) {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
}
