import React, { useState } from 'react'
import Tooltip from '../Highcharts/Tooltip'
import Loader from '../Loader'
import {
  StyleTitle,
  StyleTitleContainer,
  StyledCard,
  StyledCardBody,
  StyledDots,
  StyledViewOptions,
} from './Style'

const TextContainer = ({
  id,
  isLoading,
  name,
  onDuplicate,
  onEdit,
  onDelete,
  edited,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [hideActions] = useState(import.meta.env.VITE_APP_IS_PUBLIC === 'false')

  const handleToggleTooltip = () => {
    setShowTooltip(prev => !prev)
  }

  return (
    <StyledCard id={`title-${id}`}>
      <StyledCardBody>
        {!name ? (
          <div style={{ margin: '54px' }}>
            <Loader />
          </div>
        ) : (
          <>
            {hideActions && (
              <>
                <StyledViewOptions
                  onClick={handleToggleTooltip}
                  className="view-options"
                  onMouseDown={e => e.stopPropagation()}
                  onMouseMove={e => e.stopPropagation()}
                >
                  <StyledDots className="dots"></StyledDots>
                </StyledViewOptions>
                <div style={{ position: 'relative', top: '-23px' }}>
                  <Tooltip
                    show={showTooltip}
                    toggle={handleToggleTooltip}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    loading={isLoading}
                  />
                </div>
              </>
            )}
            <StyleTitleContainer>
              <StyleTitle>{name}</StyleTitle>
            </StyleTitleContainer>
          </>
        )}
      </StyledCardBody>
    </StyledCard>
  )
}

export default TextContainer
