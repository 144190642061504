import styled from 'styled-components'

export const IaDescriptionTitle = styled.div`
  margin-left: 15px;
  padding-top: 5px;
  color: #8f8f8f;
`

export const IaDescriptionTitleContainer = styled.div`
  display: flex;
  margin: auto 0px auto auto;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: linear-gradient(
    45deg,
    rgb(11 255 255 / 10%),
    rgb(248 176 248 / 10%)
  );
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 8px;
  margin-bottom: 16px;
`

export const Title = styled.h2`
  font-size: 18px;
  color: #333333;
  margin: 0;
`

export const Description = styled.p`
  font-size: 14px;
  color: #666666;
  margin: 0;
  padding: 16px;
`

export const ErrorDescription = styled.p`
  font-size: 14px;
  color: red;
  margin: 0;
  padding: 16px;
`

export const Footer = styled.div`
  display: flex;
  align-items: right;
  border-top: 1px solid #e1e1e1;
  padding: 8px;
  padding-right: 16px;
  background-color: rgb(248 248 248);
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  background: linear-gradient(45deg, #fad3d657, rgb(248 176 248 / 10%));
  width: 100%;
`
