import styled from 'styled-components'

export const WarningWrapper = styled.div`
  width: 20px;
  heigth: 20px;
  img {
    width: 100%;
  }
  cursor: help;
`
