import { Modal } from 'reactstrap'
import styled from 'styled-components'

export const ModalWrapper = styled(Modal)`
  &.modal-dialog {
    position: relative;
    top: 40vh;
  }
  .modal-content {
    width: 60vw;
    border-radius: 8px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  min-height: 40px;
  position: relative;
  top: 8px;
  gap: 10px;
  right: 0px;
  padding: 0px;
  min-width: 40px;
`
