import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const UPDATE_SINGLE_COLUMN_KEY = 'Tables_UpdateSingleColumn'

const updateSingleColumn = async (payload: { [key: string]: any }) => {
  const { id, columnId, ...data } = payload
  return tablesApi
    .put(`/${id}/columns/${columnId}`, data)
    .then(response => response.data)
}
const useUpdateSingleColumn = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateSingleColumn, {
    mutationKey: [UPDATE_SINGLE_COLUMN_KEY],
    ...queryOptions,
  })
}

export default useUpdateSingleColumn
