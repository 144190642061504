import React from 'react'
import { VariableSizeGrid as Grid } from 'react-window'

function getCellIndicies(child) {
  return { row: child.props.rowIndex, column: child.props.columnIndex }
}

function getShownIndicies(children) {
  let minRow = Infinity
  let maxRow = -Infinity
  let minColumn = Infinity
  let maxColumn = -Infinity

  React.Children.forEach(children, child => {
    const { row, column } = getCellIndicies(child)
    minRow = Math.min(minRow, row)
    maxRow = Math.max(maxRow, row)
    minColumn = Math.min(minColumn, column)
    maxColumn = Math.max(maxColumn, column)
  })

  return {
    from: {
      row: minRow,
      column: minColumn,
    },
    to: {
      row: maxRow,
      column: maxColumn,
    },
  }
}

function useInnerElementType(Cell, columnWidth, rowHeight, fixedColumns = 1) {
  return React.useMemo(
    () =>
      React.forwardRef((props, ref) => {
        function sumRowsHeights(index) {
          let sum = 0

          while (index > 1) {
            sum += rowHeight(index - 1)
            index -= 1
          }

          return sum
        }

        function sumColumnWidthsFT(from, to) {
          let sum = 0
          for (let index = from; index < to; index++) {
            sum += columnWidth(index)
          }
          return sum
        }

        const shownIndecies = getShownIndicies(props.children)
        const showFixedColumns = fixedColumns - 1
        const children = React.Children.map(props.children, child => {
          const { column, row } = getCellIndicies(child)

          if (column < fixedColumns || row === 0) {
            return null
          }

          return child
        })
        for (let fixColIdx = 0; fixColIdx <= showFixedColumns; fixColIdx++) {
          children.push(
            React.createElement(Cell, {
              key: `0:${fixColIdx}`,
              rowIndex: 0,
              columnIndex: fixColIdx,
              style: {
                display: 'inline-flex',
                width: columnWidth(fixColIdx),
                height: rowHeight(0),
                position: 'sticky',
                top: 0,
                left: fixColIdx === 0 ? 0 : sumColumnWidthsFT(0, fixColIdx),
                zIndex: 4,
              },
            })
          )
        }

        const shownColumnsCount =
          shownIndecies.to.column - shownIndecies.from.column

        for (let i = fixedColumns; i <= shownColumnsCount; i += 1) {
          const columnIndex = i + shownIndecies.from.column
          const rowIndex = 0
          const width = columnWidth(columnIndex)
          const height = rowHeight(rowIndex)

          const marginLeft =
            shownIndecies.from.column !== 0 &&
            columnIndex === shownIndecies.from.column + fixedColumns
              ? sumColumnWidthsFT(
                  fixedColumns,
                  fixedColumns + shownIndecies.from.column
                )
              : 0
          const colsWidth = sumColumnWidthsFT(0, columnIndex + 1) - marginLeft
          const marginRight =
            columnIndex === shownIndecies.to.column
              ? `calc( 100% - ${colsWidth}px)`
              : 0
          children.push(
            React.createElement(Cell, {
              key: `${rowIndex}:${columnIndex}`,
              rowIndex,
              columnIndex,
              style: {
                marginLeft,
                marginRight,
                display: 'inline-flex',
                width,
                height,
                position: 'sticky',
                top: 0,
                zIndex: 3,
              },
            })
          )
        }

        const shownRowsCount = shownIndecies.to.row - shownIndecies.from.row

        for (let i = 1; i <= shownRowsCount; i += 1) {
          for (let j = 0; j <= showFixedColumns; j += 1) {
            const columnIndex = j
            const rowIndex = i + shownIndecies.from.row
            const width = columnWidth(columnIndex)
            const height = rowHeight(rowIndex)

            const left =
              columnIndex === 0 ? 0 : sumColumnWidthsFT(0, columnIndex)
            const colsWidth = sumColumnWidthsFT(0, columnIndex + 1)
            const marginRight =
              showFixedColumns === columnIndex
                ? `calc( 100% - ${colsWidth}px)`
                : undefined

            const marginTop = i === 1 ? sumRowsHeights(rowIndex) : undefined
            const borderRight =
              columnIndex === fixedColumns - 1
                ? '3px solid darkgray'
                : undefined

            children.push(
              React.createElement(Cell, {
                key: `${rowIndex}:${columnIndex}`,
                rowIndex,
                columnIndex,
                isLastSticky: showFixedColumns === columnIndex,
                marginRight,
                style: {
                  marginTop,
                  display: 'inline-flex',
                  width,
                  height,
                  position: 'sticky',
                  left,
                  zIndex: columnIndex === 0 ? 3 : 2,
                  overflow: columnIndex === 0 ? 'visible' : 'hidden',
                  borderRight,
                },
              })
            )
          }
        }

        return (
          <div ref={ref} {...props}>
            {children}
          </div>
        )
      }),
    [Cell, columnWidth, rowHeight]
  )
}

function StickyGridFn(props, ref) {
  return (
    <Grid
      ref={ref}
      {...props}
      innerElementType={useInnerElementType(
        props.children,
        props.columnWidth,
        props.rowHeight,
        props.fixedColumns
      )}
    />
  )
}

export const StickyGrid = React.forwardRef(StickyGridFn)
