import React, { useMemo, useState } from 'react'
import { Tooltip } from 'reactstrap'
import { calculateDiff } from '../SmartTable/ComparedValueMarker/ComparedValueMarker'
import { getFactor } from '../SmartTable/ComparedValueMarker/mapCompareMetricFactor'
import { ComparedArrowCell, ComparedTextCell } from '../SmartTable/Styles'

interface CompareMarkerProps {
  metric: string
  current?: number
  comparedValue?: number
  fontSize?: number
  previousDataString?: string
  widgetId?: string
  formatedPreviousPeriod?: string
}

export const CompareMarker = ({
  metric,
  current,
  comparedValue,
  fontSize,
  previousDataString,
  widgetId,
  formatedPreviousPeriod,
}: CompareMarkerProps) => {
  const factor = useMemo(() => {
    return getFactor(metric)
  }, [metric])

  const [toggle, setToggle] = useState(false)
  const targetId = document.getElementById(`powertile-value-${widgetId}`)
    ? `powertile-value-${widgetId}`
    : undefined

  const onToggle = () => {
    setToggle(!toggle)
  }

  const increaseNumber =
    current && comparedValue && Number(current) > Number(comparedValue)
      ? true
      : false

  const arrowDir = increaseNumber ? ` ↑ ` : ` ↓ `
  const diff = calculateDiff(current, comparedValue)

  if (diff === undefined) {
    return (
      <>
        <div style={{ color: 'rgb(145 145 145)' }}>-</div>
      </>
    )
  }

  const diffPercentaje = diff * factor
  const increaseDiffPercentaje = diffPercentaje > 0 ? true : false

  if (!metric) return null

  if (!factor) {
    return null
  }

  const toggleText = `${diffPercentaje} % (${formatedPreviousPeriod}) `

  return (
    <div id={`powertile-value-${widgetId}`}>
      <ComparedArrowCell
        increase={increaseDiffPercentaje}
        style={{ fontSize: `${fontSize}px` }}
      >
        {arrowDir}
      </ComparedArrowCell>
      <ComparedTextCell increase={increaseDiffPercentaje}>{`${Math.abs(
        diffPercentaje
      )} %`}</ComparedTextCell>
      {targetId ? (
        <Tooltip
          placement={'bottom'}
          isOpen={toggle}
          target={targetId}
          toggle={onToggle}
        >
          <div>{toggleText}</div>
          <div>{previousDataString}</div>
        </Tooltip>
      ) : null}
    </div>
  )
}
