import React from 'react'

const Loader = ({
  small,
  white,
  style,
}: {
  small?: boolean
  white?: boolean
  style?: { [k: string]: string }
}) => (
  <div className="loader-container" style={{ ...style }}>
    <div className={`loader-wrap${small ? ' small' : ''}`}>
      <div className={`loader${white ? ' white' : ''}`} />
    </div>
  </div>
)

export default Loader
