import styled from 'styled-components'
import { Card, CardBody, CardTitle } from 'reactstrap'

export const StyledCard = styled(Card)`
  height: auto;
  width: 100%;
  border-radius: 0.75rem;
  border: initial;
  background: white;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  top: 0;
  padding: 1.25rem;
  overflow: auto;
  border: 1px solid #d3d3d3;
`
export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

export const StyledCardBody = styled(CardBody)`
  padding: 1.75rem;
  overflow: inherit;
  webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  display: flex !important;
  padding: 1.25rem;
  width: 100%;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`
export const StyledDots = styled('div')`
  width: 6px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
`

export const StyledCardTitle = styled(CardTitle)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
`

export const StyledTableTitle = styled('h2')`
  color: #1b215c;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
`

export const StyledResponsiveTable = styled('div')`
  display: block;
  width: 100%;
  overflow: inherit;
`

export const StyledTh = styled('th')`
  border: 0;
  font-size: 12px;
  color: #4552e8;
  vertical-align: bottom;
  font-weight: 400;
  text-align: center;
  &:nth-child(1) {
    text-align: left;
  }
`

export const ErrorTr = styled('tr')`
  display: flex;
  width: 100%;
  font-weight: 400;
  text-align: left;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border: 0;
  color: red;
`

export const TableData = styled.td<{ hoverMessage: string; warning: string }>`
  color: ${({ hoverMessage, warning }) =>
    hoverMessage ? (warning ? '#ff9800' : 'red') : 'inherit'};
  text-align: center;
  border-top: 0;
  white-space: pre;
  padding-bottom: 5px;
  border-color: #f3f3f3;
  padding: 0.75rem;
  vertical-align: top;
`
export const DateLabel = styled.span`
  position: relative;
  text-align: center;
  font-size: 14px;
`
