import React, { useEffect, useMemo, useState } from 'react'

import { Context } from './context'
import useGetUserTables, {
  GET_TABLES_QUERY_KEY,
} from '@mm/api/src/tables/requests/useGetUserTables'
import queryClient from '@mm/api/src/reactQuery'
import useGetTableViews, {
  GET_TABLES_VIEWS,
} from '@mm/api/src/tables/requests/useGetTableViews'
import useCreateView from '@mm/api/src/tables/requests/useCreateView'
import useUpdateView from '@mm/api/src/tables/requests/useUpdateView'
import useDeleteView from '@mm/api/src/tables/requests/useDeleteView'
import { getColumnsViewConfig, handleError } from './utils'
import {
  ClientData,
  ColumnReporting,
  TableReporting,
  View,
  ViewFilterCollection,
} from '../types'
import useUpdateSingleColumnData from '@mm/api/src/tables/requests/useUpdateSingleColumnData'
// import { handleError } from '../../../../api'
import useCreateClient from '@mm/api/src/tables/requests/useCreateClient'
import { FETCH_TABLE_DATA } from '@mm/api/src/tables/requests/useTableData'
import useUpdateClient from '@mm/api/src/tables/requests/useUpdateClient'
import useRemoveClient from '@mm/api/src/tables/requests/useRemoveClient'
import useUpdateAllClients from '@mm/api/src/tables/requests/useUpdateAllClients'
import useGetOneUserTable, {
  GET_ONE_TABLE_QUERY_KEY,
} from '@mm/api/src/tables/requests/useGetOneUserTable'
import { GET_TABLES_METRICS } from '@mm/api/src/tables/requests/useGetTableMetrics'
import { GET_TABLES_VIEW_BY_ID } from '@mm/api/src/tables/requests/useGetTableViewById'

export const BASE_STORAGE_KEY_CLIENT_TABLE = 'MM_OVERVIEW_TABLE_CLIENT_TABLE_ID'
export const BASE_STORAGE_KEY_TABLE = 'MM_OVERVIEW_TABLE_TABLE_ID'
export const BASE_STORAGE_KEY_VIEW = 'MM_V2_OVERVIEW_TABLE_VIEW_ID'
export const BASE_FILTER =
  '{"AND":[{"id":"7d1c5c1d-6ce1-43f0-8a96-fcaef71dd403","property":"amount_spent","filter":"major","type":"number","value":"0"}], "OR":[]}'
export const EMPTY_FILTER = '{ "OR": [], "AND": []}'

export const useProvider = (
  type: 'campaign' | 'client',
  from: 'dashboard' | 'overview' = 'overview'
) => {
  const [id, setId] = useState<string | null>(null)
  const [filters, setFilters] = useState(
    type === 'campaign' ? BASE_FILTER : EMPTY_FILTER
  )
  const [appliedView, setAppliedView] = useState<{
    label: string
    value: {
      id: string
      filters: ViewFilterCollection
    }
    key: string
  } | null>(null)
  const [columnsView, setColumnsView] = useState(null)
  const [changeTable, setChangeTable] = useState(false)
  const [invalidateViewDate, setInvalidateViewDate] = useState(false)
  const [tableKey, setTableKey] = useState(Math.random())
  const [lastRefreshTime, setLastRefreshTime] = useState(0)
  const [changedColumns, setChangedColumns] = useState(0)
  const [changedClients, setChangedClients] = useState(0)

  const { isLoading: isFetching, data: tables } = useGetUserTables(type, {
    staleTime: Infinity,
    cacheTime: Infinity,
  })

  const {
    data: currentTable,
    isLoading: loadingCurrentTable,
    refetch: refetchCurrentTable,
  } = useGetOneUserTable(id)

  const refreshTableKey = () => {
    setTableKey(Math.random())
  }

  const removeColumnsOnTable = async ({
    columns,
    refresh,
  }: {
    columns: Array<ColumnReporting>
    refresh?: boolean
  }) => {
    refresh && (await refetchTableData())
    queryClient.setQueryData(
      [GET_ONE_TABLE_QUERY_KEY, id],
      (table?: TableReporting) => {
        if (!table) return
        table.columns = columns
        setChangedColumns(Date.now())
        setChangeTable(true)
        return table
      }
    )
  }

  const upsertColumnsOnTable = async ({
    columns,
    refresh,
  }: {
    columns: Array<ColumnReporting>
    refresh?: boolean
  }) => {
    refresh && (await refetchTableData())
    queryClient.setQueryData(
      [GET_ONE_TABLE_QUERY_KEY, id],
      (table?: TableReporting) => {
        if (!table) return
        const targetColumns = table.columns
        columns.forEach(col => {
          const index = targetColumns.findIndex(
            prevCol => col.id === prevCol.id
          )
          if (index !== -1) {
            targetColumns[index] = col
          } else {
            targetColumns.push(col)
          }
        })
        setChangedColumns(Date.now())
        return table
      }
    )
  }

  const refetchTableData = () => {
    queryClient.invalidateQueries([FETCH_TABLE_DATA, id])
  }

  useEffect(() => {
    const keyView = `${BASE_STORAGE_KEY_VIEW}_${id}`
    if (appliedView) {
      localStorage.setItem(keyView, appliedView.key)
    } else {
      localStorage.removeItem(keyView)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedView])

  useEffect(() => {
    if (from === 'dashboard') return
    if (tables) {
      const tableKey =
        type === 'campaign'
          ? BASE_STORAGE_KEY_TABLE
          : BASE_STORAGE_KEY_CLIENT_TABLE
      if (id) {
        localStorage.setItem(tableKey, JSON.stringify(id))
      } else {
        localStorage.removeItem(tableKey)
      }
      setChangeTable(false)
    }
  }, [id, tables])

  const hasUnsavedChanges = useMemo(() => {
    const viewFilters = JSON.stringify(
      appliedView?.value?.filters ||
        JSON.parse(type === 'campaign' ? BASE_FILTER : EMPTY_FILTER)
    )
    const hasFiltersChange = viewFilters !== filters
    const _hasUnsavedChanges = hasFiltersChange || changeTable
    return _hasUnsavedChanges
  }, [appliedView, filters, changeTable])

  const refetchTables = async () => {
    await queryClient.refetchQueries([GET_TABLES_QUERY_KEY, type], undefined, {
      throwOnError: true,
    })
  }
  const { data: views, isError } = useGetTableViews(id)

  useEffect(() => {
    if (isError) {
      const keyView = `${BASE_STORAGE_KEY_VIEW}_${id}`
      localStorage.removeItem(keyView)
    }
  }, [isError, id])

  const createViewRequest = useCreateView({
    onSuccess: data => {
      queryClient.refetchQueries([GET_TABLES_VIEWS, id], undefined, {
        throwOnError: true,
      })
      setView(data[0])
    },
  })

  const refreshTableData = {
    onSuccess: async () => {
      await refetchCurrentTable()
      queryClient.invalidateQueries([FETCH_TABLE_DATA, id])
      queryClient.invalidateQueries([GET_TABLES_METRICS])
    },
  }

  const createClientRequest = useCreateClient(refreshTableData)

  const updateClientRequest = useUpdateClient(refreshTableData)

  const removeClientRequest = useRemoveClient({
    onMutate: (data: any) => {
      queryClient.setQueryData(
        [GET_ONE_TABLE_QUERY_KEY, id],
        (table?: TableReporting) => {
          if (!table) return
          table.clients = (table.clients || []).filter(
            client => client.id !== data.id
          )
          setChangedClients(Date.now())
          return table
        }
      )
    },
    onError: () => {
      queryClient.invalidateQueries([GET_ONE_TABLE_QUERY_KEY, id])
    },
  })

  const createNewClient = (data: ClientData) => {
    if (data.id) {
      updateClientRequest.mutate({ tableId: id!, data })
    } else {
      createClientRequest.mutate({ tableId: data.tableId || id!, data })
    }
  }

  const removeClient = (clientId: string) => {
    removeClientRequest.mutate({ tableId: id!, id: clientId })
  }

  const duplicateClient = (clientId: string) => {
    if (!currentTable) return
    const _client = currentTable.clients.find(
      (client: ClientData) => client.id === clientId
    )
    if (!_client) return
    const clientCopy = {
      ..._client,
    }
    delete clientCopy.id
    clientCopy.name = `Copy of ${clientCopy.name}`
    createClientRequest.mutate({ tableId: id!, data: clientCopy })
  }

  const updateViewRequest = useUpdateView({
    onSuccess: data => {
      queryClient.refetchQueries([GET_TABLES_VIEWS, id], undefined, {
        throwOnError: true,
      })
      setView(data)
    },
  })
  const deleteViewRequest = useDeleteView({
    onSuccess: data => {
      queryClient.refetchQueries([GET_TABLES_VIEWS, id], undefined, {
        throwOnError: true,
      })
      setView(null)
    },
  })

  const createNewView = (name: string) => {
    const { order, activeColumns } = getColumnsViewConfig(columnsView)
    const { date, additional_config } = currentTable
    const payload = {
      tableId: id!,
      views: [
        {
          name,
          filters: JSON.parse(filters),
          order,
          activeColumns,
          date,
          additional_config,
        },
      ],
    }
    createViewRequest.mutate(payload)
    setChangeTable(false)
  }

  const duplicateView = () => {
    if (appliedView) {
      const { order, activeColumns } = getColumnsViewConfig(columnsView)
      const copyName = `Copy of ${appliedView.label}`
      const copies = views.filter((view: View) =>
        view.name.startsWith(copyName)
      )
      const name = `${copyName}${
        copies.length > 0 ? ` (${copies.length})` : ''
      }`
      const { date, additional_config } = currentTable
      const payload = {
        tableId: id!,
        views: [
          {
            name,
            filters: JSON.parse(filters),
            order,
            activeColumns,
            date,
            additional_config,
          },
        ],
      }
      createViewRequest.mutate(payload)
      setChangeTable(false)
    }
  }

  const updateView = () => {
    if (appliedView) {
      const { order, activeColumns } = getColumnsViewConfig(columnsView)
      const { date, additional_config } = currentTable
      const payload = {
        tableId: id,
        id: appliedView.value.id,
        filters: JSON.parse(filters),
        order,
        activeColumns,
        date,
        additional_config,
      }
      queryClient.invalidateQueries([
        GET_TABLES_VIEW_BY_ID,
        id,
        appliedView.value.id,
      ])
      updateViewRequest.mutate(payload)
      setChangeTable(false)
    }
  }

  const editView = (name: string) => {
    if (appliedView) {
      const payload = {
        tableId: id,
        id: appliedView.value.id,
        name,
      }
      updateViewRequest.mutate(payload)
    }
  }

  const deleteView = () => {
    if (appliedView) {
      const payload = {
        tableId: id!,
        id: appliedView.value.id,
      }
      deleteViewRequest.mutate(payload)
      setChangeTable(false)
    }
  }

  const setView = (view: View | null) => {
    if (!view) {
      setAppliedView(null)
      setFilters(type === 'campaign' ? BASE_FILTER : EMPTY_FILTER)
      return
    }
    const label = view.name
    const key = view.id
    setAppliedView({ label, key, value: view })

    setFilters(JSON.stringify(view.filters))
    setChangeTable(false)
  }

  const updateColumnDataRequest = useUpdateSingleColumnData({
    onError: error => {
      handleError(error)
    },
    onSuccess: data => {
      upsertColumnsOnTable({ columns: [data], refresh: false })
    },
  })

  const updateCustomFieldData = ({
    columnId,
    rowId,
    payload,
    refresh,
  }: {
    columnId: string
    rowId: string
    payload: string
    refresh?: boolean
  }) => {
    updateColumnDataRequest.mutate(
      { id, columnId, rowId, payload },
      {
        onSuccess: () => {
          if (refresh) {
            refetchTableData()
          }
        },
      }
    )
  }

  const { sources, accounts, clients } = useMemo(() => {
    if (!currentTable) {
      return {
        sources: [],
        accounts: [],
        clients: [],
      }
    }
    if (type === 'campaign') {
      return {
        sources: currentTable.sources,
        accounts: (currentTable.adAccounts || []).map(
          (a: { id: string }) => a.id
        ),
      }
    }
    const uniqueSources = new Set<string>()
    const uniqueAccounts = new Set<string>()
    if (currentTable.clients?.length) {
      currentTable.clients.forEach((client: ClientData) => {
        client.configuration.forEach(configuration => {
          uniqueSources.add(configuration.source!.id)
          configuration.accounts.forEach(account => {
            uniqueAccounts.add(account.id as string)
          })
        })
      })
      currentTable.clients.sort(
        (prevClient: ClientData, nextClient: ClientData) =>
          prevClient.sort! - nextClient.sort!
      )
    }
    return {
      sources: Array.from(uniqueSources),
      accounts: Array.from(uniqueAccounts),
      clients: currentTable.clients,
    }
  }, [currentTable, changedClients])

  const updateClientsDataRequest = useUpdateAllClients({
    onError: error => {
      refetchCurrentTable()
      handleError(error)
    },
    onMutate: (updateClients: any) => {
      if (!updateClients) return
      queryClient.setQueryData(
        [GET_ONE_TABLE_QUERY_KEY, id],
        (table?: TableReporting) => {
          if (!table) return
          const tableCopy = { ...table }
          tableCopy.clients = updateClients.data
          return tableCopy
        }
      )
    },
  })

  const handleDragEnd = (start: string, end: string) => {
    if (start !== end) {
      const clientsCopy = [...clients]
      const startDrag = clientsCopy.findIndex(client => client.id === start)
      const endDrag = clientsCopy.findIndex(client => client.id === end)
      if (endDrag >= 0 && startDrag >= 0) {
        const element = clientsCopy.splice(startDrag, 1)
        clientsCopy.splice(endDrag, 0, element[0])
        const newClients = clientsCopy.map((client, idx) => {
          return {
            ...client,
            sort: idx,
          }
        })
        updateClientsDataRequest.mutate({ tableId: id!, data: newClients })
      }
    }
  }

  return {
    filters,
    setFilters,
    isFetching,
    tables,
    refetchTables,
    views,
    appliedView,
    setAppliedView,
    setId,
    createNewView,
    updateView,
    editView,
    duplicateView,
    hasUnsavedChanges,
    deleteView,
    setView,
    setColumnsView,
    columnsView,
    id,
    upsertColumnsOnTable,
    tableKey,
    refreshTableKey,
    updateCustomFieldData,
    invalidateViewDate,
    setInvalidateViewDate,
    setChangeTable,
    type,
    accounts,
    sources,
    createNewClient,
    clients,
    removeClient,
    duplicateClient,
    handleDragEnd,
    lastRefreshTime,
    setLastRefreshTime,
    currentTable,
    loadingCurrentTable,
    refetchCurrentTable,
    changedColumns,
    removeColumnsOnTable,
    setChangedColumns,
  }
}

type Props = {
  children: React.ReactNode
  type: 'campaign' | 'client'
  from?: 'dashboard' | 'overview'
}

export const Provider = ({ children, type, from }: Props) => {
  const value = useProvider(type, from)

  return <Context.Provider value={value}>{children}</Context.Provider>
}
