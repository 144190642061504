import './App.css'
import DashboardContainer from './components/DashboardContainer'
import { QueryClientProvider } from '@tanstack/react-query'
import defaultQueryClient from '@mm/api/src/reactQuery'

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={defaultQueryClient}>
        <DashboardContainer />
      </QueryClientProvider>
    </div>
  )
}

export default App
