export const urlByFreeApp: { [k: string]: string } = {
  freeAlerts: import.meta.env.VITE_FREE_ALERTS_DOMAIN,
  freeDashboards: import.meta.env.VITE_FREE_DASHBOARDS_DOMAIN,
  freeOverviews: import.meta.env.VITE_FREE_OVERVIEWS_DOMAIN,
  alertsLTV: import.meta.env.VITE_ALERTS_LTV_DOMAIN,
}

export const urlByApp: { [k: string]: string } = {
  ...urlByFreeApp,
  app: import.meta.env.VITE_MASTER_METRICS_DOMAIN,
}

export const appByPlan: { [k: string]: string } = {
  freeAlerts: 'Free Alerts',
  freeDashboards: 'Free Dashboards',
  freeOverviews: 'Free Overviews',
  alertsLTV: 'Life time Alerts',
}

function changeRedirectionToApp(url: string, app: string, path: string) {
  if (!url || !urlByApp[app]) return ''
  const parsedUrl = new URL(url)
  const redirectUri = new URL(`${urlByApp[app]}${path}`)
  parsedUrl.searchParams.set('redirect_uri', redirectUri.toString())
  return parsedUrl.toString()
}

const masterMetricsRedirections: { [k: string]: string } = {
  hubspot: import.meta.env.VITE_APP_HUBSPOT_OAUTH_REDIRECT_URI,
  meta: import.meta.env.VITE_APP_META_OAUTH_REDIRECT_URI,
  google: import.meta.env.VITE_APP_GOOGLE_OAUTH_REDIRECT_URI,
  google_analytics: import.meta.env
    .VITE_APP_GOOGLE_ANALYTICS_OAUTH_REDIRECT_URI,
  linkedin: import.meta.env.VITE_APP_LINKEDIN_OAUTH_REDIRECT_URI,
  meta_social: import.meta.env.VITE_APP_META_SOCIAL_OAUTH_REDIRECT_URI,
  instagram_social: import.meta.env
    .VITE_APP_META_SOCIAL_INSTAGRAM_OAUTH_REDIRECT_URI,
  asana: import.meta.env.VITE_ASANA_OAUTH_REDIRECT_URI,
  monday: import.meta.env.VITE_MONDAY_OAUTH_REDIRECT_URI,
  clickup: import.meta.env.VITE_CLICKUP_OAUTH_REDIRECT_URI,
  slack: import.meta.env.VITE_SLACK_OAUTH_REDIRECT_URI,
  tiktok: import.meta.env.VITE_APP_TIKTOK_OAUTH_REDIRECT_URI,
}

const createDomainRedirections = (
  app: string,
  redirectPath = '/app/data-sources'
) => {
  return {
    meta: changeRedirectionToApp(
      masterMetricsRedirections.meta,
      app,
      redirectPath
    ),
    google: changeRedirectionToApp(
      masterMetricsRedirections.google,
      app,
      redirectPath
    ),
    google_analytics: changeRedirectionToApp(
      masterMetricsRedirections.google_analytics,
      app,
      redirectPath
    ),
    linkedin: changeRedirectionToApp(
      masterMetricsRedirections.linkedin,
      app,
      redirectPath
    ),
    meta_social: changeRedirectionToApp(
      masterMetricsRedirections.meta_social,
      app,
      redirectPath
    ),
    instagram_social: changeRedirectionToApp(
      masterMetricsRedirections.instagram_social,
      app,
      redirectPath
    ),
    hubspot: changeRedirectionToApp(
      masterMetricsRedirections.hubspot,
      app,
      redirectPath
    ),
    asana: changeRedirectionToApp(
      masterMetricsRedirections.asana,
      app,
      '/app/integrations'
    ),
    monday: changeRedirectionToApp(
      masterMetricsRedirections.monday,
      app,
      '/app/integrations'
    ),
    clickup: changeRedirectionToApp(
      masterMetricsRedirections.clickup,
      app,
      '/app/integrations'
    ),
    slack: changeRedirectionToApp(
      masterMetricsRedirections.slack,
      app,
      '/app/integrations'
    ),
    tiktok: changeRedirectionToApp(
      masterMetricsRedirections.tiktok,
      app,
      '/app/data-sources'
    ),
  }
}

export const getRedirectionsUriForDomains = (path = '/app/data-sources') => {
  if (window.location.origin === import.meta.env.VITE_FREE_ALERTS_DOMAIN) {
    return createDomainRedirections('freeAlerts', path)
  }
  if (window.location.origin === import.meta.env.VITE_FREE_DASHBOARDS_DOMAIN) {
    return createDomainRedirections('freeDashboards', path)
  }
  if (window.location.origin === import.meta.env.VITE_FREE_OVERVIEWS_DOMAIN) {
    return createDomainRedirections('freeOverviews', path)
  }
  if (window.location.origin === import.meta.env.VITE_ALERTS_LTV_DOMAIN) {
    return createDomainRedirections('alertsLTV', path)
  }
  return createDomainRedirections('app', path)
}

export const getIntercomAppIdByDomain = () => {
  if (window.location.origin === import.meta.env.VITE_MASTER_METRICS_DOMAIN) {
    return import.meta.env.VITE_APP_INTERCOM_APP
  }
  return import.meta.env.VITE_FREE_ALERTS_INTERCOM_APP
}

export const getAppByDomain = () => {
  if (
    window.location.origin === import.meta.env.VITE_FREE_ALERTS_DOMAIN ||
    window.location.origin === import.meta.env.VITE_ALERTS_LTV_DOMAIN
  ) {
    return 'Alerts'
  }
  if (window.location.origin === import.meta.env.VITE_FREE_DASHBOARDS_DOMAIN) {
    return 'Dashboards'
  }
  if (window.location.origin === import.meta.env.VITE_FREE_OVERVIEWS_DOMAIN) {
    return 'Overview'
  }
  return ''
}
