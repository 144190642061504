import styled from 'styled-components'
import { Card, CardBody, CardFooter, CardTitle } from 'reactstrap'

export const BaseCardWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
  border: initial;
  background: white;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  top: 0;
  padding: 1.25rem;
  overflow: auto;
  border: 1px solid #d3d3d3;
`

export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`
export const StyledCardBody = styled(CardBody)`
  padding: 1.75rem;
  overflow: inherit;
  position: static;
  webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  display: flex !important;
  padding: 1.25rem;
  width: 100%;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`

export const StyledCardFooter = styled(CardFooter)`
  width: 100%;
  padding: 0px 1.25rem;
  background: white;
  border: none;

  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`

export const StyledCardTitle = styled(CardTitle)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
`

export const TitleContainer = styled.div`
  display: flex;
  .table-title {
    margin-right: 20px;
  }
`

export const StyledTableTitle = styled('h2')`
  color: #1b215c;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  margin-right: 20px;
`

export const StyledTableViewApply = styled.span`
  position: relative;
  top: 4px;
  left: -10px;
  font-size: 14px;
  color: rgb(164 164 164);
`

export const DateLabel = styled.span`
  position: relative;
  top: 4px;
  font-size: 14px;
`
