import React from 'react'

const HeadingTwo = ({ active }) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Editor"
          transform="translate(-480.000000, -48.000000)"
          fillRule="nonzero"
        >
          <g id="Heading_2_fill" transform="translate(480.000000, 48.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fillRule="nonzero"
            ></path>
            <path
              d="M13,2.5 C13.7796706,2.5 14.4204457,3.09488554 14.4931332,3.85553954 L14.5,4 L14.5,20 C14.5,20.8284 13.8284,21.5 13,21.5 C12.2203294,21.5 11.5795543,20.9050879 11.5068668,20.1444558 L11.5,20 L11.5,13.5 L5.5,13.5 L5.5,20 C5.5,20.8284 4.82843,21.5 4,21.5 C3.22030118,21.5 2.579551,20.9050879 2.50686655,20.1444558 L2.5,20 L2.5,4 C2.5,3.17157 3.17157,2.5 4,2.5 C4.77969882,2.5 5.420449,3.09488554 5.49313345,3.85553954 L5.5,4 L5.5,10.5 L11.5,10.5 L11.5,4 C11.5,3.17157 12.1716,2.5 13,2.5 Z M17.6575,12.6792 C18.2901,12.521 18.9499,12.6085 19.5304,12.8987 C20.6957,13.4814 21.2873,14.8195 20.9732,16.0757 C20.8743714,16.4711857 20.6906857,16.8394878 20.4358711,17.1556055 L20.3026,17.3092 L18.7244,19.0001 L20.3542,19.0001 C20.9065,19.0001 21.3542,19.4478 21.3542,20.0001 C21.3542,20.51295 20.968173,20.9356092 20.4708239,20.9933725 L20.3542,21.0001 L16.6492,21.0001 C16.0918,21.0001 15.6399,20.5482 15.6399,19.9908 C15.6399,19.69785 15.6621031,19.4213609 15.830043,19.1827703 L15.9113,19.0829 L18.8405,15.9446 C19.313,15.4383 18.8904,14.4325 18.1425,14.6195 C17.8287222,14.6979889 17.6756556,14.9547296 17.6455145,15.2464465 L17.6399,15.3572 C17.6399,15.9095 17.1922,16.3572 16.6399,16.3572 C16.0876,16.3572 15.6399,15.9095 15.6399,15.3572 C15.6399,14.1109 16.4218,12.9881 17.6575,12.6792 Z"
              id="形状"
              fill={active ? '#1C274C' : '#a3a3a3'}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default HeadingTwo
