/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react'
import { FieldInputProps } from '../FieldEditor'
import { SelectWrapper } from '../SingleSelectInput/styles'
import SmartReportingSelect from '../../../../Forms/_control/SmartReportingSelect'
import useGetMetricsOptions from './MetricSelect.hooks'

const CLEAR_ALL_OPT = {
  key: '_clear_all_',
  value: '_clear_all_',
  label: 'Clear Value',
}

const MetricSelectInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  onKeyDown,
  value,
  row,
  tableMetrics,
  source,
}) => {
  const handleSelect = ({
    target,
  }: {
    target: { [key: string]: string | number }
  }) => {
    if (target.value === CLEAR_ALL_OPT.value) {
      onChange(null)
      return
    }
    const metric = _mergedoptions.find(
      (mp: { value: string | number }) =>
        mp.value.toString() === target.value.toString()
    )

    const metricSelected = JSON.stringify({
      metric: metric?.value,
      label: metric?.label,
      value: metric?.value,
    })

    onChange(metricSelected)
  }

  const handleOnBlur = async () => {
    await onChange(value)
    await onBlur()
  }
  const _mergedoptions: {
    label: string
    value: string | number
    key?: string | number | undefined
  }[] = useGetMetricsOptions({ source, tableMetrics, row })

  const valueMetric = value ? JSON.parse(value).metric : undefined

  return (
    <SelectWrapper>
      <SmartReportingSelect
        label={''}
        name={''}
        options={_mergedoptions}
        placeholder="Select one"
        className="react-select"
        classNamePrefix="react-select"
        value={valueMetric}
        onChange={handleSelect}
        onBlur={handleOnBlur}
        onKeyDown={onKeyDown}
        autoFocus
      />
    </SelectWrapper>
  )
}

export default MetricSelectInput
