import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const UPDATE_TABLE_KEY = 'Tables_UpdateTable'

const updateTable = async (payload: { [key: string]: any }) => {
  const { id, ...data } = payload
  if (id === 0) return
  return tablesApi.put(`/${id}`, data).then(response => response.data)
}

const useUpdateTable = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateTable, {
    mutationKey: [UPDATE_TABLE_KEY],
    ...queryOptions,
  })
}

export default useUpdateTable
