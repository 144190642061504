import styled from 'styled-components'
import { Card, CardBody, CardTitle } from 'reactstrap'

export const StyledCard = styled(Card)`
  height: auto;
  width: 100%;
  border-radius: 0.75rem;
  cursor: pointer;
  border: initial;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  border: 1px solid #d3d3d3;
  background-color: #4552e8;
`

export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 5px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 9999;
`

export const StyledCardBody = styled(CardBody)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
  justify-content: center;
`

export const StyledDots = styled('div')`
  width: 6px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
`

export const StyledCardText = styled('h3')`
  color: #4552e8;
  font-weight: 400;
  margin-bottom: 0 !important;
`

export const StyledLead = styled('p')`
  font-size: 14px;
  color: #1b215c;
  font-weight: 600;
  line-height: 1.2;
  height: 40px;
  text-align: center !important;
`
export const StyledSpan = styled('span')`
  display: block;
  font-weight: 400;
  font-size: 12px;
  padding-top: 3px;
`

export const StyledCardTitle = styled(CardTitle)`
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
  margin: 0px;
`

export const StyleTitle = styled('p')`
  color: #ffffff;
  margin: 0px;
  font-weight: 500;
  display: block;
  font-size: 2.2rem;
  line-height: 2.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const StyleTitleContainer = styled('div')`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  text-align: center;
`
