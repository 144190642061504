import { useMemo } from 'react'
import { RowData } from '../../../types'

const SOURCE_START_WITH = {
  meta: '(FB)',
  linkedin: '(Linkedin)',
  google: '(Google)',
} as { [key: string]: string }

const useGetMetricsOptions = ({
  row,
  tableMetrics,
  source,
}: {
  row?: RowData
  tableMetrics: {
    dataType: string
    id: string
    label: string
    source: string
    type: string
    value: string
  }[]
  source?: string
}) => {
  const _mergedoptions: {
    label: string
    value: string | number
    key?: string | number | undefined
  }[] = useMemo(() => {
    const startLabel = row
      ? (SOURCE_START_WITH[row._dataSource as string] ?? '')
      : ''
    return tableMetrics.reduce(
      (
        filteredMetrics: {
          key: string
          label: string
          value: string
          source: string
        }[],
        metric
      ) => {
        if (
          metric.dataType !== 'number' ||
          (metric.label.startsWith('(') &&
            !metric.label.startsWith(startLabel)) ||
          (source && metric.source !== source)
        )
          return filteredMetrics
        filteredMetrics.push({
          key: metric.id,
          label: metric.label,
          value: metric.value,
          source: metric.source,
        })
        return filteredMetrics
      },
      []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableMetrics])

  return _mergedoptions
}

export default useGetMetricsOptions
