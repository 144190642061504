import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const REMOVE_CLIENT_KEY = 'Tables_UpdateClient'

const removeClient = async ({
  tableId,
  id,
}: {
  tableId: string
  id: string
}) => {
  if (!tableId) throw new Error('Missing Table ID')
  return tablesApi
    .delete(`/${tableId}/clients/${id}`)
    .then(response => response.data)
}

const useRemoveClient = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(removeClient, {
    mutationKey: [REMOVE_CLIENT_KEY],
    ...queryOptions,
  })
}

export default useRemoveClient
