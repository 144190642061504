import React from 'react'
import { FieldInputProps } from '../FieldEditor'
import { StyledInput } from './styles'

const SingleTextInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  onKeyDown,
  value,
}) => {
  return (
    <StyledInput
      type="text"
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value)
      }
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      autoFocus
    />
  )
}

export default SingleTextInput
