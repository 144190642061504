import styled from 'styled-components'

export const Container = styled.div<{ $read: boolean }>`
  margin: 0.5rem auto;
  max-width: 72rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: ${props => (props.$read ? 'none' : '1px solid gray')};
`

export const EditorStyles = styled.div`
padding: 10px 5px;
font - size: 1.2rem;
line - height: 1.2rem;
height: 100 %;
`
