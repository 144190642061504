export type MultiSelectOption = {
  id: string | number
  label: string
  value: any
  dataType?: 'string' | 'number'
  [key: string]: any
}

export type Format = {
  id?: string
  adAccounts: []
  campaigns: []
  clients: []
  field: MultiSelectOption | {}
  name: string
  conditions?: Rule[]
  order?: number
}

export type Rule = {
  id: string
  operator: {}
  value: string
  color: string
  order?: number
}

export type Operators = {
  string: MultiSelectOption[]
  number: MultiSelectOption[]
}

export const defaultFormat: Format = {
  adAccounts: [],
  campaigns: [],
  field: {},
  name: '',
  conditions: [],
}

export const FILTERS_OPERATORS: Operators = {
  string: [
    { id: 1, label: 'Contains', value: 'contains' },
    { id: 2, label: 'Does Not Contain', value: 'not_contains' },
    { id: 3, label: 'Contains Exactly', value: 'contains_exactly' },
    { id: 4, label: 'Does Not Contain Exactly', value: 'not_contains_exactly' },
  ],
  number: [
    { id: 1, label: '>', value: 'major' },
    { id: 2, label: '>=', value: 'major_equals' },
    { id: 3, label: '<', value: 'minor' },
    { id: 4, label: '<=', value: 'minor_equals' },
    { id: 5, label: 'Is Exactly', value: 'is_exactly' },
    { id: 6, label: 'Is Not Exactly', value: 'is_not_exactly' },
  ],
}

export const FILTERS_CALLBACKS: {
  [operator: string]: (value: string, ref: string) => boolean
} = {
  contains: (value: string, ref: string) =>
    value.toLowerCase().includes(ref.toLowerCase()),
  not_contains: (value: string, ref: string) =>
    !value.toLowerCase().includes(ref.toLowerCase()),
  contains_exactly: (value: string, ref: string) => value === ref,
  not_contains_exactly: (value: string, ref: string) => !(value === ref),
  major: (value: string, ref: string) => Number(value) > Number(ref),
  major_equals: (value: string, ref: string) => Number(value) >= Number(ref),
  minor: (value: string, ref: string) => Number(value) < Number(ref),
  minor_equals: (value: string, ref: string) => Number(value) <= Number(ref),
  is_exactly: (value: string, ref: string) => Number(value) === Number(ref),
  is_not_exactly: (value: string, ref: string) =>
    !(Number(value) === Number(ref)),
}
