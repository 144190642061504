import React from 'react'
import FacebookLogo from '../../assets/icons/datasources/facebook.svg'
import GoogleLogo from '../../assets/icons/datasources/google.svg'
import LinkedinLogo from '../../assets/icons/datasources/linkedin.svg'
import InstagramLogo from '../../assets/icons/datasources/instagram.svg'
import Ga4Logo from '../../assets/icons/datasources/ga4.svg'
import HubspotLogo from '../../assets/icons/datasources/hubspot.svg'
import CloseLogo from '../../assets/icons/datasources/close.svg'
import TiktokLogo from '../../assets/icons/datasources/tiktok.svg'
import QueryMetricsIcon from '../../assets/icons/master-metrics.svg'
import { WidgetIconContainer, WidgeticonStyle } from './Style'

const WidgetIcon = ({ sources }) => {
  const sourceLogo = source => {
    switch (source) {
      case 'META':
      case 'META_SOCIAL':
        return FacebookLogo
      case 'GOOGLE':
        return GoogleLogo
      case 'LINKEDIN':
        return LinkedinLogo
      case 'INSTAGRAM_SOCIAL':
        return InstagramLogo
      case 'GOOGLE_ANALYTICS':
      case 'GA4':
        return Ga4Logo
      case 'HUBSPOT':
        return HubspotLogo
      case 'CLOSE':
        return CloseLogo
      case 'TIKTOK':
        return TiktokLogo
      case 'MASTER-METRICS':
        return QueryMetricsIcon
      default:
        return ''
    }
  }

  return (
    <WidgetIconContainer>
      {sources.length > 0 &&
        sources.map(source => (
          <WidgeticonStyle
            src={sourceLogo(source)}
            alt={`${source}-logo`}
            key={source}
          />
        ))}
    </WidgetIconContainer>
  )
}

export default WidgetIcon
