import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const CREATE_VIEW_KEY = 'Tables_CreateView'

const createView = async (payload: { tableId: string }) => {
  const { tableId, ...data } = payload
  if (!tableId) throw new Error('Missing Table ID')
  return tablesApi
    .post(`/${tableId}/views`, data)
    .then(response => response.data)
}

const useCreateView = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(createView, {
    mutationKey: [CREATE_VIEW_KEY],
    ...queryOptions,
  })
}

export default useCreateView
