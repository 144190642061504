import React, { useEffect, useMemo, useState } from 'react'
import { TableContextProvider, TablesProvider } from '../SmartTable/context'
import { DateChosen } from '../Forms/WidgetForms/widget'
import { BaseTable } from './BaseTable'
import useGetOneDashboardOverview from '@mm/api/src/dashboardOverviews/requests/useGetOneDashboardOverview'
import useGetTableViewById from '@mm/api/src/tables/requests/useGetTableViewById'
import { TableReportingColumn } from '../SmartTable/types'
import { BASE_FILTER, EMPTY_FILTER } from '../SmartTable/context/provider'

export interface TableContainerProps {
  id: string
  show_icons?: boolean
  dateChosen: DateChosen
  onDelete: () => void
  onDuplicate: () => void
  shareable?: string
  pdf?: string
}

export function TableContainer(props: TableContainerProps) {
  const {
    data: currentTable,
    isFetching: isFetchingTable,
    refetch,
  } = useGetOneDashboardOverview(props.id, props.shareable, props.pdf)

  const {
    isFetching: isFetchedView,
    data: view,
    refetch: refetchView,
  } = useGetTableViewById(
    currentTable?.id,
    currentTable?.additional_config?.overviewViewId,
    props.shareable,
    props.pdf
  )

  useEffect(() => {
    refetch()
    if (currentTable?.additional_config?.overviewViewId) {
      refetchView()
    }
  }, [props.id])

  const activeColumns = useMemo(() => {
    if (!view) {
      return null
    }

    return view.activeColumns.reduce(
      (acc: TableReportingColumn[], column: string) => {
        const columnData = currentTable?.columns.find(
          (col: TableReportingColumn) => col.data.value === column
        )
        if (columnData) {
          acc.push(columnData)
        }

        return acc
      },
      [] as TableReportingColumn[]
    )
  }, [view, currentTable])

  const { dateChosen } = props

  const date = useMemo(() => {
    return {
      name: dateChosen?.dateFilterRange || currentTable?.date?.name,
      since: dateChosen?.dateFilterFrom || currentTable?.date?.since,
      until: dateChosen?.dateFilterTo || currentTable?.date?.until,
      lastDays: dateChosen?.dateFilterLastDays || currentTable?.date?.lastDays,
      untilToday:
        dateChosen?.dateFilterUntilToday || currentTable?.date?.untilToday,
    }
  }, [currentTable, dateChosen])

  if (!currentTable) {
    return null
  }
  return (
    <TableContextProvider type={currentTable.type} from={'dashboard'}>
      <TablesProvider>
        <BaseTable
          {...props}
          id={currentTable?.id}
          widgetId={props.id}
          type={currentTable.group_by}
          filters={
            view?.filters ||
            (currentTable.group_by === 'campaign' ? BASE_FILTER : EMPTY_FILTER)
          }
          currentTable={{
            ...currentTable,
            date,
          }}
          isFetchingData={isFetchingTable || isFetchedView}
          columnsView={activeColumns}
          view={view}
        />
      </TablesProvider>
    </TableContextProvider>
  )
}
