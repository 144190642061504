// @ts-nocheck
import { apiInstance } from '@mm/api'

export const publicLinkApiInstance = apiInstance('', true, true)

export async function fetchDashboardData(id) {
  const dashboardData = {
    tiles: [],
    charts: [],
    tables: [],
    powertables: [],
    powercharts: [],
    powertiles: [],
    simpletables: [],
    texts: [],
    titles: [],
    error: false,
  }

  await publicLinkApiInstance
    .get(`/dashboard/shared/data/${id}`)
    .then(({ data }) => {
      const responseData = data
      dashboardData.title = responseData.dashboard.name
      dashboardData.allowFilter = responseData.shareable.allowFilter
      dashboardData.dateFilters = responseData.shareable.dateFilters
      dashboardData.layout = responseData.dashboard.layout
      dashboardData.logoUrl = responseData.shareable.image.url
      dashboardData.widgets = []
      if (responseData.data.length) {
        for (const widget of responseData.data) {
          dashboardData.widgets.push(widget)
        }
      }
      dashboardData.layout.forEach(widget => {
        if (
          ![
            'powertable',
            'powertile',
            'simpletable',
            'powerchart',
            'multiaxischart',
            'overview',
          ].includes(widget.type)
        )
          return
        dashboardData.widgets.push({
          id: widget.i,
          type: widget.type,
          stringDate: widget.stringDate,
          edited: widget.edited,
        })
      })
    })
    .catch(e => {
      console.log(e.code, e.response, e.message, e.data)
      if (e.message !== 'user_left') {
        console.log(e)
        dashboardData.error = true
      }
    })
  return dashboardData
}

export async function uptadeShareable(id, dateFilters) {
  await publicLinkApiInstance.post(`shareables/${id}?shareable=${id}`, {
    dateFilters,
  })
}
