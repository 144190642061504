import React, { useState } from 'react'
import { StyledInput, InputContainer } from './styles'
import { ColData } from '../../../types'

export type CheckboxInputProps = {
  value: boolean
  rowId: string
  source?: string
  column: ColData
  onChange?: (updateFieldProps: {
    value: boolean
    rowId: string
    column: ColData
    onError: () => void
  }) => void
  allowEdit: boolean
}

export const CheckboxInput: React.ComponentType<CheckboxInputProps> = ({
  rowId,
  column,
  value,
  onChange,
  allowEdit,
}) => {
  const [checked, setChecked] = useState(value)

  return (
    <InputContainer>
      <StyledInput
        type="checkbox"
        disabled={!allowEdit}
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newChecked = e.target.checked
          setChecked(newChecked)
          onChange &&
            onChange({
              column,
              rowId,
              value: newChecked,
              onError: () => {
                setChecked(!newChecked)
              },
            })
        }}
      />
    </InputContainer>
  )
}
