import React, { ComponentType, useState, useMemo } from 'react'
import {
  CellWrapper,
  Checkmark,
  Content,
  EditButton,
  EditorContainer,
  Emptybox,
  Link,
} from './styles'
import SingleTextInput from './CustomFileds/SingleTextInput'
import NumberInput from './CustomFileds/NumberInput'
import LongTextInput from './CustomFileds/LongTextInput'
import DateInput from './CustomFileds/DateInput'
import editIcon from '../../../assets/icons/edit.svg'
import FieldEditor, { FieldInputProps } from './CustomFileds/FieldEditor'
import SingleSelectInput from './CustomFileds/SingleSelectInput'
import MultiSelectInput from './CustomFileds/MultiSelectInput'
import SourceResultInput from './CustomFileds/SourceResultInput'
import MetricSelectInput from './CustomFileds/MetricSelectInput/MetricSelectInput'
import { CheckboxInput } from './CustomFileds/CheckboxInput'
import {
  formatCRMValue,
  formatDateMDY,
  parseCustomOptionMultiple,
  parseCustomOptionValue,
  parseCustomOptions,
} from './utils'
import { getCampaignObjetiveMetricData } from '../../Tables/utils'
import { formatData, getValueRowData } from '../Utils'
import { ColData, RowData } from '../types'
import { ComparedValueMarker } from '../ComparedValueMarker/ComparedValueMarker'

const INPUTS: { [type: string]: ComponentType<FieldInputProps> } = {
  single_text: SingleTextInput,
  long_text: LongTextInput,
  date: DateInput,
  url: SingleTextInput,
  number: NumberInput,
  single_select: SingleSelectInput,
  multiple_select: MultiSelectInput,
  // checkbox: CheckboxInput,
  result_formula: MetricSelectInput,
  source_result: SourceResultInput,
}

const resultTypes = ['result_formula', 'source_result']

const renderContent = (
  type: string,
  value: string | null,
  column: ColData,
  row: RowData,
  columns: Array<ColData>,
  isFixedColumn: boolean
) => {
  if (type === 'url' && value) {
    let href = ''
    href = value.startsWith('https://') ? value : `https://${value}`
    return (
      <Link href={href} target="_blank">
        {value || '-'}
      </Link>
    )
  }
  if (type === 'single_select' && value)
    return <Content>{parseCustomOptionValue(value)}</Content>
  if (type === 'crm' && value) {
    const formattedValue = formatCRMValue(value, column)
    return <Content>{formattedValue}</Content>
  }
  if (type === 'multiple_select' && value)
    return <Content>{parseCustomOptionMultiple(value)}</Content>
  if (type === 'checkbox' && value)
    return JSON.parse(value) ? <Checkmark /> : <Emptybox />
  if (type === 'date' && value) {
    return <Content>{formatDateMDY(value) || '-'}</Content>
  }
  if (column && type === 'number') {
    return <Content>{formatNumber(value, column) || '-'}</Content>
  }
  if (column && resultTypes.includes(type)) {
    const resultData = getCampaignObjetiveMetricData(row, column)
    if (!columns) return <Content>-</Content>
    const refColumn = columns.find(c => c.data.value === resultData.metric)
    if (!row) return '-'

    if (!refColumn)
      return (
        <Content>
          {resultData.label} {type === 'result_formula' ? 'not found' : ''}
        </Content>
      )
    let metricName = resultData.metric
    if (resultData.filter) {
      metricName = `${resultData.filter.id}_${resultData.metric}`
    }
    const cellData =
      typeof row[metricName] === 'string'
        ? row[metricName]
        : typeof row[metricName] === 'object'
          ? (row[metricName] as unknown as { current: string })?.current
          : null

    return (
      <Content style={{ lineHeight: isFixedColumn ? '23px' : '24px' }}>
        {formatData(
          cellData as string,
          refColumn,
          refColumn.data.decimals,
          refColumn.data.numberType
        ) || '-'}
      </Content>
    )
  }
  return <Content>{value || '-'}</Content>
}

function formatNumber(value: string | null, column: ColData) {
  if (!value || value === '-') return '-'

  if (isNaN(Number(value))) return `$${value}`

  const decimals = column?.data?.decimals ?? 2
  if (column.data.numberType === 'percentage')
    return Number(value).toFixed(decimals) + '%'

  if (column.data.numberType === 'currency')
    return Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

function getValueOrDefault(
  type: string,
  editMode: boolean,
  row: RowData,
  column: ColData,
  value: string | null,
  defaultValue = '-'
) {
  try {
    if (resultTypes.includes(type)) {
      if (value) {
        const dataType = typeof JSON.parse(value)
        if (dataType === 'number' && !editMode) return value
        if (dataType === 'object' && editMode) return value
      }
      const resultData = getCampaignObjetiveMetricData(row, column)
      return JSON.stringify(resultData)
    }

    return value || '-'
  } catch (err) {
    return value || '-'
  }
}

const CustomCell: React.FC<{
  type: string
  initialValue: string | null
  updateField: Function
  column: ColData
  options?: Array<string>
  row: RowData
  columns: Array<ColData>
  // ! TODO: Quitar table como dependencia
  table: any
  tableMetrics: {
    dataType: string
    id: string
    label: string
    source: string
    type: string
    value: string
  }[]
  rowIndex?: number
  isFixedColumn?: boolean
  updateCheckboxValue?: (updateFieldProps: {
    value: boolean
    rowId: string
    column: ColData
    onError: () => void
  }) => void
  allowEdit: boolean
}> = ({
  type,
  initialValue,
  updateField,
  options,
  column,
  row,
  table,
  tableMetrics,
  rowIndex,
  isFixedColumn,
  updateCheckboxValue,
  allowEdit,
}) => {
  const [editMode, setEditMode] = useState(false)
  const [value, setValue] = useState(initialValue)

  const metricResultData = useMemo(() => {
    if (type === 'result_formula') {
      const resultData = getCampaignObjetiveMetricData(row, column)
      return resultData
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, value])

  const formattedTitle = useMemo(() => {
    if (type === 'multiple_select') return parseCustomOptionMultiple()
    if (type === 'result_formula' && metricResultData) {
      return metricResultData.label
    }

    return value || '-'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, value, metricResultData])

  const valueMetric = useMemo(() => {
    return getValueOrDefault(type, editMode, row, column, value)
  }, [type, value, editMode])

  const openEditor = (): void => {
    setEditMode(true)
  }

  const handleChange = async (_value: string | null, updateValue?: boolean) => {
    setValue(_value)
    if (updateValue) {
      updateField(_value || null, true)
    }
  }

  const handleBlur = async () => {
    if (valueMetric === initialValue || column.data?.filterEnabled) {
      setEditMode(false)
      return
    }
    if (resultTypes.includes(type)) {
      const isNumber = typeof JSON.parse(valueMetric) === 'number'
      if (!isNumber) {
        if (valueMetric === JSON.stringify(metricResultData)) {
          setEditMode(false)
          return
        }
        updateField(valueMetric || null)
      }
    } else {
      updateField(valueMetric || null)
    }
    setEditMode(false)
  }

  const compareEnabled =
    resultTypes.includes(column.type) || column.type === 'crm'

  let comparedMetric = resultTypes.includes(column.type)
    ? getCampaignObjetiveMetricData(row, column)
    : undefined

  if (column.type === 'crm') {
    comparedMetric = { metric: column.data.value }
  }
  if (comparedMetric) {
    if (comparedMetric?.filter) {
      comparedMetric = `${comparedMetric.filter.id}_${comparedMetric.metric}`
    } else {
      comparedMetric = comparedMetric.metric
    }
  }
  const comparedValue = compareEnabled
    ? getValueRowData(row, comparedMetric, false) || 0
    : undefined

  if (type === 'checkbox') {
    const rowId = getValueRowData(row, 'id')
    const storedValue = (column?.data?.stored as any)[rowId]
    const isChecked =
      storedValue === undefined
        ? column?.data?.default === 'true'
        : storedValue === 'true'

    return (
      <CheckboxInput
        onChange={updateCheckboxValue}
        rowId={rowId}
        source={column.data.source}
        column={column}
        value={isChecked}
        allowEdit={allowEdit}
      ></CheckboxInput>
    )
  }
  return (
    <>
      <CellWrapper title={formattedTitle}>
        {editMode ? (
          <EditorContainer>
            <FieldEditor
              inputComponent={INPUTS[type]}
              onBlur={handleBlur}
              onChange={handleChange}
              value={valueMetric || ''}
              options={parseCustomOptions(options)}
              row={row}
              table={table}
              tableMetrics={tableMetrics}
              source={column.data.source}
              filterEnabled={column.data.filterEnabled}
            />
          </EditorContainer>
        ) : (
          <>
            {renderContent(
              type,
              valueMetric,
              column,
              row,
              table.columns as unknown as Array<ColData>,
              !!isFixedColumn
            )}
            {allowEdit && INPUTS[type] && (
              <EditButton onClick={openEditor}>
                <img alt="" src={editIcon} className={`buttons-img`} />
              </EditButton>
            )}
          </>
        )}
      </CellWrapper>
      {!editMode && column.data.compare?.enabled ? (
        <ComparedValueMarker
          style={{ marginRight: 0 }}
          key={`compared-${row.id}-${column.id}`}
          metric={comparedMetric}
          comparedValue={Number(comparedValue)}
          current={Number(valueMetric)}
          rowIndex={rowIndex}
          startDate={new Date(table.date.since)}
          endDate={new Date(table.date.until)}
          column={column}
        ></ComparedValueMarker>
      ) : null}
    </>
  )
}

export default CustomCell
