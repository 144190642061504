import React, { useState, useEffect } from 'react'
import {
  StyledCard,
  StyledCardBody,
  StyledViewOptions,
  StyledDots,
  StyledCardTitle,
} from './Style'
import TextEditor from '../Slate/TextEditor'
import { Descendant } from 'slate'
import Loader from '../Loader'
import Tooltip from '../Highcharts/Tooltip'

const TextContainer = ({
  id,
  isLoading,
  text,
  onDuplicate,
  onEdit,
  onDelete,
  edited,
}) => {
  const [counter, setCounter] = useState(0)
  const [value, setValue] = useState<Descendant[]>([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ])

  const [showTooltip, setShowTooltip] = useState(false)
  const [hideActions] = useState(import.meta.env.VITE_APP_IS_PUBLIC === 'false')

  const handleToggleTooltip = () => {
    setShowTooltip(prev => !prev)
  }

  useEffect(() => {
    setCounter(prev => prev + 1)
  }, [text])

  return (
    <StyledCard id={`text-${id}`} className="scrollbar-color">
      <StyledCardBody className="scrollbar-color">
        {!text ? (
          <div style={{ margin: '54px' }}>
            <Loader />
          </div>
        ) : (
          <>
            <StyledCardTitle>
              {hideActions && (
                <>
                  <StyledViewOptions
                    onClick={handleToggleTooltip}
                    className="view-options"
                    onMouseDown={e => e.stopPropagation()}
                    onMouseMove={e => e.stopPropagation()}
                  >
                    <StyledDots className="dots"></StyledDots>
                  </StyledViewOptions>

                  <Tooltip
                    show={showTooltip}
                    toggle={handleToggleTooltip}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    loading={isLoading}
                  />
                </>
              )}
            </StyledCardTitle>
            <TextEditor
              value={text}
              setValue={setValue}
              read={true}
              edited={counter}
            />
          </>
        )}
      </StyledCardBody>
    </StyledCard>
  )
}

export default TextContainer
