import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const UPDATE_VIEW_KEY = 'Tables_UpdateView'

const updateView = async (payload: { [key: string]: any }) => {
  const { tableId, id, ...data } = payload
  return tablesApi
    .put(`/${tableId}/views/${id}`, data)
    .then(response => response.data)
}

const useUpdateView = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateView, {
    mutationKey: [UPDATE_VIEW_KEY],
    ...queryOptions,
  })
}

export default useUpdateView
