import React from 'react'
import { RemoveBtn } from './styles'

interface Props {
  handleRemove: () => void
  disabled: boolean
}

const RemoveButton = ({ handleRemove, disabled }: Props) => {
  return <RemoveBtn onClick={handleRemove} disabled={disabled} />
}

export default RemoveButton
