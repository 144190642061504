import React, { useCallback, useMemo, useState } from 'react'
import {
  ComparedArrowCell,
  ComparedCell,
  ComparedCellNoData,
  ComparedTextCell,
} from '../Styles'
import { CSSProperties } from 'styled-components'
import { getFactor } from './mapCompareMetricFactor'
import { Tooltip } from 'reactstrap'
import { calculateDate } from './calculateDate'
import { format } from 'date-fns'
import { DATE_FNS_FORMAT } from '../../SingleDatePicker/SingleDatePicker'
import { ColData } from '../types'
import { formatData } from '../Utils'
interface ComparedValueMarkerProps {
  metric?: string
  rowIndex?: number
  current?: number
  comparedValue?: number
  style?: CSSProperties
  compare?: {
    enabled: boolean
    period: 'Customize' | 'Previous period'
    from?: string | undefined
    to?: string | undefined
    compareFactor?: number
  }
  startDate: Date
  endDate: Date
  column: ColData
}

export function calculateDiff(current?: number, comparedValue?: number) {
  if (!current || !comparedValue || isNaN(current) || isNaN(comparedValue))
    return undefined

  const percentage = ((current - comparedValue) / comparedValue) * 100

  return Number(percentage.toFixed(2))
}

export const ComparedValueMarker = ({
  metric,
  current,
  comparedValue,
  style,
  compare,
  rowIndex,
  startDate,
  endDate,
  column,
}: ComparedValueMarkerProps) => {
  const [toggle, setToggle] = useState(false)
  const tooltipRefId = useMemo(() => {
    if (!metric) return ''
    return `Tooltip-${metric.replace(
      /[^a-zA-Z0-9]/g,
      ''
    )}-compared-${rowIndex}`.replace(/\s+/g, '')
  }, [metric, rowIndex])

  const tooltipDomElement = document.getElementById(tooltipRefId)
    ? tooltipRefId
    : undefined

  const lastPeriod = useMemo(() => {
    if (!metric) return {}

    return calculateDate(
      compare?.period || 'Previous period',
      compare?.from ? new Date(compare?.from) : startDate,
      compare?.to ? new Date(compare?.to) : endDate
    )
  }, [compare?.from, compare?.period, compare?.to, metric]) as {
    from: Date
    to: Date
  }

  const factor = useMemo(() => {
    return getFactor(metric, compare?.compareFactor)
  }, [metric])

  const diffPercentaje = (calculateDiff(current, comparedValue) ?? 0) * factor
  const formatCompareValue = useMemo(() => {
    return formatData(
      (comparedValue ?? '-').toString(),
      column,
      column.data.decimals,
      column.data.numberType
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedValue])
  const toggleText = useMemo(() => {
    try {
      if (!metric) return ''
      return `${diffPercentaje} % (${formatCompareValue}) from ${format(
        lastPeriod.from,
        DATE_FNS_FORMAT
      )} to ${format(lastPeriod.to, DATE_FNS_FORMAT)}`
    } catch (e) {
      return ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric, lastPeriod.from, lastPeriod.to, diffPercentaje])

  const onToggle = useCallback(() => {
    setToggle(!toggle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metric, rowIndex, toggle])

  if (!metric) return null

  if (!factor) {
    return null
  }

  const increaseNumber =
    current && comparedValue && Number(current) > Number(comparedValue)
      ? true
      : false

  const arrowDir = increaseNumber ? ` ↑ ` : ` ↓ `
  const increaseDiffPercentaje = diffPercentaje > 0 ? true : false

  if (diffPercentaje !== 0) {
    return (
      <ComparedCell style={style} onClick={onToggle} id={tooltipRefId}>
        <ComparedArrowCell increase={increaseDiffPercentaje}>
          {arrowDir}
        </ComparedArrowCell>
        <ComparedTextCell increase={increaseDiffPercentaje}>{`${Math.abs(
          diffPercentaje
        )} %`}</ComparedTextCell>
        {tooltipDomElement ? (
          <Tooltip
            placement={'right'}
            isOpen={toggle}
            target={tooltipRefId}
            toggle={onToggle}
          >
            {toggleText}
          </Tooltip>
        ) : null}
      </ComparedCell>
    )
  }

  return (
    <ComparedCellNoData
      title={`No data`}
      style={{ ...style, textAlign: 'center', width: 30 }}
    >
      <span> {`(-)`}</span>
    </ComparedCellNoData>
  )
}
