import React from 'react'
import { RowContainer } from '../style'
import { Data } from './Data'
import { PowertableStructure, rawValues } from '../types'

export class Row implements PowertableStructure {
  public rowData: Data[]
  public rowHeaders: Data[]
  constructor(
    data: rawValues,
    parentNames: string[],
    formatMetricRows: string[]
  ) {
    this.rowData = []
    this.rowHeaders = []
    this.populateRow(data, parentNames, formatMetricRows)
  }

  private populateRow(
    data: rawValues,
    parentNames: string[],
    formatMetricRows: string[]
  ) {
    for (let i = 0; i < parentNames.length; i++) {
      const name = parentNames[i]
      this.rowHeaders.push(
        new Data({
          value: name,
          isHeader: true,
        })
      )
    }
    data.values.flat().forEach((value: string | number, idx: number) => {
      this.rowData.push(
        new Data({ value, isHeader: false, format: formatMetricRows[idx] })
      )
    })
  }

  render(idx: number) {
    return <RowContainer>{this.rowData[idx]?.render() || ''}</RowContainer>
  }
}
