// @ts-nocheck

import moment from 'moment'
// eslint-disable-next-line import/no-extraneous-dependencies
import formatNumber from 'format-number'

import { activityMetricsFormats } from '../constants/activity-metrics-formats'

import { defaultDirection, statusChange } from '../constants/defaultValues'
import { META_COST_PER_CONVERTIONS } from '../constants/meta'
import { getDecimals } from '@mm/ui/src/components/Powertable/utils'

export const countDataSources = user => {
  let count = 0
  if (import.meta.env.VITE_APP_CLOSE_ENABLED === 'true' && user.closeApiKey)
    count += 1
  if (
    import.meta.env.VITE_APP_HUBSPOT_ENABLED === 'true' &&
    user.hubspotPortalId
  )
    count += 1
  if (import.meta.env.VITE_APP_META_ENABLED === 'true' && user.metaUserId)
    count += 1
  if (import.meta.env.VITE_APP_GOOGLE_ENABLED === 'true' && user.googleUserId)
    count += 1
  if (
    import.meta.env.VITE_APP_LINKEDIN_ENABLED === 'true' &&
    user.linkedinUserId
  )
    count += 1
  if (
    import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ENABLED === 'true' &&
    user.googleAnalyticsUserId
  )
    count += 1
  if (
    import.meta.env.VITE_APP_META_SOCIAL_ENABLED === 'true' &&
    user.metaSocialUserId
  )
    count += 1

  if (
    import.meta.env.VITE_APP_INSTAGRAM_SOCIAL_ENABLED === 'true' &&
    user.instagramSocialUserId
  )
    count += 1

  if (import.meta.env.VITE_APP_TIKTOK_ENABLED === 'true' && user.tiktokUserId)
    count += 1

  return count
}

export const countAdAccounts = user => {
  let count = 0
  if (import.meta.env.VITE_APP_META_ENABLED === 'true' && user.metaAdAccounts)
    count += user.metaAdAccounts.length
  if (
    import.meta.env.VITE_APP_GOOGLE_ENABLED === 'true' &&
    user.googleAdAccounts
  )
    count += user.googleAdAccounts.length
  if (
    import.meta.env.VITE_APP_LINKEDIN_ENABLED === 'true' &&
    user.linkedinAdAccounts
  )
    count += user.linkedinAdAccounts.length
  if (
    import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ENABLED === 'true' &&
    user.googleAnalyticsProperties
  )
    count += user.googleAnalyticsProperties.length
  if (
    import.meta.env.VITE_APP_META_SOCIAL_ENABLED === 'true' &&
    user.metaSocialPages
  )
    count += user.metaSocialPages.length
  if (
    import.meta.env.VITE_APP_INSTAGRAM_SOCIAL_ENABLED === 'true' &&
    user.instagramSocialProfiles
  )
    count += user.instagramSocialProfiles.length

  return count
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 //January is 0!

  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const getDirection = () => {
  let direction = defaultDirection
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction')
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  }
}

export const setDirection = localValue => {
  let direction = 'ltr'
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue
  }
  localStorage.setItem('direction', direction)
}

export const isStatusChange = variableToCheck =>
  Object.values(statusChange).some(
    statusChangeValue => variableToCheck === statusChangeValue
  )

export function capitalize(str) {
  if (typeof str !== 'string') throw new Error(`Invalid type "${typeof str}".`)

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function capitalizeString(string) {
  if (string) {
    const words = string.replace('_', ' ').split(' ')
    return words
      .map(word => {
        if (word.trim() === '') {
          return ''
        }

        return word[0].toUpperCase() + word.substring(1)
      })
      .join(' ')
  } else return ''
}

export function encodeTextToUrl(text) {
  return encodeURIComponent(
    text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  )
}

export function parseAMQuery(query) {
  const {
    users,
    metric,
    smartViewId,
    date,
    choices,
    contact,
    valueFields,
    textarea,
    text,
    number,
    customFields,
  } = JSON.parse(query || '{}')

  const user = users && users[0]

  return {
    activityMetric: metric,
    user,
    smartview: smartViewId,
    date,
    choices,
    contact,
    valueFields,
    textarea,
    text,
    number,
    customFields,
  }
}

export function formatValue(value, metric) {
  const { activityMetric: metricKey } = parseAMQuery(metric.query || '{}')
  const format = activityMetricsFormats[metricKey]

  switch (format) {
    case 'duration':
      return formatDuration(value)

    case 'currency':
      return `$${(+value).toLocaleString()}`

    default:
      return (+value).toLocaleString()
  }
}

export function formatCalculatedMetric(value, metric) {
  const {
    numberType,
    stats_metrics: { decimals },
    float,
  } = metric
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? float ?? 2,
    padRight: decimals ?? float ?? 2,
  })
  const defaultDecimals = getDecimals(undefined, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  if (numberType === 'percentage') {
    return percentageFormat(value)
  }
  if (numberType === 'currency') {
    return currencyFormat(value)
  }
  return commonFormat(value)
}

export function formatDuration(value, unit = 'seconds') {
  const duration = moment.duration(value, unit)

  const hours = duration.asHours()
  const showHours = hours >= 1
  const formattedHours = showHours && `${Math.floor(hours)}h `

  const minutes = duration.minutes()
  const showMinutes = minutes > 0
  const formattedMinutes = showMinutes && `${minutes}m `

  const seconds = duration.seconds()
  const showSeconds =
    !(showHours && !showMinutes) && !(showMinutes && showHours && seconds < 1)
  const formattedSeconds = ` ${seconds}s`

  return (
    `${showHours ? formattedHours : ''}` +
      `${showMinutes ? formattedMinutes : ''}` +
      `${showSeconds ? formattedSeconds : ''}`.replace(' 0s', '') || '0s'
  )
}

export function isHubspotDuration(metric) {
  return (
    metric.source === 'HUBSPOT' &&
    ['sum_calls', 'avg_calls', 'sum_meetings', 'avg_meetings'].includes(
      metric.type
    )
  )
}

const roundValue = (value: string | number) => {
  return Math.ceil(+value)
}

export function formatMetaValue(value, metric) {
  const {
    type,
    stats_metrics: { decimals },
    float,
  } = metric
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? float ?? 2,
    padRight: decimals ?? float ?? 2,
  })
  const defaultDecimals = getDecimals(type, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  if (META_COST_PER_CONVERTIONS.includes(type))
    return currencyFormat(value, decimals)
  switch (type) {
    case 'ctr':
    case 'inline_link_click_ctr':
    case 'outbound_clicks_ctr':
    case 'unique_link_clicks_ctr':
    case 'unique_outbound_clicks_ctr':
    case 'unique_ctr':
    case 'linkedin_ctr_outbound_click':
    case 'conversions_from_interactions_rate':
      return percentageFormat(value)
    case 'spend':
    case 'conversion_offsite_conversion.fb_pixel_purchase_value':
    case 'conversion_omni_purchase_value':
      return currencyFormat(value)
    case 'cpm':
    case 'cpc':
    case 'cpc_link_clicks':
    case 'cpc_outbound_click':
    case 'cost_per_unique_outbound_click':
    case 'cost_per_unique_click':
    case 'cost_per_unique_inline_link_click':
    case 'linkedin_cpc_outbound_click':
    case 'cpp':
      return currencyFormat(value)
    case 'video_avg_time_watched_actions': {
      return FormatSecondsToTime(value)
    }
    default:
      return commonFormat(value)
  }
}

export function formatGoogleValue(value, metric) {
  /**
   * In google the type should be a string containing the main metric and the resouced it belongs to.
   */

  const [, type] = metric.type.split('-')
  const {
    stats_metrics: { decimals },
    float,
  } = metric
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? float ?? 2,
    padRight: decimals ?? float ?? 2,
  })
  const defaultDecimals = getDecimals(type, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  switch (type) {
    case 'ctr':
    case 'conversions_from_interactions_rate':
      return percentageFormat(value)
    case 'average_cpc':
    case 'average_cpm':
    case 'google_purchase_roas':
      return currencyFormat(value)
    case 'cost':
    case 'cost_per_conversion':
    case 'conversions_value':
      return currencyFormat(value)
    default:
      return commonFormat(value)
  }
}

let googleAnalyticsCostFormatter
export function getGoogleAnalyticsCostFormatter(toCurrency = 'USD') {
  if (!googleAnalyticsCostFormatter) {
    googleAnalyticsCostFormatter = new Intl.NumberFormat(
      navigator.language || 'en-US',
      {
        style: 'currency',
        currency: toCurrency,
      }
    )
  }

  return googleAnalyticsCostFormatter
}

export function formatGoogleAnalyticsValue(value, metric) {
  let { type } = metric
  const {
    stats_metrics: { decimals },
    float,
  } = metric

  if (type.includes(':')) {
    ;[type] = type.split(':') // Custom metrics prefixes
  }
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? float ?? 2,
    padRight: decimals ?? float ?? 2,
  })
  const defaultDecimals = getDecimals(type, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })

  switch (type) {
    case 'adUnitExposure':
      return formatDuration(value, 'milliseconds')

    case 'averageSessionDuration':
    case 'userEngagementDuration':
      return formatDuration(value)

    case 'averagePurchaseRevenue':
    case 'averagePurchaseRevenuePerPayingUser':
    case 'averagePurchaseRevenuePerUser':
    case 'averageRevenuePerUser':
    case 'itemRevenue':
    case 'purchaseRevenue':
    case 'returnOnAdSpend':
    case 'shippingAmount':
    case 'taxAmount':
    case 'totalAdRevenue':
    case 'totalRevenue':
    case 'advertiserAdCost':
    case 'advertiserAdCostPerClick':
    case 'advertiserAdCostPerConversion':
    case 'grossItemRevenue':
    case 'grossPurchaseRevenue':
      return currencyFormat(value)

    case 'bounceRate':
    case 'cartToViewRate':
    case 'crashFreeUsersRate':
    case 'dauPerMau':
    case 'dauPerWau':
    case 'engagementRate':
    case 'firstTimePurchaserConversionRate':
    case 'itemListClickThroughRate':
    case 'itemPromotionClickThroughRate':
    case 'organicGoogleSearchClickThroughRate':
    case 'purchaseToViewRate':
    case 'purchaserConversionRate':
    case 'sessionConversionRate':
    case 'userConversionRate':
    case 'userKeyEventRate':
    case 'wauPerMau':
      return percentageFormat(value * 100)

    default:
      return commonFormat(value)
  }
}

export function formatLinkedinValue(value, metric) {
  const {
    type,
    stats_metrics: { decimals },
    float,
  } = metric
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? float ?? 2,
    padRight: decimals ?? float ?? 2,
  })
  const defaultDecimals = getDecimals(type, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  switch (type) {
    case 'linkedin_ctr':
    case 'linkedin_conversion_rate':
      return percentageFormat(value)
    case 'costInUsd':
    case 'costInLocalCurrency':
      return currencyFormat(value)
    case 'linkedin_cpc':
    case 'linkedin_average_cpm':
    case 'conversionValueInLocalCurrency':
    case 'linkedin_cost_per_conversion':
    case 'linkedin_return_over_ad_spent':
      return currencyFormat(value)
    default:
      return commonFormat(value)
  }
}

export function formatTiktokValue(value, metric) {
  const {
    type,
    stats_metrics: { decimals },
    float,
  } = metric
  const percentageFormat = formatNumber({
    suffix: '%',
    round: decimals ?? 2,
    padRight: decimals ?? 2,
  })
  const defaultDecimals = getDecimals(type, decimals)
  const currencyFormat = formatNumber({
    prefix: '$',
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const commonFormat = formatNumber({
    round: defaultDecimals ?? float ?? 0,
    padRight: defaultDecimals ?? float ?? 0,
  })
  const isRate = type.includes('_rate')
  const isCost =
    type.includes('_cost') ||
    type.includes('cost_') ||
    type.includes('_spend') ||
    type.includes('spend_')
  if (isRate) {
    return percentageFormat(+value)
  }
  if (isCost) {
    return currencyFormat(+value)
  }
  switch (type) {
    case 'ctr':
      return percentageFormat(+value)
    case 'spend':
      return currencyFormat(+value)
    case 'cpm':
    case 'cpc':
      return currencyFormat(+value)
    default:
      return commonFormat(+value)
  }
}

export const auth = () => {
  const user = JSON.parse(localStorage.getItem('user'))
  const pdfAuthorization = localStorage.getItem('pdf-authorization')
  if (pdfAuthorization) {
    return { headers: { 'pdf-authorization': pdfAuthorization } }
  }
  return user && user.token ? { headers: { Authorization: user.token } } : {}
}
