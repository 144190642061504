import Highcharts from 'highcharts'
import React, { useEffect, useState } from 'react'
import Chart from '../Chart'
import { ErrorText } from './style'

interface MultiProps {
  id: string
  response: any
  error: any[]
}

const MultiChart = ({ id, response, error }: MultiProps) => {
  const [options, setOptions] = useState<Highcharts.Options | {}>({})

  const formatYAxis = rows => {
    const yAxis = [] as any[]

    let counter = 0

    for (const row of rows) {
      if (!row) continue
      const newAxis = {
        title: {
          text: row[0],
          style: {
            color: Highcharts?.getOptions().colors[counter],
          },
        },
        opposite: yAxis.length % 2 === 0 ? false : true,
      }
      counter = counter + 1
      yAxis.push(newAxis)
    }

    return yAxis
  }

  const parseData = () => {
    if (!response) return {}
    const optionsData: Highcharts.Options = {
      legend: {
        itemDistance: 5,
        margin: 7,
        itemWidth: 200,
        maxHeight: 70,
      },
      title: {
        text: '',
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'normal',
        zoomType: 'xy',
      },
      series: [
        //data Rows
      ],
      xAxis: {
        //Headers
        categories: response.headings.slice(1),
        crosshair: true,
      },
      yAxis: formatYAxis(response.rows),
      tooltip: {
        shared: true,
      },
      plotOptions: {},
    }

    let counter = 0

    for (const d of response.rows) {
      if (!d) continue
      const newData = {
        name: d[0],
        type: response.types[counter],
        data: d.slice(1, d.length).map(obj => Number(obj.value)),
        yAxis: counter,
      }

      counter = counter + 1
      //@ts-ignore
      optionsData.series?.push(newData)
    }

    setOptions(optionsData)
  }

  useEffect(() => {
    parseData()
  }, [response])

  return (
    <>
      {response?.errors?.length ? (
        response.errors.map((error: String, i: number) => {
          return <ErrorText key={i}>{error}</ErrorText>
        })
      ) : (
        <Chart options={options} error={error} chartId={id} />
      )}
    </>
  )
}

export default MultiChart
