// @ts-nocheck
import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import PlugIcon from '../../assets/icons/plug-icon.svg'
import WireIcon from '../../assets/icons/wire-icon.svg'
import ErrorIcon from '../../assets/icons/error-icon.svg'
import WarningIcon from '../../assets/icons/warning-icon.svg'

class NotFound extends Component {
  render() {
    const { isPublic = false } = this.props
    return (
      <Row className="view-not-found-container">
        <Row className="align-items-center">
          <Col className="left-not-found" span="12">
            <div className="text-not-found">
              <h1>404</h1>
              <h3>Page not found</h3>
              <p>
                I'm sorry, but it looks like the page you are trying to access
                cannot be found.
              </p>
              {!isPublic && (
                <Button
                  color="#2CC1D1"
                  className="btn-go-back"
                  onClick={() => this.props.history.push('/')}
                >
                  Go Home
                </Button>
              )}
            </div>
          </Col>
          <Col className="right-not-found" span="12">
            <img className="wire-icon" alt="" src={WireIcon} />
            <img className="error-icon" alt="" src={ErrorIcon} />
            <img className="warning-icon" alt="" src={WarningIcon} />
            <img className="plug-icon" alt="" src={PlugIcon} />
          </Col>
        </Row>
      </Row>
    )
  }
}
export default NotFound
