import { isAfter, isBefore, isSameDay } from 'date-fns'

export const FILTERS_OPERATORS = {
  string: [
    { label: 'Contains', value: 'contains' },
    { label: 'Does Not Contain', value: 'not_contains' },
    { label: 'Contains Exactly', value: 'contains_exactly' },
    { label: 'Does Not Contain Exactly', value: 'not_contains_exactly' },
    { label: 'Is Any Of', value: 'is_any_of' }, // []
    { label: 'Is None Of', value: 'is_none_of' },
    { label: 'Is Null', value: 'is_null' },
    { label: 'Is Not Null', value: 'is_not_null' },
  ],
  number: [
    { label: '>', value: 'major' },
    { label: '>=', value: 'major_equals' },
    { label: '<', value: 'minor' },
    { label: '<=', value: 'minor_equals' },
    { label: 'Is Exactly', value: 'is_exactly' },
    { label: 'Is Not Exactly', value: 'is_not_exactly' },
    { label: 'Is Null', value: 'is_null' },
    { label: 'Is Not Null', value: 'is_not_null' },
  ],
  date: [
    { label: 'Is Same As', value: 'is_same_date' },
    { label: 'Is Before', value: 'is_before_date' },
    { label: 'Is After', value: 'is_after_date' },
    { label: 'Is Null', value: 'is_null' },
    { label: 'Is Not Null', value: 'is_not_null' },
  ],
  enumeration: [
    { label: 'Is Any Of', value: 'is_any_of' }, // []
    { label: 'Is None Of', value: 'is_none_of' }, // []
    { label: 'Is Null', value: 'is_null' },
    { label: 'Is Not Null', value: 'is_not_null' },
  ],
  boolean: [
    { label: 'Is Checked', value: 'is_checked' }, // []
    { label: 'Is Not Checked', value: 'is_not_checked' }, //[]
  ],
}

export const FILTERS_CALLBACKS: {
  [operator: string]: (value: string | null, ref: string) => boolean
} = {
  contains: (value: string | null, ref: string) => {
    if (!value) return false
    return value.toLowerCase().includes(ref.toLowerCase())
  },
  not_contains: (value: string | null, ref: string) => {
    if (!value) return true
    return !value.toLowerCase().includes(ref.toLowerCase())
  },
  contains_exactly: (value: string | null, ref: string) => {
    if (!value) return false
    return value.toLowerCase() === ref.toLowerCase()
  },
  not_contains_exactly: (value: string | null, ref: string) => {
    if (!value) return true
    return !(value.toLowerCase() === ref.toLowerCase())
  },
  major: (value: string | null, ref: string) => {
    if (!value) return false
    return Number(value) > Number(ref)
  },
  major_equals: (value: string | null, ref: string) => {
    if (!value) return false
    return Number(value) >= Number(ref)
  },
  minor: (value: string | null, ref: string) => {
    if (!value) return false
    return Number(value) < Number(ref)
  },
  minor_equals: (value: string | null, ref: string) => {
    if (!value) return false
    return Number(value) <= Number(ref)
  },
  is_exactly: (value: string | null, ref: string) => {
    if (!value) return false
    return Number(value) === Number(ref)
  },
  is_not_exactly: (value: string | null, ref: string) => {
    if (!value) return true
    return !(Number(value) === Number(ref))
  },
  is_null: (value: string | null, __: string) => {
    return !value
  },
  is_not_null: (value: string | null, __: string) => {
    return !!value
  },
  is_same_date: (value: string | null, ref: string) => {
    if (!value) return false
    return isSameDay(new Date(value), new Date(ref))
  },
  is_before_date: (value: string | null, ref: string) => {
    if (!value) return false
    return isBefore(new Date(value), new Date(ref))
  },
  is_after_date: (value: string | null, ref: string) => {
    if (!value) return false
    return isAfter(new Date(value), new Date(ref))
  },
  is_checked: (value: string | null, __: string) => {
    if (!value) return false
    return value === 'true'
  },
  is_not_checked: (value: string | null, __: string) => {
    if (!value) return true
    return value !== 'true'
  },
  is_any_of: (value: string | null, ref: string) => {
    if (!value) return false
    if (!ref) return false
    const _splitedValue = value.split(',').filter(val => val)
    const _splitedRef = ref.split(',').filter(val => val)
    if (_splitedValue.length <= 0 || _splitedRef.length <= 0) return false
    return _splitedRef.some(val => _splitedValue.includes(val))
  },
  is_none_of: (value: string | null, ref: string) => {
    if (!value) return false
    if (!ref) return false
    const _splitedValue = value.split(',').filter(val => val)
    const _splitedRef = ref.split(',').filter(val => val)
    if (_splitedValue.length <= 0 || _splitedRef.length <= 0) return false
    return !_splitedRef.some(val => _splitedValue.includes(val))
  },
}
