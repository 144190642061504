import styled from 'styled-components'

export const LastDaysSelectContainer = styled.div`
  min-width: 282px;
  border: none !important;
  border-left: 1px solid #dedfeb !important;
  border-radius: 0px !important;
  padding: 20px 12px !important;
`

export const LastDaysRangeDateText = styled.div`
  color: #a6a6a6;
  font-size: 14px;
  padding: 4px 4px 0px 4px;
`

export const ErrorMesagge = styled.div`
  margin: 8px 5px 0px 5px;
  color: #ff0000;
  size: 12px;
  font-weight: 400;
`
