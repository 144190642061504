import { useQuery } from '@tanstack/react-query'
import powertileApi from '../index'

export const GET_POWERTILE_DATA_KEY = 'Powertiles_GetPowertileData'

interface DataChosen {
  dateFilterFrom?: any
  dateFilterRange: string
  dateFilterTo?: any
  dateFilterLastDays: number
  dateFilterUntilToday: boolean
}

export const getPowerTileData = async (
  id: string,
  shareable?: string,
  dateChosen?: DataChosen,
  pdf?: string
) => {
  const params = new URLSearchParams()

  if (shareable) params.append('shareable', shareable)
  if (pdf) params.append('pdf', pdf)

  if (dateChosen && dateChosen.dateFilterRange) {
    params.append('dateFilterFrom', dateChosen.dateFilterFrom)
    params.append('dateFilterRange', dateChosen.dateFilterRange)
    params.append('dateFilterTo', dateChosen.dateFilterTo)
    params.append(
      'dateFilterLastDays',
      (dateChosen?.dateFilterLastDays ?? '').toString()
    )
    params.append(
      'dateFilterUntilToday',
      dateChosen?.dateFilterUntilToday ? 'true' : 'false'
    )
  }

  return powertileApi
    .get(`/${id}/data?${params}`)
    .then(response => response.data)
}

const useGetPowertileData = ({
  id,
  shareable,
  dateChosen,
  pdf,
}: {
  id: string
  shareable?: string
  dateChosen?: DataChosen
  pdf?: string
}) =>
  useQuery(
    [GET_POWERTILE_DATA_KEY, id, JSON.stringify(dateChosen)],
    () => getPowerTileData(id, shareable, dateChosen, pdf),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

export default useGetPowertileData
