// @ts-nocheck

import React from 'react'
import { Transition } from 'react-transition-group'

import DeleteIcon from '../../assets/icons/delete-icon.svg'
import DuplicateIcon from '../../assets/icons/duplicate-icon.svg'
import PauseIcon from '../../assets/icons/pause-icon.svg'
import EditIcon from '../../assets/icons/pen.svg'
import PlayIcon from '../../assets/icons/play-icon.svg'

const defaultStyle = {
  transition: 'opacity 150ms ease-in-out',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const items = [
  {
    name: 'Delete',
    img: DeleteIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Edit',
    img: EditIcon,
    hideWhileLoading: true,
  },
  {
    name: 'Duplicate',
    img: DuplicateIcon,
  },
]

interface TooltipProps {
  show: boolean
  toggle: () => void
  onDelete?: () => void
  onEdit?: () => void
  onDuplicate?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
  loading: boolean
  hideButtons?: string[]
  onStatusChange?: () => void
  activeStatus?: boolean
}

class Tooltip extends React.Component<TooltipProps> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setRef = this.setRef.bind(this)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (this.ref && !this.ref.contains(e.target)) {
      this.props.toggle()
    }
  }

  setRef = node => {
    this.ref = node
  }

  handleClick = buttonName => {
    try {
      this.props[`on${buttonName}`]()
      if (!buttonName.includes('Move')) this.props.toggle()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const { show, loading, hideButtons, onStatusChange, activeStatus } =
      this.props
    const loadingStyles = { width: '150px' }

    const shownItems = items.filter(
      item => !(hideButtons || []).includes(item.name)
    )
    const menuItems = loading
      ? shownItems.filter(i => !i.hideWhileLoading)
      : [...shownItems]

    if (onStatusChange) {
      menuItems.push({
        name: 'StatusChange',
        img: activeStatus ? PauseIcon : PlayIcon,
      })
    }
    return (
      <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              ...loadingStyles,
            }}
            ref={this.setRef}
            className={'table-popup table-popup-action'}
            onMouseDown={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
          >
            <ul style={loadingStyles}>
              {menuItems.map(item => (
                <li key={item.name}>
                  <button
                    onClick={() => {
                      this.handleClick(item.name)
                    }}
                    className={`button-container ${
                      item.name === 'Delete' ? 'hide-on-mobile' : ''
                    }`}
                  >
                    <img
                      src={item.img}
                      className={`${item.name}-button preload-animation`}
                      alt=""
                    />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Transition>
    )
  }
}

export default Tooltip
