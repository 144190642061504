import { getDatesArray } from '@mm/api/src/ranges'
import { differenceInDays, parse, addDays, set, parseISO } from 'date-fns'
import {
  formatDateMDY,
  isDateISO,
  isDateMDY,
  isDateYMD,
} from '@mm/ui/src/components/SmartTable/CustomCell/utils'
import {
  DATE_FNS_FORMAT,
  DATE_FNS_FORMAT_YMD,
} from '@mm/ui/src/components/SingleDatePicker/SingleDatePicker'
import {
  ColData,
  ColumnTypes,
  DateTypeOptions,
  NumberTypeOptions,
  RowData,
  TableDate,
  TableReportingFilters,
} from '@mm/ui/src/components/SmartTable/types'
import {
  getStoredColumnValue,
  getValueRowData,
} from '@mm/ui/src/components/SmartTable/Utils'
import { compact } from 'lodash'
import { FILTERS_CALLBACKS } from '../SmartTable/filters.constants'
import {
  getDefaultDateValues,
  getFieldDate,
} from '@mm/ui/src/components/SmartTable/ComparedValueMarker/calculateDate'

const MISSING_FORMULA_ERROR_MESSAGE = 'Missing formula'
const MALFORMED_FORMULA_ERROR_MESSAGE = 'Error in formula'
export const validDateFields: { [key: string]: boolean } = {
  endDate: true,
  startDate: true,
}

export const MIN_WIDTH_COLUMNS = 120

const parseData = (dataString: string) => {
  try {
    return JSON.parse(dataString)
  } catch (err) {
    return dataString
  }
}

export const getCampaignObjetiveMetricData = (row: RowData, col: ColData) => {
  if (!row)
    return {
      label: 'No row data.',
      metric: '',
    }
  const id = getValueRowData(row, 'id')
  const storedMetric = getStoredColumnValue(col, id)
  if (storedMetric) {
    return parseData(storedMetric)
  }
  if (col.type === 'source_result')
    return {
      label: 'No metric selected',
      metric: '',
    }

  const _dataSource = getValueRowData(row, '_dataSource')
  if (_dataSource === 'google') {
    return {
      label: '(Google) Conversions',
      metric: 'google_conversions',
    }
  }
  if (_dataSource === 'tiktok') {
    return CampaignObjetiveMetricsMap.tiktok.RESULT
  }
  const campaignObjetive = getValueRowData(row, 'campaign_objective')
  if (!campaignObjetive || !_dataSource) {
    return {
      label: 'The metric for this objective was not defined. (3)',
      metric: '',
    }
  }
  const CampaignObjetiveFormulasBySource =
    CampaignObjetiveMetricsMap[_dataSource]
  if (!CampaignObjetiveFormulasBySource)
    return {
      label: 'The metric for this objective was not defined. (1)',
      metric: '',
    }

  const defaultStoredData = CampaignObjetiveFormulasBySource[campaignObjetive]
  if (!defaultStoredData)
    return {
      label: 'The metric for this objective was not defined. (2)',
      metric: '',
    }

  return defaultStoredData
}

export const getDefaultValue = (data: {
  dateType: string
  default?: string
}) => {
  const defaultDateValue = getDefaultDateValues(data.dateType)
  if (defaultDateValue) return defaultDateValue

  return data.default || '0'
}

export const getCalculatedValue = ({
  formula,
  columns,
  row,
  numberType = 'default',
  currentValue = true,
  dateChosen,
}: {
  formula: string
  columns: Array<ColData>
  row: RowData
  decimals?: number
  numberType?: NumberTypeOptions
  currentValue?: boolean
  dateChosen?: any
}) => {
  if (!formula) {
    return MISSING_FORMULA_ERROR_MESSAGE
  }
  let fixedFormula = formula
  const formulaType = {
    hasNumber: hasNumbers(formula, columns),
    hasDate: false,
    numberType,
  }
  const labelsInFormula = formula.match(new RegExp(/\[([^[\]]+)\]/g)) || []

  for (const label of labelsInFormula) {
    const column = getColumnFromLabelOrId(columns, label)
    if (!column) return MALFORMED_FORMULA_ERROR_MESSAGE
    const { data } = column
    const { stored, type, dateType } = data
    let storedData = stored
    if (type === 'date' && dateType !== 'manual') {
      storedData = {}
    }
    const defaultValue = getDefaultValue(data)
    const rowId = getValueRowData(row, 'id', currentValue)
    if (!rowId) return '-'
    const storedValue = storedData ? storedData[rowId] : null
    const metricValue = getMetricValue(
      label,
      columns,
      row,
      storedValue,
      currentValue,
      dateChosen
    )
    let filedValue

    if (
      metricValue === '0' &&
      storedValue !== '0' &&
      defaultValue !== undefined
    ) {
      filedValue = defaultValue
      if (column.type === ColumnTypes.DATE) {
        const date = getFieldDate(
          dateType as DateTypeOptions,
          dateChosen,
          defaultValue
        )
        filedValue = getDateInDays(date!)
      }
    } else {
      filedValue = metricValue
    }
    const isDate = isColumnDate(column)
    if (column.data.type === 'number') formulaType.hasNumber = true
    if (isDate && ['default', 'date'].includes(formulaType.numberType))
      formulaType.hasDate = true
    if (isNaN(Number(filedValue))) return '-'
    fixedFormula = fixedFormula.replaceAll(label, filedValue as string)
  }
  const regex =
    /(?:(?:^|[-+_*/])\s*|\(\s*)(-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*(?:[-+*/]\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*)*(?:\)\s*|$))+$/
  const validator = new RegExp(regex)
  const isValid = validator.test(fixedFormula)
  if (!isValid) return MALFORMED_FORMULA_ERROR_MESSAGE
  try {
    // eslint-disable-next-line no-eval
    let result = eval(fixedFormula)
    if (isNaN(result)) result = 0
    if (!Number.isFinite(result)) result = 0
    if (numberType === 'percentage') result = result * 100
    if (formulaType.hasNumber && formulaType.hasDate)
      return daysToDateYMD(Number(result))
    const parsedValue = result.toString()
    return parsedValue
  } catch (e) {
    return MALFORMED_FORMULA_ERROR_MESSAGE
  }
}

export function getColumnFromLabelOrId(columns: ColData[], labelOrId: string) {
  return columns.find(
    col =>
      col.label.toLowerCase().includes(labelOrId.toLowerCase()) ||
      labelOrId.includes(col.id.toString())
  )
}

function getColumnValue(rowValue: string, storedValue: string | null) {
  if (rowValue && rowValue !== '-') {
    return rowValue
  }
  if (storedValue && storedValue !== '-') {
    return storedValue
  }
  return '0'
}

function getMetricValue(
  labelFormula: string,
  columns: ColData[],
  row: RowData,
  storedValue: string | null,
  currentValue: boolean,
  dateChosen: any
): string | null {
  const regex = /\[(.*?)\]/
  const match = labelFormula.match(regex)

  // Retorna null si no se encuentra ningún texto entre corchetes o si el arreglo de coincidencias no tiene la posición 1.
  if (!match || match.length < 2) {
    return '0'
  }

  const label = match[1]
  const column = getColumnFromLabelOrId(columns, label)

  // Retorna null si no se encuentra ninguna columna con el label correspondiente.
  if (!column) {
    return '0'
  }

  if (column?.type === 'result_formula' || column?.type === 'source_result') {
    const resultData = getCampaignObjetiveMetricData(row, column)
    return getValueRowData(row, resultData.metric, currentValue) || '0'
  }

  if (column.data.dateType === 'isToday') {
    return differenceInDays(new Date(), new Date(0)).toString()
  }
  if (column.data.type === 'date' && column.data.dateType !== 'manual') {
    const date = getFieldDate(column.data.dateType, dateChosen) as string
    return getDateInDays(date)
  }

  const requireDiv100 =
    (column.data.value.toLowerCase().includes('ctr') &&
      !column.data.value.toLowerCase().includes('electrnico')) ||
    (column.data.numberType === 'percentage' &&
      column.type === ColumnTypes.NUMBER)

  const field = column.data.value
  const rowValue = getValueRowData(
    row,
    field,
    currentValue,
    column.data.dateType
  )
  let value = getColumnValue(rowValue, storedValue)
  if (column.data.formula) {
    const newValue = getCalculatedValue({
      formula: column.data.formula,
      columns,
      row,
      currentValue,
      dateChosen,
    })

    value = newValue !== MALFORMED_FORMULA_ERROR_MESSAGE ? newValue : value
  }

  value = getDateInDays(value)

  return requireDiv100 ? (Number(value) / 100).toString() : (+value).toString()
}

export function isColumnDate(column: ColData) {
  return column.data.type === 'date' || validDateFields[column.data.value]
}

export function hasNumbers(formula: string, columns: ColData[]) {
  const regex = /(?:^|[^[])(\d+)(?![^\]]*])/g
  const matches = formula.match(regex)

  return (matches?.length ?? 0) > 0
}

// Pasa un numero de dias a una fecha teniendo en cuenta el inicio de los tiempos
function daysToDateYMD(days: number) {
  // Crea la fecha base a la que se agregarán los días
  const fechaBase = new Date(0)

  // Agrega los días utilizando addDays
  const nuevaFecha = addDays(fechaBase, days)

  // Obtén el offset horario local en minutos
  const offsetMinutes = fechaBase.getTimezoneOffset()

  // Ajusta la nueva fecha con el offset local
  const nuevaFechaConOffset = set(nuevaFecha, {
    minutes: nuevaFecha.getMinutes() + offsetMinutes,
  })

  return formatDateMDY(nuevaFechaConOffset)
}

function getDateInDays(value: string) {
  if (isDateMDY(value)) {
    const fecha = parse(value, DATE_FNS_FORMAT, new Date())
    return differenceInDaysFromInit(fecha)
  }
  if (isDateYMD(value)) {
    const fecha = parse(value, DATE_FNS_FORMAT_YMD, new Date())
    return differenceInDaysFromInit(fecha)
  }
  if (isDateISO(value)) {
    const fecha = parseISO(value)
    return differenceInDaysFromInit(fecha)
  }

  return value
}

function differenceInDaysFromInit(fecha: Date) {
  return differenceInDays(fecha, new Date(0)).toString()
}

export const sortColumn = (property: string, type = 'string') => {
  let sortOrder = 1
  if (property[0] === '-') {
    sortOrder = -1
    property = property.substr(1)
  }
  return function (
    a: { [property: string]: string },
    b: { [property: string]: string }
  ) {
    let result = -1
    if (type === 'number') {
      result =
        Number(a[property]) < Number(b[property])
          ? -1
          : Number(a[property]) > Number(b[property])
            ? 1
            : 0
    } else if (type === 'string') {
      result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
    }
    return result * sortOrder
  }
}

export const parseDate = (date: TableDate) =>
  date
    ? {
        dateFilterFrom: date.since,
        dateFilterTo: date.until,
        dateFilterRange: date.name,
        dateFilterLastDays: date.lastDays,
        dateFilterUntilToday: date.untilToday,
      }
    : undefined

export const getDateId = (date: TableDate) => {
  const _parsedDate = parseDate(date)
  return _parsedDate && _parsedDate.dateFilterRange
    ? getDatesArray()?.find(item => item.name === _parsedDate.dateFilterRange)
        ?.id
    : undefined
}

export const CampaignObjetiveMetricsMap: {
  [source: string]: { [key: string]: { label: string; metric: string } }
} = {
  google: {},
  tiktok: {
    RESULT: {
      label: '(Tiktok) Result',
      metric: 'result',
    },
  },
  linkedin: {
    LEAD_GENERATION: {
      label: '(Linkedin) One Click Leads',
      metric: 'oneClickLeads',
    },
    BRAND_AWARENESS: {
      label: 'Reach',
      metric: 'reach',
    },
    CREATIVE_ENGAGEMENT: {
      label: '(Linkedin) Viral Landing Page Clicks',
      metric: 'viralLandingPageClicks',
    },
    ENGAGEMENT: {
      label: '(Linkedin) Viral Landing Page Clicks',
      metric: 'viralLandingPageClicks',
    },
    WEBSITE_VISIT: {
      label: '(Linkedin) Viral Landing Page Clicks',
      metric: 'viralLandingPageClicks',
    },
    JOB_APPLICANT: {
      label: '(Linkedin) Job Apply Clicks',
      metric: 'jobApplyClicks',
    },
    TALENT_LEAD: {
      label: '(Linkedin) One Click Leads',
      metric: 'oneClickLeads',
    },
    VIDEO_VIEW: {
      label: 'Video Views',
      metric: 'video_views',
    },
    WEBSITE_CONVERSION: {
      label: '(Linkedin) Conversions',
      metric: 'externalWebsiteConversions',
    },
    WEBSITE_TRAFFIC: {
      label: 'Link Click',
      metric: 'link_click',
    },
  },
  meta: {
    CONVERSIONS: {
      label: '(FB) Conversions: Purchases',
      metric: 'conversion_offsite_conversion.fb_pixel_purchase',
    },
    OUTCOME_SALES: {
      label: '(FB) Conversions: Purchases',
      metric: 'conversion_offsite_conversion.fb_pixel_purchase',
    },
    PRODUCT_CATALOG_SALES: {
      label: '(FB) Conversions: Purchases',
      metric: 'conversion_offsite_conversion.fb_pixel_purchase',
    },
    LEAD_GENERATION: {
      label: '(FB) Conversions: All offsite leads plus all On-Facebook leads',
      metric: 'conversion_lead',
    },
    OUTCOME_LEADS: {
      label: '(FB) Conversions: All offsite leads plus all On-Facebook leads',
      metric: 'conversion_lead',
    },
    LINK_CLICKS: {
      label: 'Link Click',
      metric: 'link_click',
    },
    OUTCOME_TRAFFIC: {
      label: 'Link Click',
      metric: 'link_click',
    },
    STORE_VISITS: {
      label: 'Link Click',
      metric: 'link_click',
    },
    MESSAGES: {
      label: 'Link Click',
      metric: 'link_click',
    },
    REACH: {
      label: 'Reach',
      metric: 'reach',
    },
    OUTCOME_AWARENESS: {
      label: 'Reach',
      metric: 'reach',
    },
    LOCAL_AWARENESS: {
      label: 'Reach',
      metric: 'reach',
    },
    OFFER_CLAIMS: {
      label: 'Reach',
      metric: 'reach',
    },
    OUTCOME_APP_PROMOTION: {
      label: 'FB) Conversions: App Install',
      metric: 'conversion_app_install',
    },
    OUTCOME_ENGAGEMENT: {
      label: '(FB) Conversions: Post Engagement',
      metric: 'conversion_post_engagement',
    },
    POST_ENGAGEMENT: {
      label: '(FB) Conversions: Post Engagement',
      metric: 'conversion_post_engagement',
    },
    VIDEO_VIEWS: {
      label: '(FB) Video Plays',
      metric: 'video_views',
    },
    APP_INSTALLS: {
      label: '(FB) Conversions: App Install',
      metric: 'conversion_app_install',
    },
    EVENT_RESPONSES: {
      label: '(FB) Conversions: Post Engagement',
      metric: 'conversion_post_engagement',
    },
    PAGE_LIKES: {
      label: '(FB) Conversions: Page Likes',
      metric: 'conversion_like',
    },
  },
}

type Predicate = (value: string, ref: any) => boolean

type PredicateWithOperator = {
  predicate: Predicate
  ref: any
  operator: 'AND' | 'OR'
  colName: string
}

export function combinePredicatesWithOperators(predicatesWithOperators: {
  OR: PredicateWithOperator[]
  AND: PredicateWithOperator[]
}): (rowData: RowData) => boolean {
  return (rowData: RowData) => {
    const andValidation =
      predicatesWithOperators.AND.length > 0
        ? predicatesWithOperators.AND.reduce(
            (valid, { predicate, colName, ref }) => {
              const currentValue = getValueRowData(rowData, colName)
              return predicate(currentValue, ref) && valid
            },
            true
          )
        : true

    const orValidation =
      predicatesWithOperators.OR.length > 0
        ? predicatesWithOperators.OR.reduce(
            (valid, { predicate, colName, ref }) => {
              const currentValue = getValueRowData(rowData, colName)
              return predicate(currentValue, ref) || valid
            },
            false
          )
        : true

    return andValidation && orValidation
  }
}

export function createFilterPredicates(filtersData: TableReportingFilters) {
  const andPredicates = (filtersData?.AND ?? []).map(filter => {
    const ref = filter.value

    return {
      operator: 'AND',
      ref: ref,
      colName: filter.property,
      predicate: (value, ref) =>
        FILTERS_CALLBACKS[filter.filter ?? ''](value, ref),
    }
  }) as PredicateWithOperator[]

  const orPredicates = (filtersData.OR ?? []).map(filter => {
    const ref = filter.value

    return {
      operator: 'OR',
      ref: ref,
      colName: filter.property,
      predicate: (value, ref) =>
        FILTERS_CALLBACKS[filter.filter ?? ''](value, ref),
    }
  }) as PredicateWithOperator[]

  return { AND: compact(andPredicates), OR: orPredicates }
}
