import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

const SET_ACTIVE_COLUMNS_KEY = 'Tables_SetActiveColumns'

const setActiveColumns = async (payload: { [key: string]: any }) => {
  const { id, ...data } = payload
  return tablesApi
    .post(`/${id}/set-active-columns`, data)
    .then(response => response.data)
}

const useSetActiveColumns = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(setActiveColumns, {
    mutationKey: [SET_ACTIVE_COLUMNS_KEY],
    ...queryOptions,
  })
}

export default useSetActiveColumns
