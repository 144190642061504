import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import CarouselItem from '../CarouselItem'
import DashboardTable from '../DashboardTable/DashboardTable'
import Powertable from '../Powertable/Powertable'
import { WidgetLayout } from './types'
import CopyMoveMenu from './CopyMoveMenu'
import { Dashboard } from '../SmartTable/types'
import TextContainer from '../TextContainer/TextContainer'
import TitleContainer from '../TitleContainer/TitleContainer'
import SimpleTable from '../DashboardTable/SimpleTable'
import PowerChart from '../Highcharts/PowerChart'
import QueryChart from '../Highcharts/QueryChart'
import Multiaxischart from '../Highcharts/MultiAxisCharts'
import { TableContainer } from '../Tables/TableWrapper'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface Props {
  widgets: any[]
  layout: WidgetLayout[]
  resizableDash: boolean
  shareable?: string
  pdf: string
  compactType: string
  onDuplicateStat?: (id: string, dashboardId?: number) => void
  onDuplicatePowertable?: (widget: any, dashboardId?: number) => void
  setCurrentStat?: Dispatch<SetStateAction<any>>
  clearCurrentStat?: () => void
  setBottomRef?: () => void
  updateDashboard?: ({ layout }: { layout: WidgetLayout[] }) => void
  onDeleteStat?: (type: string, id: string) => void
  onCompareStat?: (type: string, id: string, compare: boolean) => void
  isLoading: boolean
  dateChosen: any
  editLayout: boolean
  dashboards?: Dashboard[]
  currentDashboardId?: number
  onChangeStat?: (type: string, id: string, delta: any) => void
}

const GridLayoutComponent = ({
  widgets,
  shareable,
  onDuplicateStat,
  onDuplicatePowertable,
  setCurrentStat,
  clearCurrentStat,
  onDeleteStat,
  onCompareStat,
  onChangeStat,
  layout,
  setBottomRef,
  updateDashboard,
  isLoading,
  dateChosen,
  editLayout,
  dashboards,
  currentDashboardId,
  pdf,
  compactType,
}: Props) => {
  const [widgetCount, setWidgetCount] = useState(0)
  const [resizable, setResizable] = useState(false)
  const [duplicateMenu, setDuplicateMenu] = useState<null | {
    duplicateId: string
    dashboardId: string
    targetId: string
    type: string
  }>(null)

  const addMinmaxToLayout = (layout: WidgetLayout[]) => {
    if (!layout) return []
    const sizes = {
      tile: { minW: 2, minH: 4, maxH: 20 },
      powertile: { minW: 2, minH: 4, maxH: 20 },
      table: { minW: 4, minH: 6, maxH: 20 },
      powertable: { minW: 6, minH: 8, maxH: 50 },
      text: { minW: 3, minH: 4, maxH: 20 },
      title: { minW: 3, minH: 2, maxH: 50 },
      chart: { minW: 4, minH: 7, maxH: 20 },
      multiaxischart: { minW: 4, minH: 7, maxH: 20 },
      powerchart: { minW: 4, minH: 8, maxH: 20 },
      simpletable: { minW: 6, minH: 8, maxH: 20 },
    }
    // @ts-ignore
    return layout.map((lay: WidgetLayout) => ({ ...lay, ...sizes[lay.type] }))
  }

  const saveNewLayout = async (newLayouts: WidgetLayout[]) => {
    const saveLayout: WidgetLayout[] = []
    if (!updateDashboard) return
    for (const lay of newLayouts) {
      const { i, w, h, x, y } = lay
      const newLayout = {
        i,
        w,
        h,
        x,
        y,
        type: layout.filter(lay => lay.i === i)[0].type,
      }
      saveLayout.push(newLayout)
    }
    await updateDashboard({ layout: saveLayout })
  }

  const layouts = {
    lg: addMinmaxToLayout(layout),
  }

  const getResizable = () => {
    isLoading ? setResizable(false) : setResizable(editLayout)
  }

  useEffect(() => {
    getResizable()
  }, [isLoading])

  useEffect(() => {
    setResizable(editLayout)
  }, [editLayout])

  const openDuplicateMoveMenu = (
    data: null | {
      duplicateId: string
      dashboardId: string
      targetId: string
      type: string
    }
  ) => {
    setDuplicateMenu(data)
  }

  const onDuplicateMove = (
    data: null | {
      duplicateId: string
      dashboardId: number
      targetId: string
      type: string
    }
  ) => {
    if (!data) return

    switch (duplicateMenu?.type) {
      case 'tile':
      case 'powertile':
      case 'powerchart':
      case 'chart':
      case 'title':
      case 'text':
      case 'table':
      case 'multiaxischart':
      case 'simpletable':
      case 'overview': {
        onDuplicateStat && onDuplicateStat(data.duplicateId, data.dashboardId)
        break
      }

      case 'powertable': {
        const powertable = widgets.find(
          widget => widget.id === data.duplicateId
        )
        powertable &&
          onDuplicatePowertable &&
          onDuplicatePowertable(powertable, data.dashboardId)
        break
      }
    }

    setDuplicateMenu(null)
  }

  const getNewlayout = async (updatedLayout: WidgetLayout[]) => {
    if (updatedLayout.length > 0 && updatedLayout.length < widgetCount) {
      await saveNewLayout(updatedLayout)
      setWidgetCount(updatedLayout.length)
    }
  }

  useEffect(() => {
    layout && setWidgetCount(layout.length)
  }, [layout])

  return (
    <>
      <div id="report-to-pdf">
        {duplicateMenu?.duplicateId ? (
          <CopyMoveMenu
            dashboards={dashboards!}
            dashboardIdDefault={currentDashboardId!}
            duplicateId={duplicateMenu?.duplicateId}
            targetId={duplicateMenu?.targetId ?? ''}
            type={duplicateMenu?.type ?? ''}
            onDuplicateMove={onDuplicateMove}
            onClose={() => openDuplicateMoveMenu(null)}
            open={true}
          />
        ) : null}
        {layout && (
          <ResponsiveGridLayout
            margin={[15, 15]}
            className="layout"
            layouts={layouts}
            cols={{ lg: 16, md: 12, sm: 8, xs: 4, xxs: 2 }}
            rowHeight={20}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            isResizable={resizable}
            isDraggable={resizable}
            compactType={compactType}
            style={{ overflow: 'hidden', marginBottom: '50px' }}
            onDragStop={itemCallback => saveNewLayout(itemCallback)}
            onResizeStop={itemCallback => saveNewLayout(itemCallback)}
            onLayoutChange={l => getNewlayout(l)}
          >
            {widgets.map(widget => (
              <div key={widget.id} style={{ display: 'flex' }}>
                {widget.type === 'tile' && (
                  <CarouselItem
                    id={widget.id}
                    {...widget}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `tile-${widget.id}`,
                          type: 'tile',
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat && clearCurrentStat()
                      onDeleteStat && onDeleteStat('tile', widget.id)
                    }}
                    error={widget.error || ''}
                    isLoading={isLoading}
                    type="tile"
                    widget={widget}
                    onChangeStat={deltaChange => {
                      clearCurrentStat && clearCurrentStat()
                      onChangeStat &&
                        onChangeStat('tile', widget.id, deltaChange)
                    }}
                  />
                )}
                {widget.type === 'powerchart' && (
                  <PowerChart
                    key={widget.id}
                    // @ts-ignore
                    {...widget}
                    chart={widget}
                    shareable={shareable}
                    pdf={pdf}
                    dateChosen={dateChosen}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={(id: string) => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                  />
                )}
                {widget.type === 'chart' && (
                  <QueryChart
                    key={widget.id}
                    chart={widget}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    dateChosen={dateChosen}
                    onDelete={(id: string) => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                  />
                )}
                {widget.type === 'table' && (
                  <DashboardTable
                    {...widget}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                    shareable={shareable}
                    pdf={pdf}
                    dateChosen={dateChosen}
                    widget={widget}
                  />
                )}
                {widget.type === 'simpletable' && (
                  <SimpleTable
                    {...widget}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                    shareable={shareable}
                    pdf={pdf}
                    dateChosen={dateChosen}
                  />
                )}
                {widget.type === 'powertable' && (
                  <Powertable
                    id={widget.id}
                    shareable={shareable}
                    pdf={pdf}
                    {...widget}
                    dateChosen={dateChosen}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat!()
                      onDeleteStat!('powertable', widget.id)
                    }}
                  />
                )}
                {widget.type === 'powertile' && (
                  <CarouselItem
                    id={widget.id}
                    widget={widget}
                    shareable={shareable}
                    pdf={pdf}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `powertile-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat && clearCurrentStat()
                      onDeleteStat && onDeleteStat('powertile', widget.id)
                    }}
                    onCompare={compare => {
                      clearCurrentStat && clearCurrentStat()
                      onCompareStat &&
                        onCompareStat('powertile', widget.id, compare)
                    }}
                    isLoading={isLoading}
                    type="powerTile"
                    dateChosen={dateChosen}
                  />
                )}
                {widget.type === 'text' && (
                  <TextContainer
                    id={widget.id}
                    isLoading={isLoading}
                    text={widget.text}
                    edited={widget.edited}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `text-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat && clearCurrentStat()
                      onDeleteStat && onDeleteStat('text', widget.id)
                    }}
                  />
                )}
                {widget.type === 'title' && (
                  <TitleContainer
                    id={widget.id}
                    isLoading={isLoading}
                    name={widget.name}
                    edited={widget.edited}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `title-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={() => {
                      clearCurrentStat && clearCurrentStat()
                      onDeleteStat && onDeleteStat('title', widget.id)
                    }}
                  />
                )}
                {widget.type === 'multiaxischart' && (
                  <Multiaxischart
                    key={widget.id}
                    // @ts-ignore
                    chart={widget}
                    dateChosen={dateChosen}
                    id={widget.id}
                    shareable={shareable}
                    pdf={pdf}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onEdit={() => setCurrentStat!(widget)}
                    onDelete={(id: string) => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                  />
                )}
                {widget.type === 'overview' && (
                  <TableContainer
                    pdf={pdf}
                    key={`overview-by-client-${widget.id}`}
                    id={widget.id}
                    dateChosen={dateChosen}
                    shareable={shareable}
                    onDuplicate={() => {
                      if (openDuplicateMoveMenu) {
                        openDuplicateMoveMenu({
                          duplicateId: widget.id,
                          dashboardId: 'dashboardId',
                          targetId: `${widget.type}-${widget.id}`,
                          type: widget.type,
                        })
                      }
                    }}
                    onDelete={() => {
                      clearCurrentStat!()
                      onDeleteStat!(widget.type, widget.id)
                    }}
                  />
                )}
              </div>
            ))}
          </ResponsiveGridLayout>
        )}
      </div>

      <div ref={setBottomRef} />
    </>
  )
}

export default GridLayoutComponent
