// @ts-nocheck

export const META_CONVERTIONS = [
  'conversion_app_custom_event.fb_mobile_achievement_unlocked',
  'conversion_app_custom_event.fb_mobile_activate_app',
  'conversion_app_custom_event.fb_mobile_add_payment_info',
  'conversion_app_custom_event.fb_mobile_add_to_cart',
  'conversion_app_custom_event.fb_mobile_add_to_wishlist',
  'conversion_app_custom_event.fb_mobile_complete_registration',
  'conversion_app_custom_event.fb_mobile_content_view',
  'conversion_app_custom_event.fb_mobile_initiated_checkout',
  'conversion_app_custom_event.fb_mobile_level_achieved',
  'conversion_app_custom_event.fb_mobile_purchase',
  'conversion_app_custom_event.fb_mobile_rate',
  'conversion_app_custom_event.fb_mobile_search',
  'conversion_app_custom_event.fb_mobile_spent_credits',
  'conversion_app_custom_event.fb_mobile_tutorial_completion',
  'conversion_app_custom_event.other',
  'conversion_app_install',
  'conversion_app_use',
  'conversion_checkin',
  'conversion_comment',
  'conversion_credit_spent',
  'conversion_games.plays',
  'conversion_landing_page_view',
  'conversion_like',
  'conversion_link_click',
  'conversion_mobile_app_install',
  'conversion_offsite_conversion.fb_pixel_add_payment_info',
  'conversion_offsite_conversion.fb_pixel_add_to_cart',
  'conversion_offsite_conversion.fb_pixel_add_to_wishlist',
  'conversion_offsite_conversion.fb_pixel_complete_registration',
  'conversion_offsite_conversion.fb_pixel_initiate_checkout',
  'conversion_offsite_conversion.fb_pixel_lead',
  'conversion_offsite_conversion.fb_pixel_purchase',
  'conversion_offsite_conversion.fb_pixel_search',
  'conversion_offsite_conversion.fb_pixel_view_content',
  'conversion_onsite_conversion.flow_complete',
  'conversion_onsite_conversion.messaging_block',
  'conversion_onsite_conversion.messaging_conversation_started_7d',
  'conversion_onsite_conversion.messaging_first_reply',
  'conversion_onsite_conversion.post_save',
  'conversion_onsite_conversion.purchase',
  'conversion_outbound_click',
  'conversion_photo_view',
  'conversion_post',
  'conversion_post_reaction',
  'conversion_rsvp',
  'conversion_video_view',
  'conversion_contact_total',
  'conversion_contact_website',
  'conversion_contact_mobile_app',
  'conversion_contact_offline',
  'conversion_customize_product_total',
  'conversion_customize_product_website',
  'conversion_customize_product_mobile_app',
  'conversion_customize_product_offline',
  'conversion_donate_total',
  'conversion_donate_website',
  'conversion_donate_on_facebook',
  'conversion_donate_mobile_app',
  'conversion_donate_offline',
  'conversion_find_location_total',
  'conversion_find_location_website',
  'conversion_find_location_mobile_app',
  'conversion_find_location_offline',
  'conversion_schedule_total',
  'conversion_schedule_website',
  'conversion_schedule_mobile_app',
  'conversion_schedule_offline',
  'conversion_start_trial_total',
  'conversion_start_trial_website',
  'conversion_start_trial_mobile_app',
  'conversion_start_trial_offline',
  'conversion_submit_application_total',
  'conversion_submit_application_website',
  'conversion_submit_application_mobile_app',
  'conversion_submit_application_offline',
  'conversion_submit_application_on_facebook',
  'conversion_subscribe_total',
  'conversion_subscribe_website',
  'conversion_subscribe_mobile_app',
  'conversion_subscribe_offline',
  'conversion_recurring_subscription_payment_total',
  'conversion_recurring_subscription_payment_website',
  'conversion_recurring_subscription_payment_mobile_app',
  'conversion_recurring_subscription_payment_offline',
  'conversion_cancel_subscription_total',
  'conversion_cancel_subscription_website',
  'conversion_cancel_subscription_mobile_app',
  'conversion_cancel_subscription_offline',
  'conversion_ad_click_mobile_app',
  'conversion_ad_impression_mobile_app',
  'conversion_click_to_call_call_confirm',
  'conversion_page_engagement',
  'conversion_post_engagement',
  'conversion_onsite_conversion.lead_grouped',
  'conversion_lead',
  'conversion_leadgen_grouped',
  'conversion_omni_app_install',
  'conversion_omni_purchase',
  'conversion_omni_add_to_cart',
  'conversion_omni_complete_registration',
  'conversion_omni_view_content',
  'conversion_omni_search',
  'conversion_omni_initiated_checkout',
  'conversion_omni_achievement_unlocked',
  'conversion_omni_activate_app',
  'conversion_omni_level_achieved',
  'conversion_omni_rate',
  'conversion_omni_spend_credits',
  'conversion_omni_tutorial_completion',
  'conversion_omni_custom',
]

export const META_COST_PER_CONVERTIONS = [
  'conversion_cost_app_custom_event.fb_mobile_achievement_unlocked',
  'conversion_cost_app_custom_event.fb_mobile_activate_app',
  'conversion_cost_app_custom_event.fb_mobile_add_payment_info',
  'conversion_cost_app_custom_event.fb_mobile_add_to_cart',
  'conversion_cost_app_custom_event.fb_mobile_add_to_wishlist',
  'conversion_cost_app_custom_event.fb_mobile_complete_registration',
  'conversion_cost_app_custom_event.fb_mobile_content_view',
  'conversion_cost_app_custom_event.fb_mobile_initiated_checkout',
  'conversion_cost_app_custom_event.fb_mobile_level_achieved',
  'conversion_cost_app_custom_event.fb_mobile_purchase',
  'conversion_cost_app_custom_event.fb_mobile_rate',
  'conversion_cost_app_custom_event.fb_mobile_search',
  'conversion_cost_app_custom_event.fb_mobile_spent_credits',
  'conversion_cost_app_custom_event.fb_mobile_tutorial_completion',
  'conversion_cost_app_custom_event.other',
  'conversion_cost_app_install',
  'conversion_cost_app_use',
  'conversion_cost_checkin',
  'conversion_cost_comment',
  'conversion_cost_credit_spent',
  'conversion_cost_games.plays',
  'conversion_cost_landing_page_view',
  'conversion_cost_like',
  'conversion_cost_link_click',
  'conversion_cost_mobile_app_install',
  'conversion_cost_offsite_conversion.fb_pixel_add_payment_info',
  'conversion_cost_offsite_conversion.fb_pixel_add_to_cart',
  'conversion_cost_offsite_conversion.fb_pixel_add_to_wishlist',
  'conversion_cost_offsite_conversion.fb_pixel_complete_registration',
  'conversion_cost_offsite_conversion.fb_pixel_initiate_checkout',
  'conversion_cost_offsite_conversion.fb_pixel_lead',
  'conversion_cost_offsite_conversion.fb_pixel_purchase',
  'conversion_cost_offsite_conversion.fb_pixel_search',
  'conversion_cost_offsite_conversion.fb_pixel_view_content',
  'conversion_cost_onsite_conversion.flow_complete',
  'conversion_cost_onsite_conversion.messaging_block',
  'conversion_cost_onsite_conversion.messaging_conversation_started_7d',
  'conversion_cost_onsite_conversion.messaging_first_reply',
  'conversion_cost_onsite_conversion.post_save',
  'conversion_cost_onsite_conversion.purchase',
  'conversion_cost_outbound_click',
  'conversion_cost_photo_view',
  'conversion_cost_post',
  'conversion_cost_post_reaction',
  'conversion_cost_rsvp',
  'conversion_cost_video_view',
  'conversion_cost_contact_total',
  'conversion_cost_contact_website',
  'conversion_cost_contact_mobile_app',
  'conversion_cost_contact_offline',
  'conversion_cost_customize_product_total',
  'conversion_cost_customize_product_website',
  'conversion_cost_customize_product_mobile_app',
  'conversion_cost_customize_product_offline',
  'conversion_cost_donate_total',
  'conversion_cost_donate_website',
  'conversion_cost_donate_on_facebook',
  'conversion_cost_donate_mobile_app',
  'conversion_cost_donate_offline',
  'conversion_cost_find_location_total',
  'conversion_cost_find_location_website',
  'conversion_cost_find_location_mobile_app',
  'conversion_cost_find_location_offline',
  'conversion_cost_schedule_total',
  'conversion_cost_schedule_website',
  'conversion_cost_schedule_mobile_app',
  'conversion_cost_schedule_offline',
  'conversion_cost_start_trial_total',
  'conversion_cost_start_trial_website',
  'conversion_cost_start_trial_mobile_app',
  'conversion_cost_start_trial_offline',
  'conversion_cost_submit_application_total',
  'conversion_cost_submit_application_website',
  'conversion_cost_submit_application_mobile_app',
  'conversion_cost_submit_application_offline',
  'conversion_cost_submit_application_on_facebook',
  'conversion_cost_subscribe_total',
  'conversion_cost_subscribe_website',
  'conversion_cost_subscribe_mobile_app',
  'conversion_cost_subscribe_offline',
  'conversion_cost_recurring_subscription_payment_total',
  'conversion_cost_recurring_subscription_payment_website',
  'conversion_cost_recurring_subscription_payment_mobile_app',
  'conversion_cost_recurring_subscription_payment_offline',
  'conversion_cost_cancel_subscription_total',
  'conversion_cost_cancel_subscription_website',
  'conversion_cost_cancel_subscription_mobile_app',
  'conversion_cost_cancel_subscription_offline',
  'conversion_cost_ad_click_mobile_app',
  'conversion_cost_ad_impression_mobile_app',
  'conversion_cost_click_to_call_call_confirm',
  'conversion_cost_page_engagement',
  'conversion_cost_post_engagement',
  'conversion_cost_onsite_conversion.lead_grouped',
  'conversion_cost_lead',
  'conversion_cost_leadgen_grouped',
  'conversion_cost_omni_app_install',
  'conversion_cost_omni_purchase',
  'conversion_cost_omni_add_to_cart',
  'conversion_cost_omni_complete_registration',
  'conversion_cost_omni_view_content',
  'conversion_cost_omni_search',
  'conversion_cost_omni_initiated_checkout',
  'conversion_cost_omni_achievement_unlocked',
  'conversion_cost_omni_activate_app',
  'conversion_cost_omni_level_achieved',
  'conversion_cost_omni_rate',
  'conversion_cost_omni_spend_credits',
  'conversion_cost_omni_tutorial_completion',
  'conversion_cost_omni_custom',
]
