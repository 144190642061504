import styled from 'styled-components'
import { Card, CardBody, CardFooter, CardTitle } from 'reactstrap'

export const PowertableCardWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
  border: initial;
  background: white;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  top: 0;
  padding: 1.25rem;
  overflow: auto;
  border: 1px solid #d3d3d3;
`
export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

export const StyledCardBody = styled(CardBody)`
  padding: 1.75rem;
  overflow: inherit;
  webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  display: flex !important;
  padding: 1.25rem;
  width: 100%;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`

export const StyledCardFooter = styled(CardFooter)`
  width: 100%;
  padding: 0px 1.25rem;
  background: white;
  border: none;

  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`

export const StyledCardTitle = styled(CardTitle)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
`

export const StyledDots = styled('div')`
  width: 6px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
`
export const StyledTableTitle = styled('h2')`
  color: #1b215c;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
  margin-right: 20px;
`

export const PowerTableContainer = styled.div<{
  rowTitleNum: number
  headers: number
  rowAmount: number
  mainRowHeaderSize: number
}>`
  overflow-x: inherit;
  display: grid;
  min-height: 100%;
  grid-template-rows: ${({ headers }) => `${headers * 35}px`};
  grid-template-columns: ${({ rowTitleNum, mainRowHeaderSize }) =>
    `${(rowTitleNum - 1) * 40 + mainRowHeaderSize}px`};
  grid-template-areas:
    'empty header'
    'rowName  main';
  padding-bottom: 20px;
`

export const TableHeader = styled.div``

export const HeaderTitle = styled.span`
  display: block;
  width: 100%;
  font-size: 11px;
  color: #4552e8;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 7px;
`
export const HeaderMetric = styled.div`
  background: #f8f8f8;
  text-align: center;
  font-weight: 600;
  color: #4552e8;
  border-radius: 8px;
  margin: 0 10px;
  margin-top: 8px;
  padding: 8px 0px 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 60px;
`

export const HeaderRow = styled.div``
export const RowData = styled.div`
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &.header-row {
    margin-top: 14.5px;
    margin-bottom: 14.5px;
  }
`

export const HeaderGrids = styled.div<{ headers: number }>`
  overflow: hidden;
  grid-area: header;
  height: ${({ headers }) => `${headers * 45 + 55}px`};
  .first {
    margin-top: 8px;
    background-color: #f8f8f8;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding: 8px 0px 5px;
    margin-left: 10px;
  }
  .middle {
    margin-top: 8px;
    background-color: #f8f8f8;
    padding: 8px 0px 5px;
    border-spacing: -10px !important;
  }
  .last {
    margin-top: 8px;
    padding: 8px 0px 5px;
    border-spacing: 0px !important;
    background-color: #f8f8f8;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    margin-right: 10px;
  }
`

export const RowHeaderGrids = styled.div`
  margin-top: 40px;
  overflow: hidden;
  grid-area: rowName;
  display: flex;
`

export const RowGrid = styled.div<{ width: number; height: number }>`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`

export const RowHeaderContainer = styled.div<{ width: number }>`
  div {
    padding: 0px 5px !important;
    height: 100%;
  }
  &.secondary {
    transform: rotate(270deg);
    position: relative;
    top: 50%;
    width: ${({ width }) => width}px;
    right: ${({ width }) => width / 2 - 20}px;
    border-radius: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    div {
      padding: 0px 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  &.primary {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 100%;
    text-align: center;
    div {
      border-bottom: 1px solid #d8d8d8;
      border-radius: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 5px !important;
    }
  }
  background-color: #f8f8f8;
  border-radius: 8px;
  font-weight: 600;
  line-height: 28px;
  color: #787b90;
  font-family: 'Hind Guntur';
  text-align: center;
`

export const MainDataContainer = styled.div`
  margin-top: 40px;
  grid-area: main;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`

export const ErrorMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: red;
`

export const RowContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const TitleContainer = styled.div`
  display: flex;
  .table-title {
    margin-right: 20px;
  }
`

export const DateLabel = styled.span`
  position: relative;
  top: 4px;
  font-size: 14px;
`

export const PowertableImageContainer = styled.div<{
  hasImageAdditionalData: boolean
}>`
  ${({ hasImageAdditionalData }) => {
    if (hasImageAdditionalData) {
      return `
        display: grid;
        grid-template-columns: 35% 65%;
      `
    }
    return `
      display: flex;
      justify-content: center;
    `
  }}
  padding-top: 20px;
  div {
    border-radius: none !important;
    border-bottom: none !important;
  }
  .image-wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    img {
      white-space: normal;
      &:first {
        display: none;
      }
      z-index: 1;
    }
    img:after {
      content: 'No photo';
    }
  }
  .image-extra-data {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .image-title {
      font-size: 12px;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .image-description {
      font-size: 11px;
      width: 90%;
      font-weight: 400;
      white-space: normal;
      line-height: 15px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
`

export const TitleDescriptionContainer = styled.div`
  div {
    border-radius: none !important;
    border-bottom: none !important;
  }
  display: flex;
  flex-direction: column;
  .description {
    font-size: 11px;
    width: 90%;
    font-weight: 400;
    white-space: normal;
    line-height: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const NewTabIconWrapper = styled.div`
  width: 26px !important;
  height: 26px !important;
  img {
    width: 100%;
  }
`

export const ViewIcon = styled.a`
  &:hover {
    span {
      color: black;
    }
    filter: invert(31%) sepia(96%) saturate(1177%) hue-rotate(218deg)
      brightness(87%) contrast(110%);
  }
  display: flex;
  justify-content: end;
  margin-right: 20px;
  span {
    position: relative;
    top: 6px;
    font-weight: 500;
    font-size: 10px;
  }
`
