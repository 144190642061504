import React from 'react'

const HelpIcon = ({ className, id }: { className?: string; id: string }) => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      id={id}
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#2CC1D1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.08997 9C9.32507 8.33167 9.78912 7.76811 10.3999 7.40913C11.0107 7.05016 11.7289 6.91894 12.4271 7.03871C13.1254 7.15849 13.7588 7.52153 14.215 8.06353C14.6713 8.60553 14.921 9.29152 14.92 10C14.92 12 11.92 13 11.92 13"
        stroke="#2CC1D1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17H12.01"
        stroke="#2CC1D1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HelpIcon
