import styled from 'styled-components'

export const ErrorText = styled('p')`
  display: flex;
  width: 100%;
  font-weight: 400;
  text-align: left;
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border: 0;
  color: red;
`
