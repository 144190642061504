import React, { useState, Dispatch, SetStateAction } from 'react'
import Elipsis from '../Icons/Elipsis'
import DeleteIcon from '../../assets/icons/delete-icon.svg'
import DuplicateIcon from '../../assets/icons/duplicate-icon.svg'
import EditIcon from '../../assets/icons/pen.svg'
import { debounce } from 'lodash'
import { TooltipActions } from './Styles'

const ClientActions = ({
  id,
  handleEdit,
  handleRemove,
  handleDuplicate,
}: {
  id: string
  handleEdit?: Dispatch<SetStateAction<string>>
  handleRemove?: Dispatch<SetStateAction<string>>
  handleDuplicate?: (id: string) => void
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false)

  const handleActionHover = debounce(
    (showDropdown: boolean) => setOptionsOpen(showDropdown),
    200
  )

  const items = [
    {
      name: 'Edit',
      img: EditIcon,
      hideWhileLoading: true,
      method: handleEdit,
    },
    {
      name: 'Duplicate',
      img: DuplicateIcon,
      method: handleDuplicate,
    },
    {
      name: 'Delete',
      img: DeleteIcon,
      hideWhileLoading: true,
      method: handleRemove,
    },
  ]
  return (
    <div
      className="client-actions"
      onClick={() => setOptionsOpen(!optionsOpen)}
      onDragEnter={e => e.preventDefault()}
      onDragLeave={e => e.preventDefault()}
    >
      <Elipsis color="#2dc3d3" />
      {optionsOpen && (
        <TooltipActions
          onMouseEnter={() => handleActionHover(true)}
          onMouseLeave={() => handleActionHover(false)}
        >
          <ul>
            {items.map(item => (
              <li key={item.name}>
                <button
                  onClick={() => {
                    item?.method && item?.method(id)
                  }}
                  className="button-container"
                >
                  <img
                    src={item.img}
                    className={`${item.name}-button preload-animation`}
                    alt=""
                  />
                </button>
              </li>
            ))}
          </ul>
        </TooltipActions>
      )}
    </div>
  )
}

export default ClientActions
