import { GET_ONE_TABLE_DASHBOARD_QUERY_KEY } from './../../../dashboardOverviews/requests/useGetOneDashboardOverview'
import useUpdateColumns from '../useUpdateColumns'
import queryClient from '../../../reactQuery'
import { GET_ONE_TABLE_QUERY_KEY } from '../useGetOneUserTable'
import { TableReporting } from '@mm/ui/src/components/SmartTable/types'
import { handleError } from '@mm/ui/src/components/SmartTable/context/utils'

const useUpdateColumnsHook = (overviewId: string, widgetId?: string) => {
  const queryKey = widgetId
    ? [GET_ONE_TABLE_DASHBOARD_QUERY_KEY, widgetId]
    : [GET_ONE_TABLE_QUERY_KEY, overviewId]

  const mutation = useUpdateColumns({
    onSuccess: columns => {
      queryClient.setQueryData(queryKey, (table?: TableReporting) => {
        if (!table) return
        const tableCopy = { ...table }
        tableCopy.columns = columns
        return tableCopy
      })
    },
    onError: error => {
      queryClient.invalidateQueries(queryKey)
      handleError({ message: 'The table could not been updated' })
    },
  })

  const updateColumnsHandler = async (
    updatedColumns: any,
    property: string
  ) => {
    try {
      const body = {
        columns: updatedColumns,
        property: property,
        id: overviewId,
      }

      await mutation.mutateAsync(body)
    } catch (error) {
      console.error('Error in updateColumnsHandler:', error)
    }
  }

  return {
    updateColumnsHandler,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSuccess: mutation.isSuccess,
  }
}

export default useUpdateColumnsHook
