// @ts-nocheck
export const activityMetricsFormats = {
  'calls.all.all.avg_duration': 'duration',
  'calls.all.all.count': 'number',
  'calls.all.all.sum_duration': 'duration',
  'calls.inbound.all.avg_duration': 'duration',
  'calls.inbound.all.count': 'number',
  'calls.inbound.all.sum_duration': 'duration',
  'calls.outbound.all.avg_duration': 'duration',
  'calls.outbound.all.count': 'number',
  'calls.outbound.all.sum_duration': 'duration',
  'calls.outbound.external.avg_duration': 'duration',
  'calls.outbound.external.count': 'number',
  'calls.outbound.external.sum_duration': 'duration',
  'calls.outbound.power_dialer.avg_duration': 'duration',
  'calls.outbound.power_dialer.count': 'number',
  'calls.outbound.power_dialer.sum_duration': 'duration',
  'calls.outbound.predictive_dialer.avg_duration': 'duration',
  'calls.outbound.predictive_dialer.count': 'number',
  'calls.outbound.predictive_dialer.sum_duration': 'duration',
  'calls.outbound.regular.avg_duration': 'duration',
  'calls.outbound.regular.count': 'number',
  'calls.outbound.regular.sum_duration': 'duration',
  'emails.opened.all.count': 'number',
  'emails.opened.bulk.count': 'number',
  'emails.opened.manual.count': 'number',
  'emails.opened.sequences.count': 'number',
  'emails.received.all.count': 'number',
  'emails.sent.all.count': 'number',
  'emails.sent.bulk.count': 'number',
  'emails.sent.bulk_with_reply.count': 'number',
  'emails.sent.bulk_without_reply.count': 'number',
  'emails.sent.manual.count': 'number',
  'emails.sent.manual_with_reply.count': 'number',
  'emails.sent.manual_without_reply.count': 'number',
  'emails.sent.sequences.count': 'number',
  'emails.sent.sequences_with_reply.count': 'number',
  'emails.sent.sequences_without_reply.count': 'number',
  'leads.contacted.all.count': 'number',
  'leads.created.all.count': 'number',
  'meetings.all.count': 'number',
  'meetings.canceled.count': 'number',
  'meetings.completed.count': 'number',
  'meetings.declined.count': 'number',
  'meetings.upcoming.count': 'number',
  'opportunities.created.all.count': 'number',
  'opportunities.created.all.sum_annualized_value': 'currency',
  'opportunities.created.annual.sum_value': 'currency',
  'opportunities.created.monthly.sum_value': 'currency',
  'opportunities.created.one_time.sum_value': 'currency',
  'opportunities.created_for.all.count': 'number',
  'opportunities.created_for.all.sum_annualized_value': 'currency',
  'opportunities.created_for.annual.sum_value': 'currency',
  'opportunities.created_for.monthly.sum_value': 'currency',
  'opportunities.created_for.one_time.sum_value': 'currency',
  'opportunities.lost.all.count': 'number',
  'opportunities.lost.all.sum_annualized_value': 'currency',
  'opportunities.value_lost.annual.count': 'number',
  'opportunities.value_lost.annual.sum_value': 'currency',
  'opportunities.value_lost.monthly.count': 'number',
  'opportunities.value_lost.monthly.sum_value': 'currency',
  'opportunities.value_lost.one_time.count': 'number',
  'opportunities.value_lost.one_time.sum_value': 'currency',
  'opportunities.value_won.annual.count': 'number',
  'opportunities.value_won.annual.sum_value': 'currency',
  'opportunities.value_won.monthly.count': 'number',
  'opportunities.value_won.monthly.sum_value': 'currency',
  'opportunities.value_won.one_time.count': 'number',
  'opportunities.value_won.one_time.sum_value': 'currency',
  'opportunities.won.all.count': 'number',
  'opportunities.won.all.sum_annualized_value': 'currency',
  'sms.received.all.count': 'number',
  'sms.sent.all.count': 'number',
}
