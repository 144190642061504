import React, { CSSProperties } from 'react'
import LinkedinIcon from '../../../assets/icons/datasources/linkedin.svg'
import MetaIcon from '../../../assets/icons/datasources/facebook.svg'
import GoogleIcon from '../../../assets/icons/datasources/google.svg'
import TiktokIcon from '../../../assets/icons/datasources/tiktok.svg'

interface SourceIconCellProps {
  text: string
  source: string
}

const SourceReplaceWord: { [key: string]: string } = {
  linkedin: 'Linkedin Ads',
  meta: 'Facebook Ads',
  google: 'Google Ads',
  tiktok: 'Tiktok Ads',
}

const SourceIcons: { [key: string]: string } = {
  linkedin: LinkedinIcon,
  meta: MetaIcon,
  google: GoogleIcon,
  tiktok: TiktokIcon,
}

export function SourceIconCell({ text, source }: SourceIconCellProps) {
  if (!text || !source) return <span> - </span>
  const replaceWord = SourceReplaceWord[source]
  const icon = SourceIcons[source]

  if (!replaceWord || !icon) return <span>{text}</span>

  const updatedText = text
    .replace(`( ${replaceWord} )`, '')
    .replace(replaceWord, '')

  //
  const CenterRight: CSSProperties =
    updatedText === '' ? {} : { marginRight: 5 }

  return (
    <span>
      <img
        src={icon}
        alt="Source Icon"
        style={{
          verticalAlign: 'middle',
          textAlign: 'center',
          width: '1.5em',
          height: '1.5em',
          ...CenterRight,
        }}
      />
      {updatedText}
    </span>
  )
}
