import React from 'react'
import { AddBtn } from './styles'

interface Props {
  handleAdd: () => void
}

const AddButton = ({ handleAdd }: Props) => {
  return <AddBtn onClick={handleAdd}></AddBtn>
}

export default AddButton
