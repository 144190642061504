// @ts-nocheck

import moment from 'moment'

export const getDatesArray = () => [
  {
    id: 1,
    name: 'Today',
    from: moment().format(),
    to: moment().format(),
    untilTodayDefault: false,
  },
  {
    id: 2,
    name: 'This week',
    from: moment().startOf('week').format(),
    to: moment().endOf('week').format(),
    untilTodayDefault: true,
  },
  {
    id: 3,
    name: 'This month',
    from: moment().startOf('month').format(),
    to: moment().endOf('month').format(),
    untilTodayDefault: true,
  },
  {
    id: 4,
    name: 'This quarter',
    from: moment().startOf('quarter').format(),
    to: moment().endOf('quarter').format(),
    untilTodayDefault: true,
  },
  {
    id: 5,
    name: 'This year',
    from: moment().startOf('year').format(),
    to: moment().endOf('year').format(),
    untilTodayDefault: true,
  },
  {
    id: 6,
    name: 'Yesterday',
    from: moment().subtract(1, 'days').format(),
    to: moment().subtract(1, 'days').format(),
    untilTodayDefault: false,
  },
  {
    id: 7,
    name: 'Last week',
    from: moment().subtract(1, 'weeks').startOf('week').format(),
    to: moment().subtract(1, 'weeks').endOf('week').format(),
    untilTodayDefault: false,
  },
  {
    id: 8,
    name: 'Last month',
    from: moment().subtract(1, 'month').startOf('month').format(),
    to: moment().subtract(1, 'month').endOf('month').format(),
    untilTodayDefault: false,
  },
  {
    id: 9,
    name: 'Last quarter',
    from: moment().subtract(1, 'quarter').startOf('quarter').format(),
    to: moment().subtract(1, 'quarter').endOf('quarter').format(),
    untilTodayDefault: false,
  },
  {
    id: 10,
    name: 'Last year',
    from: moment().subtract(1, 'year').startOf('year').format(),
    to: moment().subtract(1, 'year').endOf('year').format(),
    untilTodayDefault: false,
  },
  {
    id: 12,
    name: 'Customize',
    untilTodayDefault: false,
  },
  {
    id: 14,
    name: 'Last days',
    untilTodayDefault: false,
  },
]

export enum DateRanges {
  Today = 1,
  ThisWeek = 2,
  ThisMonth = 3,
  ThisQuarter = 4,
  ThisYear = 5,
  Yesterday = 6,
  LastWeek = 7,
  LastMonth = 8,
  LastQuarter = 9,
  LastYear = 10,
  Customize = 12,
  LastDays = 14,
}

export const dateRangeToId = {
  Today: 1,
  'This week': 2,
  'This month': 3,
  'This quarter': 4,
  'This year': 5,
  Yesterday: 6,
  'Last week': 7,
  'Last month': 8,
  'Last quarter': 9,
  'Last year': 10,
  Customize: 12,
  'Last days': 14,
}
