import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'

export const UPDATE_COLUMN_KEY = 'Tables_UpdateColumns'

const updateColumns = async (payload: { [key: string]: any }) => {
  const { id, ...data } = payload
  if (id === 0) return
  return tablesApi.put(`/${id}/columns`, data).then(response => response.data)
}

const useUpdateColumns = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateColumns, {
    mutationKey: [UPDATE_COLUMN_KEY],
    ...queryOptions,
  })
}

export default useUpdateColumns
