import React from 'react'
import { FieldInputProps } from '../FieldEditor'
import SingleDatePicker from '../../../../SingleDatePicker'
import { DATE_FNS_FORMAT } from '../../../../SingleDatePicker/SingleDatePicker'

const DateInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  value,
  onKeyDown,
}) => {
  return (
    <SingleDatePicker
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      initiallyOpen={true}
      formatString={DATE_FNS_FORMAT}
    />
  )
}

export default DateInput
