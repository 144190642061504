import { useQuery } from '@tanstack/react-query'

import tablesApi from '../'

export const GET_TABLES_QUERY_KEY = 'Tables_GetUserTables'

export const getUserTables = async (type?: 'campaign' | 'client') => {
  if (!type) return []

  return tablesApi.get(`?groupBy=${type}`).then(response => response.data)
}

const useGetUserTables = (type?: 'campaign' | 'client', queryOptions: any) =>
  useQuery(
    [GET_TABLES_QUERY_KEY, type],
    () => getUserTables(type),
    queryOptions
  )

export default useGetUserTables
