// @ts-nocheck

import moment from 'moment'
import { getDatesArray } from './ranges'

export const headers = timezone => {
  const user = JSON.parse(localStorage.getItem('user'))
  const headers = {
    Authorization: user && user.token,
    locale: 'en',
  }
  if (timezone) headers.timezone = timeZoneParam()
  return headers
}

export const timeZoneParam = () => {
  const tm = moment().format('Z').split(':')
  const h = Number(tm[0])
  const m = (tm[1] / 60) * (h > 0 ? 1 : -1)
  return `${h + m}`
}

export const formatDateForApi = date => {
  return {
    range: date.name,
    from: moment(date.from).format('YYYY-MM-DD'),
    to: moment(date.to).format('YYYY-MM-DD'),
    lastDays: date ? date.dateFilterLastDays : null,
    untilToday: date ? date.dateFilterUntilToday : null,
  }
}

export const formatDateForDashboardApi = date => {
  return {
    dateFilterRange: date ? date.name : null,
    dateFilterFrom: date ? moment(date.from).format('YYYY-MM-DD') : null,
    dateFilterTo: date ? moment(date.to).format('YYYY-MM-DD') : null,
    dateFilterLastDays: date ? date.dateFilterLastDays : null,
    dateFilterUntilToday: date ? date.dateFilterUntilToday : null,
  }
}

export const formatDateFromApi = stat => {
  const datesArray = getDatesArray()
  const date = { ...datesArray.find(d => d.name === stat.range) }
  if (stat.range === 'Customize') {
    date.from = stat.from
    date.to = stat.to
  }
  return date
}
