import React, { Dispatch, SetStateAction, useState } from 'react'
import { formatData, getValueRowData } from './Utils'
import { formatDateMDY } from './CustomCell/utils'
import CustomCell from './CustomCell/CustomCell'
import { RowData, ColData } from './types'
import { ColumnCell, ColumnValue } from './Styles'
import ClientActions from './ClientActions'
import { ComparedValueMarker } from './ComparedValueMarker/ComparedValueMarker'
import { SourceIconCell } from './SourceIconCell/SourceIconCell'
import dragIcon from '../../assets/icons/drag.svg'
import WarningAlert from './WarningAlert'
import { getFieldDate } from './ComparedValueMarker/calculateDate'

interface SmartTableColumnProps {
  row: RowData
  column: ColData
  columnIndex: number
  columns: Array<ColData>
  updateField: (updateFieldProps: {
    value: string
    rowId: string
    columnId: number
    refresh?: boolean
  }) => void
  updateCheckboxValue?: (updateFieldProps: {
    value: boolean
    rowId: string
    column: ColData
    onError: () => void
  }) => void
  table: any
  appliedView: any
  tableMetrics: { [key: string]: unknown }
  setSecondaryEntityModal?: Dispatch<SetStateAction<string>>
  handleRemoveSecondaryEntity?: Dispatch<SetStateAction<string>>
  handleDuplicateSecondaryEntity?: (id: string) => void
  rowIndex?: number
  isFixedColumn: boolean
  errorWarning?: { messages: string[] }
  allowEdit: boolean
}

const sourceUrl = {
  meta: ' https://adsmanager.facebook.com/adsmanager/manage/ads',
  google: 'https://adwords.google.com/aw/overview',
  linkedin: 'https://www.linkedin.com/campaignmanager/accounts',
  tiktok: 'https://ads.tiktok.com/i18n/perf/campaign',
}

export const SmartTableCell = (props: SmartTableColumnProps) => {
  const {
    row,
    column,
    columnIndex,
    updateField,
    updateCheckboxValue,
    columns,
    table,
    appliedView,
    tableMetrics,
    setSecondaryEntityModal,
    handleRemoveSecondaryEntity,
    handleDuplicateSecondaryEntity,
    rowIndex,
    isFixedColumn,
    errorWarning,
    allowEdit,
  } = props

  // const [isDragging, setIsDragging] = useState(false)

  const isMetric = column.type === 'metric'
  const isFormula = column.type === 'formula'

  const getLinkURL = (data: RowData) => {
    if (!data) return '#'
    const { accountId, id, _dataSource, campaign_name } = data as {
      [key: string]: { current: string }
    }

    if (_dataSource?.current === 'meta') {
      if (!accountId) return '#'
      const metaAccount = accountId.current.slice(4)
      return `${
        sourceUrl[_dataSource.current]
      }?act=${metaAccount}&selected_campaign_ids=${id}`
    }
    if (_dataSource?.current === 'google') {
      return `${
        sourceUrl[_dataSource.current]
      }?__e=${accountId}&authuser=1&}&campaignId=${id}`
    }
    if (_dataSource?.current === 'linkedin') {
      return `${
        sourceUrl[_dataSource.current]
      }/${accountId}/creatives?&campaignIds=%5B${id}%5D`
    }
    if (_dataSource?.current === 'tiktok') {
      return `${sourceUrl[_dataSource.current]}?aadvid=${
        accountId.current
      }&keyword=${campaign_name.current}`
    }

    return
  }

  let cellData = getValueRowData(row, column?.data?.value)

  if (column.type === 'date' && column.data.dateType !== 'manual') {
    cellData = getFieldDate(
      column.data.dateType,
      appliedView?.value?.date || table?.date,
      column.data.default
    )!
  }
  const compare = column.data.compare
  const compareEnabled =
    compare?.enabled ||
    column.type === 'result_formula' ||
    column.type === 'source_result' ||
    column.type === 'crm'

  const columnName = column.data.value
  if (isMetric || isFormula) {
    if (column.label === 'Campaign Name') {
      return (
        <a
          style={{
            color: '#1461cc',
          }}
          href={getLinkURL(row)}
          target="blank"
          rel="noopener noreferrer"
        >
          {cellData}
        </a>
      )
    }

    if (column.label === 'Start Date' || column.label === 'End Date') {
      return (
        <span title={formatDateMDY(cellData)}>{formatDateMDY(cellData)}</span>
      )
    }

    if (compareEnabled && row) {
      const metric = column?.data?.value
      const comparedValue = compareEnabled
        ? getValueRowData(row, metric, false) || 0
        : undefined

      return (
        <>
          <span title={cellData ?? '-'}>
            {formatData(
              cellData,
              column,
              column.data.decimals,
              column.data.numberType
            )}
          </span>
          <ComparedValueMarker
            key={`compared-${row.id}-${column.id}`}
            metric={columnName}
            comparedValue={Number(comparedValue)}
            current={Number(cellData)}
            compare={compare}
            rowIndex={rowIndex}
            startDate={new Date(table.date.since)}
            endDate={new Date(table.date.until)}
            column={column}
          ></ComparedValueMarker>
        </>
      )
    }
    if (columnName === 'source' || columnName === 'accountName') {
      const { _dataSource } = row as {
        [key: string]: { current: string }
      }

      return (
        <span
          title={cellData ?? '-'}
          style={{
            margin: columnName === 'source' ? 'auto' : undefined,
          }}
        >
          <SourceIconCell
            text={cellData}
            source={_dataSource?.current}
          ></SourceIconCell>
        </span>
      )
    }
    return (
      <ColumnCell>
        {columnIndex === 0 && (row.id as { current: string })?.current && (
          <>
            <div
              className="drag-icon"
              onDragStart={e =>
                e.dataTransfer.setData(
                  'text/plain',
                  (row.id as { current: string })?.current
                )
              }
              draggable
              style={{ marginRight: '10px' }}
            >
              <img
                alt=""
                src={dragIcon}
                className={`buttons-img preload-animation`}
                id={`drag-${(row.id as { current: string })?.current}`}
                style={{ cursor: 'grab' }}
              />
            </div>
            <ClientActions
              id={(row.id as { current: string })?.current}
              handleEdit={setSecondaryEntityModal}
              handleRemove={handleRemoveSecondaryEntity}
              handleDuplicate={handleDuplicateSecondaryEntity}
            ></ClientActions>
          </>
        )}
        <ColumnValue>
          <span
            title={cellData ?? '-'}
            style={{
              whiteSpace: 'nowrap',
            }}
          >
            {formatData(
              cellData,
              column,
              column.data.decimals,
              column.data.numberType
            )}
          </span>
          {columnIndex === 0 && errorWarning && (
            <WarningAlert
              errors={errorWarning}
              id={`${(row as any)?.id?.current}-${column.id}`}
            />
          )}
        </ColumnValue>
      </ColumnCell>
    )
  }

  return (
    <CustomCell
      column={column}
      row={row}
      type={column.type}
      columns={columns}
      updateCheckboxValue={updateCheckboxValue}
      updateField={(value: string, refresh: boolean) => {
        updateField &&
          updateField({
            value,
            rowId: (row.id as { current: string })?.current || '',
            columnId: column.id,
            refresh,
          })
      }}
      initialValue={cellData}
      options={column.data.options}
      table={table}
      tableMetrics={
        tableMetrics as unknown as {
          dataType: string
          id: string
          label: string
          source: string
          type: string
          value: string
        }[]
      }
      rowIndex={rowIndex}
      isFixedColumn={isFixedColumn}
      allowEdit={allowEdit}
    />
  )
}
