import React from 'react'
import { RowData } from '../style'
import { formatMetric } from '../utils'
import { PowertableStructure } from '../types'

export class Data implements PowertableStructure {
  public value: string
  public isHeader: boolean
  public format: string
  constructor({
    value,
    isHeader,
    format = '',
  }: {
    value: number | string
    isHeader: boolean
    format?: string
  }) {
    this.value = formatMetric(format, value)
    this.isHeader = isHeader
    this.format = format
  }

  render() {
    return (
      <RowData className={this.isHeader ? 'header-row' : ''}>
        <span title={this.value.toString()}>{this.value}</span>
      </RowData>
    )
  }
}
