import React from 'react'

const Italic = ({ active }) => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
      <path
        opacity="0.5"
        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
        fill={active ? '#1C274C' : '#eeeeee'}
      />
      <path
        d="M10.6667 6.24994H13.3162C13.3273 6.24969 13.3384 6.24969 13.3495 6.24994H16C16.4142 6.24994 16.75 6.58573 16.75 6.99994C16.75 7.41416 16.4142 7.74994 16 7.74994H13.9095L11.6429 16.2499H13.3333C13.7475 16.2499 14.0833 16.5857 14.0833 16.9999C14.0833 17.4142 13.7475 17.7499 13.3333 17.7499H10.6838C10.6727 17.7502 10.6616 17.7502 10.6505 17.7499H8C7.58579 17.7499 7.25 17.4142 7.25 16.9999C7.25 16.5857 7.58579 16.2499 8 16.2499H10.0905L12.3571 7.74994H10.6667C10.2525 7.74994 9.91667 7.41416 9.91667 6.99994C9.91667 6.58573 10.2525 6.24994 10.6667 6.24994Z"
        fill={active ? '#000000' : '#a0a0a0'}
      />
    </svg>
  )
}

export default Italic
