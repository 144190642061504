import axios, { AxiosRequestHeaders } from 'axios'
import * as constants from '../_constants'
import { auth } from '@mm/utils/src/helpers/Utils'
import { handleJWTError } from '@mm/api/src'
const instance = axios.create({
  baseURL: `${constants.BASE_URL}dashboard`,
  timeout: constants.TIMEOUT * 10,
})

instance.interceptors.request.use(config => {
  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      ...(auth().headers || {}),
    } as AxiosRequestHeaders,
  }
})

handleJWTError(instance)

export default instance
