import {
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledViewOptions,
  StyledDots,
  StyledTableTitle,
  PowertableCardWrapper,
  StyledCardFooter,
} from './style'
import { useEffect, useRef, useState } from 'react'
import { DateLabel, ErrorMessage, TitleContainer } from './style'
import Tooltip from '../DashboardTable/Tooltip'
import useGetPowertableData from '../../../../api/src/powertables/requests/useGetPowertableData'
import Loader from '../Loader'
import { Table } from './PowertableStructure/Table'
import { TableData } from './types'
import React from 'react'
import {
  DashboardDate,
  calculateDateRange,
} from '@mm/ui/src/helpers/widgetDate'
import WidgetIcon from '../WidgetIcon'
import { PowerAIDescription } from '../PowerAIDescription/PowerAIDescription'
const VITE_AI_DESCRIPTION_ENABLED =
  (import.meta as any).env.VITE_AI_DESCRIPTION_ENABLED === 'true'

interface Props {
  name: string
  onDelete: () => void
  onEdit: () => void
  loading: boolean
  onDuplicate: () => void
  id: string
  onSort: (direction: number) => void
  shareable: string
  pdf: string
  stringDate: string
  dateChosen: DashboardDate
}

const Powertable = ({
  onDelete,
  onEdit,
  onDuplicate,
  loading,
  id,
  onSort,
  shareable,
  dateChosen,
  pdf,
}: Props) => {
  const {
    data: response,
    isFetching: isLoading,
    error,
  }: { data: any; isFetching: boolean; error: any } = useGetPowertableData(
    id,
    shareable,
    pdf
  )
  const [table, setTable] = useState<Table>()
  const [tooltipVisibility, setTooltipVisibility] = useState(false)
  const [stringDate, setStringDate] = useState('')
  const handleToggleTooltip = () => {
    setTooltipVisibility(!tooltipVisibility)
  }
  const [key, setKey] = useState(Date.now())
  const elementRef = useRef(null)

  useEffect(() => {
    setKey(Date.now())
  }, [dateChosen, response])

  const PowertableTooltip = () => {
    return (
      <>
        <StyledViewOptions
          onClick={handleToggleTooltip}
          onMouseDown={e => e.stopPropagation()}
          onMouseMove={e => e.stopPropagation()}
        >
          <StyledDots className="dots"></StyledDots>
        </StyledViewOptions>

        <Tooltip
          show={tooltipVisibility}
          toggle={handleToggleTooltip}
          onDelete={onDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onMoveUp={() => onSort(-1)}
          onMoveDown={() => onSort(1)}
          loading={false}
        />
      </>
    )
  }

  useEffect(() => {
    if (response) {
      setTable(new Table(response.data as TableData))
      setStringDate(calculateDateRange(dateChosen, response))
    }
  }, [response])

  useEffect(() => {
    if (!elementRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      setKey(Date.now())
    })
    resizeObserver.observe(elementRef.current)
    return () => resizeObserver.disconnect()
  }, [])

  const aiDescription = response?.data?.aiDescription?.message
  const aiError = response?.data?.aiDescription?.error

  return (
    <PowertableCardWrapper ref={elementRef} className="draggable-handle">
      <StyledCard id={`powertable-${id}`}>
        <StyledCardBody
          className="scrollbar-color"
          style={{ overflow: 'hidden' }}
        >
          {isLoading || loading ? (
            <Loader small={false} white={false} style={{}} />
          ) : error ? (
            <div>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <PowertableTooltip />
              </div>
              <ErrorMessage>
                {`Error loading powertable: ${
                  error.response?.data?.message ||
                  'Server could not process powertable data'
                }`}
              </ErrorMessage>
            </div>
          ) : (
            <>
              {response.show_icons && (
                <WidgetIcon sources={[response.source]} />
              )}
              <StyledCardTitle>
                <TitleContainer>
                  <StyledTableTitle>{response.name}</StyledTableTitle>
                  <DateLabel>{stringDate}</DateLabel>
                </TitleContainer>
                <PowertableTooltip />
              </StyledCardTitle>
              {table?.render(key)}
            </>
          )}
        </StyledCardBody>
        {VITE_AI_DESCRIPTION_ENABLED && (isLoading || loading) ? null : (
          <StyledCardFooter>
            <PowerAIDescription aiDescription={aiDescription} error={aiError} />
          </StyledCardFooter>
        )}
      </StyledCard>
    </PowertableCardWrapper>
  )
}

export default Powertable
