import React, { useMemo } from 'react'
import { FieldInputProps } from '../FieldEditor'
import { SelectWrapper } from './styles'
import SmartReportingSelect from '../../../../Forms/_control/SmartReportingSelect'

const MultiSelectInput: React.ComponentType<FieldInputProps> = ({
  onBlur,
  onChange,
  onKeyDown,
  value,
  options,
}) => {
  const handleSelect = ({
    target,
  }: {
    target: { value: string } | { [key: number]: { value: string } }
  }) => {
    onChange(
      Object.values(target)
        .map(opt => opt.value)
        .filter(opt => opt)
        .join(',')
    )
  }

  const _splitedValues = useMemo(() => value.split(','), [value])

  return (
    <SelectWrapper>
      <SmartReportingSelect
        isMulti
        label={''}
        name={''}
        options={options || []}
        placeholder="Select one or more"
        className="react-select"
        classNamePrefix="react-select"
        value={_splitedValues}
        onChange={handleSelect}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus
      />
    </SelectWrapper>
  )
}

export default MultiSelectInput
