import {
  NewTabIconWrapper,
  PowertableImageContainer,
  ViewIcon,
} from '../../style'
import React from 'react'
import NewTabIcon from '../../../../assets/icons/new-tab.svg'

export const ImageContainter = ({
  hasImageAdditionalData,
  image,
  title,
  description,
  externalLink,
}: {
  hasImageAdditionalData: boolean
  image: string
  title?: string
  description?: string
  externalLink?: string
}) => {
  return (
    <PowertableImageContainer hasImageAdditionalData={!!hasImageAdditionalData}>
      <div className="image-wrapper">
        <img style={{ width: '100px' }} src={image} alt="" title={title} />
      </div>
      {title || description ? (
        <div className="image-extra-data">
          <span className="image-title" title={title}>
            {title}
          </span>
          <span className="image-description" title={description}>
            {description}
          </span>
          {externalLink ? (
            <ViewIcon
              className="image-external-link"
              title="Preview"
              href={externalLink}
              target="_blank"
              rel="noreferrer"
            >
              <span>View</span>
              <NewTabIconWrapper>
                <img src={NewTabIcon} alt="" />
              </NewTabIconWrapper>
            </ViewIcon>
          ) : (
            <span></span>
          )}
        </div>
      ) : (
        ''
      )}
    </PowertableImageContainer>
  )
}
