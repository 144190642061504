import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'
import { ClientData } from '../../../customComponents/forms/AddNewTableModal/ClientModal/ClientConfiguration'

const CREATE_CLIENT_KEY = 'Tables_CreateClient'

const createClient = async ({
  tableId,
  data,
}: {
  tableId: string
  data: ClientData
}) => {
  if (!tableId) throw new Error('Missing Table ID')
  return tablesApi
    .post(`/${tableId}/clients`, data)
    .then(response => response.data)
}

const useCreateClient = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(createClient, {
    mutationKey: [CREATE_CLIENT_KEY],
    ...queryOptions,
  })
}

export default useCreateClient
