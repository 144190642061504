// @ts-nocheck

import React from 'react'
import { Transition } from 'react-transition-group'

import DeleteIcon from '../../assets/icons/delete-icon.svg'
import EditIcon from '../../assets/icons/pen.svg'
import DuplicateIcon from '../../assets/icons/duplicate-icon.svg'
import compareIcon from '../../assets/icons/compare.svg'

const duration = 150

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const items = [
  {
    name: 'Delete',
    img: DeleteIcon,
  },
  {
    name: 'Edit',
    img: EditIcon,
  },
  {
    name: 'Duplicate',
    img: DuplicateIcon,
  },
  {
    name: 'Compare',
    img: compareIcon,
  },
]

class TooltipMenu extends React.Component<{
  show: boolean
  toggle: () => void
  onDelete: () => void
  onEdit: () => void
  onDuplicate: () => void
  onCompare?: () => void
  isCompared?: boolean
}> {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setRef = this.setRef.bind(this)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = e => {
    if (this.ref && !this.ref.contains(e.target)) {
      this.props.toggle()
    }
  }

  handleClick = buttonName => {
    try {
      this.props[`on${buttonName}`]()
      if (!buttonName.includes('Move')) this.props.toggle()
    } catch (e) {
      console.log(e)
    }
  }

  setRef = node => {
    this.ref = node
  }

  render() {
    const { show, isCompared } = this.props

    const comparedItem = items[3]
    if (comparedItem) comparedItem.unchecked = !isCompared

    return (
      <Transition in={show} timeout={300} mountOnEnter unmountOnExit>
        {state => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={this.setRef}
            className={'carousel-items-popup'}
            onMouseDown={e => e.stopPropagation()}
            onMouseMove={e => e.stopPropagation()}
          >
            <ul>
              {items.map(item => {
                if (!this.props[`on${item.name}`]) return null

                return (
                  <li key={item.name}>
                    <button
                      onClick={() => {
                        this.handleClick(item.name)
                      }}
                      className={`button-container ${
                        item.name === 'Delete' ? 'hide-on-mobile' : ''
                      }`}
                    >
                      <img
                        src={item.img}
                        style={
                          item.unchecked
                            ? { filter: 'grayscale(1)', opacity: 0.7 }
                            : {}
                        }
                        className={`${item.name}-button preload-animation`}
                        alt=""
                      />
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </Transition>
    )
  }
}

export default TooltipMenu
