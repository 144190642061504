import React, { useCallback, useMemo } from 'react'
import { BaseEditor, createEditor } from 'slate'
import { withHistory } from 'slate-history'
import { Editable, ReactEditor, Slate, withReact } from 'slate-react'
import { BlockButton, LinkButton, MarkButton, Toolbar } from './components'
import { toggleKeyboardShortcut } from './keyboardShortcuts'
import { withLinks } from './plugins'
import { Element, Leaf } from './toolbarElements'

import { Container } from './styles'

type CustomElement = {
  type: string
  children: CustomText[]
}
type CustomText = {
  text: string
  type?: string
  bold?: boolean
  italic?: boolean
  underline?: boolean
  code?: boolean
  'heading-one'?: boolean
  'heading-two'?: boolean
  'numbered-list'?: boolean
  'bulleted-list'?: boolean
  url?: string
}

export interface MMBaseEditor extends BaseEditor {
  type?: string
}

declare module 'slate' {
  interface CustomTypes {
    Editor: MMBaseEditor & ReactEditor
    Element: CustomElement
    Text: CustomText
  }
}

const TextEditor = ({ setValue, value, read, edited }) => {
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])
  const editor = useMemo(
    () => withLinks(withHistory(withReact(createEditor()))),
    []
  )

  return (
    <Container $read={read}>
      <Slate
        editor={editor}
        initialValue={value}
        onChange={value => setValue(value)}
        key={edited}
      >
        <div style={{ border: 'none' }}>
          {!read && (
            <Toolbar>
              <MarkButton format="bold" icon="bold" />
              <MarkButton format="italic" icon="italic" />
              <MarkButton format="underline" icon="underline" />
              <MarkButton format="code" icon="code" />
              <BlockButton format="heading-one" icon="headingOne" />
              <BlockButton format="heading-two" icon="headingTwo" />
              <BlockButton format="numbered-list" icon="numbered-list" />
              <BlockButton format="bulleted-list" icon="bulleted-list" />
              <LinkButton />
            </Toolbar>
          )}
          <Editable
            style={{
              outline: 'none',
              padding: '5px 16px',
              border: read ? 'none' : '1px solid #ccc',
              minHeight: '150px',
              paddingTop: '15px',
            }}
            onKeyDown={event => {
              toggleKeyboardShortcut(event, editor)
            }}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            readOnly={read}
          />
        </div>
      </Slate>
    </Container>
  )
}

export default TextEditor
