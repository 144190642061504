import React from 'react'
import ReactDOM from 'react-dom'
import { useSlate } from 'slate-react'
import {
  isBlockActive,
  toggleBlock,
  isMarkActive,
  toggleMark,
  isLinkActive,
  wrapLink,
  unwrapLink,
} from './helpers'
import Bold from './icons/Bold.tsx'
import Italic from './icons/Italic.tsx'
import Underline from './icons/Underline.tsx'
import Code from './icons/Code.tsx'
import headingOne from './icons/HeadingOne.tsx'
import headingTwo from './icons/HeadingTwo.tsx'
import numbered from './icons/NumberList.tsx'
import bulleted from './icons/BulletedList.tsx'
import Link from './icons/Link.tsx'

const icons = {
  bold: Bold,
  italic: Italic,
  underline: Underline,
  code: Code,
  headingOne: headingOne,
  headingTwo: headingTwo,
  'numbered-list': numbered,
  'bulleted-list': bulleted,
  link: Link,
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<{
    active?: boolean
    onMouseDown?: (event: any) => void
  }>
>(({ ...props }, ref) => (
  <button
    style={{
      margin: '0px',
      border: 'none',
      background: 'none',
    }}
    {...props}
    ref={ref}
  ></button>
))

export const Icon = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ active?: boolean }>
>(({ ...props }, ref) => {
  const Icon = icons[`${props.children}`]
  return (
    <div ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
      <Icon active={props.active} />
    </div>
  )
})

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

export const Toolbar = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren
>(({ ...props }, ref) => (
  <div
    style={{
      width: '100%',
      height: '30px',
      display: 'flex',
    }}
    {...props}
    ref={ref}
  />
))

export const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      <Icon active={isBlockActive(editor, format)}>{icon}</Icon>
    </Button>
  )
}

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon active={isMarkActive(editor, format)}>{icon}</Icon>
    </Button>
  )
}

export const LinkButton = () => {
  const editor = useSlate()
  return (
    <Button
      onMouseDown={event => {
        event.preventDefault()
        if (isLinkActive(editor)) {
          unwrapLink(editor)
          return
        }
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        wrapLink(editor, url)
      }}
    >
      <Icon active={isLinkActive(editor)}>link</Icon>
    </Button>
  )
}
