import styled from 'styled-components'

export const RemoveBtn = styled.button`
  border: none;
  width: 25px;
  background: url('/assets/img/custom/condition-remove-red.svg') no-repeat;
  &:disabled {
    opacity: 0.5;
  }
`

export const AddBtn = styled.button`
  border: none;
  width: 25px;
  background: url('/assets/img/custom/condition-add.svg') no-repeat;
`
