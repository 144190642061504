import { getDatesArray } from '@mm/utils/src/constants/ranges'
import { differenceInDays, sub, endOfDay, parse, isValid } from 'date-fns'
import moment from 'moment'
import { toYesterdayNames } from '../DateSelectCard'

const datesArray = getDatesArray()

type PeriodMethods = {
  [k: string]: (
    startDate: Date,
    endDate: Date,
    lastDays?: number,
    untilToday?: boolean
  ) => { from: Date; to: Date }
}

const previousPeriodFormatDates = datesArray.reduce(
  (allDates: PeriodMethods, date) => {
    allDates[date.name] = (startDate: Date, endDate: Date) => ({
      from: new Date(date.from!),
      to: new Date(date.to!),
    })
    return allDates
  },
  {}
)

export const calculateLastXDayPeriod = (
  lastDays: string,
  untilToday: boolean,
  baseFrom = new Date()
) => {
  const xDays = Number(lastDays) - 1
  if (typeof xDays !== 'number') return undefined

  const from = sub(endOfDay(baseFrom), {
    days: untilToday ? 0 : 1,
  })

  const lastDaysDate = sub(from, {
    days: Number(lastDays) - 1,
  })

  const newState = {
    startDate: moment(lastDaysDate),
    endDate: moment(from),
    lastDays,
    name: 'Last days',
    untilToday,
  }

  return newState
}

export const transformStringToDate = (date: string | Date) => {
  if (typeof date === 'string') {
    const isoDate = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date())
    if (isValid(isoDate)) {
      return isoDate
    } else {
      const nonIsoDate = parse(date, 'MM-dd-yyyy', new Date())
      return nonIsoDate
    }
  }
  return date
}
const previousPeriods: PeriodMethods = {
  'Previous period': (startDate: Date, endDate: Date) => {
    const daysDifference = differenceInDays(endDate, startDate)
    return {
      from: sub(startDate, { days: daysDifference + 1 }),
      to: sub(startDate, { days: 1 }),
    }
  },
  ...previousPeriodFormatDates,
  Customize: (startDate: Date, endDate: Date) => ({
    from: new Date(startDate),
    to: new Date(endDate),
  }),
  'Last days': (
    _startDate: Date,
    _endDate: Date,
    lastDays?: number,
    untilToday?: boolean
  ) => {
    if (!lastDays) {
      return {
        from: new Date(),
        to: new Date(),
      }
    }

    const LastDaysPeriod = calculateLastXDayPeriod(
      lastDays.toString(),
      !!untilToday
    )
    return {
      from: LastDaysPeriod?.startDate.toDate() ?? new Date(),
      to: LastDaysPeriod?.endDate.toDate() ?? new Date(),
    }
  },
}
export const calculateDate = (
  period: string,
  startDate: Date,
  endDate: Date,
  lastDays?: number,
  untilToday?: boolean,
  trimDates = true
) => {
  const calculatedDate = previousPeriods[period](
    startDate,
    endDate,
    lastDays,
    untilToday
  )

  if (untilToday === false && toYesterdayNames.includes(period)) {
    calculatedDate.to = sub(new Date(), { days: 1 })

    if (calculatedDate.to.getTime() < calculatedDate.from.getTime()) {
      calculatedDate.to = calculatedDate.from
    }
    return calculatedDate
  }

  if (
    trimDates &&
    period !== 'Customize' &&
    period !== 'Last days' &&
    calculatedDate.to &&
    calculatedDate.to.getTime() > new Date().getTime()
  ) {
    calculatedDate.to = new Date()
  }

  return calculatedDate
}

export function toIsoString(date?: Date | string) {
  if (!date) return new Date().toISOString()
  if (typeof date === 'string') return new Date(date).toISOString()
  return date.toISOString()
}
