import { useQuery } from '@tanstack/react-query'

import tablesApi from '..'

export const GET_TABLES_VIEW_BY_ID = 'Tables_GetView_by_id'

export const getTableViewById = async (
  tableId?: string | null,
  viewId?: string | null,
  shareable?: string,
  pdf?: string
) => {
  if (!tableId || !viewId) return null

  if (shareable || pdf) {
    const param = shareable ? `shareable=${shareable}` : `pdf=${pdf}`
    return tablesApi
      .get(`/shareable/${tableId}/views/${viewId}?${param}`)
      .then(response => response.data)
  }

  return tablesApi
    .get(`/${tableId}/views/${viewId}`)
    .then(response => response.data)
}
const useGetTableViewById = (
  tableId?: string | null,
  viewId?: string | null,
  shareable?: string,
  pdf?: string,
  queryOptions?: any
) =>
  useQuery(
    [GET_TABLES_VIEW_BY_ID, tableId, viewId],
    () => getTableViewById(tableId, viewId, shareable, pdf),
    {
      retry: false,
      staleTime: 1000 * 60 * 60, // 1 hour
      ...queryOptions,
    }
  )

export default useGetTableViewById
