import { useQuery } from '@tanstack/react-query'

import tablesApi from '../'

export const GET_ONE_TABLE_QUERY_KEY = 'Tables_GetOneUserTable'

export const getOneUserTable = async (
  id: string | null,
  shareable?: string,
  pdf?: string
) => {
  if (!id) return null

  if (shareable || pdf) {
    const param = shareable ? `shareable=${shareable}` : `pdf=${pdf}`
    return tablesApi
      .get(`/shareable/${id}?${param}`)
      .then(response => response.data)
  }

  return tablesApi.get(`/${id}`).then(response => response.data)
}

const useGetOneUserTable = (
  id: string | null,
  shareable?: string,
  pdf?: string
) =>
  useQuery(
    [GET_ONE_TABLE_QUERY_KEY, id],
    () => getOneUserTable(id, shareable, pdf),
    {
      staleTime: 1000 * 60 * 60, // 1 hour
    }
  )

export default useGetOneUserTable
