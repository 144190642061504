/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  HeaderGrids,
  MainDataContainer,
  PowerTableContainer,
  RowGrid,
  RowHeaderContainer,
  RowHeaderGrids,
} from '../style'
import React from 'react'
import { Row } from './Row'
import {
  FixedSizeGrid as Grid,
  VariableSizeGrid as HeadersGrid,
} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import {
  NestedColumn,
  PowertableStructure,
  rawBreakpoint,
  rawValues,
  TableData,
} from '../types'
import { HeadersRow, MetricHeaderRow } from './Header'
import { PowertableRowData } from './DataTemplates'

const spaceByTemplate: { [k: string]: number } = {
  image: 120,
  'title-description': 80,
}

const widthByTemplate: { [k: string]: number } = {
  image: 400,
  'title-description': 300,
}
export class Table implements PowertableStructure {
  public rows: Row[]
  public headers: (HeadersRow | MetricHeaderRow)[]
  public maxRowSpan!: number
  public headerRefs: React.RefObject<any>[]
  public rowHeaderRefs: React.RefObject<any>[]
  public rowTitles: { name: string; id: string }[][]
  public data: TableData
  public rowHeight: { [k: string]: number }
  public columnWidth: { [k: string]: number }
  public additionalConfiguration?: { [k: string]: string }
  public maxColumnSize: number
  public columnTitles: string[][][]

  constructor(data: TableData) {
    this.rows = []
    this.headers = []
    this.headerRefs = []
    this.rowHeaderRefs = []
    this.data = data
    this.rowTitles = []
    this.columnWidth = {}
    this.columnTitles = []
    this.maxColumnSize = 0
    this.rowHeight = {}
    this.additionalConfiguration = data.additionalConfiguration

    this.formatColumnHeaders(data, [], 0, [])

    this.calculateRowSpan(data.rowData)

    this.createAxisReferences(data.metrics)

    this.populateBody()
  }

  private populateBody() {
    const formatMetricRow: string[] = []
    this.data.metrics.forEach(metric => {
      for (let i = 0; i < this.maxColumnSize; i++) {
        formatMetricRow.push(metric.type)
      }
    })

    for (const subRow of this.data.rowData) {
      this.populateRows(subRow, [], formatMetricRow)
    }
  }

  private createAxisReferences(metrics: { label: string; type: string }[]) {
    this.columnTitles.forEach(columnHeader => {
      const columnRef = React.createRef()
      this.headerRefs.push(columnRef)
      this.headers.push(new HeadersRow(columnHeader, metrics))
    })
    const metricColumnRef = React.createRef()
    this.headerRefs.push(metricColumnRef)
    this.headers.push(new MetricHeaderRow(metrics))
  }

  private formatColumnHeaders(
    currentData: TableData | NestedColumn,
    parents: string[],
    level: number,
    parentSpace: string[]
  ) {
    if (!currentData.columns.length) {
      this.maxColumnSize += 1
      parents.forEach(parent => (this.columnWidth[parent] += 1))
    } else {
      if (this.columnTitles.length - 1 < level) {
        this.columnTitles.push([])
      }
      this.columnTitles[level].push(parentSpace)
      currentData.columns.forEach(column => {
        parentSpace.push(column.name)
        const childSpace: string[] = []
        this.columnWidth[column.name] = 0
        this.formatColumnHeaders(
          column,
          [...parents, column.name],
          level + 1,
          childSpace
        )
      })
    }
  }

  private getCurrentName = (rowNames: string[]) => {
    return rowNames.reduce((name, rowName) => {
      name += `${rowName}-`
      return name
    }, '')
  }

  private calculateRowSpan(rowData: (rawBreakpoint | rawValues)[]) {
    this.maxRowSpan = rowData.reduce((size, row) => {
      const goDeeper = (
        currentRow: rawBreakpoint | rawValues,
        parents: string[]
      ) => {
        if (currentRow.type === 'breakdown') {
          const currentName = this.getCurrentName([...parents, currentRow.name])
          if (!this.rowHeight[currentName]) this.rowHeight[currentName] = 0
          currentRow.values.forEach(value =>
            goDeeper(value, [...parents, currentRow.name])
          )
        } else {
          parents.forEach((_parent, idx) => {
            const currentName = this.getCurrentName(parents.slice(0, idx + 1))
            this.rowHeight[currentName] += 1
          })
          size += 1
        }
      }
      goDeeper(row, [])
      return size
    }, 0)
  }

  private populateRows(
    data: rawBreakpoint | rawValues,
    parentNames: string[] = [],
    formatMetricRow: string[]
  ) {
    if (data.type === 'breakdown') {
      const breakpoint = data as rawBreakpoint
      for (const subRow of breakpoint.values) {
        this.populateRows(subRow, [...parentNames, data.name], formatMetricRow)
      }
    } else {
      parentNames.push(data.name)
      parentNames.forEach((name, idx) => {
        if (!this.rowTitles[idx]) {
          this.rowTitles[idx] = []
        }
        const currentName = this.getCurrentName(parentNames.slice(0, idx + 1))
        if (
          this.rowTitles[idx][this.rowTitles[idx].length - 1]?.id !==
            currentName ||
          idx === Object.keys(this.data.rows).length - 1
        ) {
          this.rowTitles[idx].push({
            id: currentName,
            name,
          })
        }
      })
      const rowRef = React.createRef()
      this.rowHeaderRefs.push(rowRef)
      this.rows.push(new Row(data, parentNames, formatMetricRow))
    }
  }

  private Cell({
    columnIndex,
    rowIndex,
    style,
  }: {
    columnIndex: number
    rowIndex: number
    style: any
  }) {
    return (
      <div style={style}>{this.rows[rowIndex]?.render(columnIndex) || ''}</div>
    )
  }

  private RowHeader({
    rowIndex,
    style,
    data,
  }: {
    rowIndex: number
    style: any
    data: { index: number; transform: boolean }
  }) {
    const templateType = this.additionalConfiguration?.template
    const rowSize = (templateType && spaceByTemplate[templateType]) || 35
    return (
      <div style={style}>
        <RowHeaderContainer
          width={
            (rowSize *
              this.rowHeight[this.rowTitles[data.index][rowIndex]?.id] || 1) - 5
          }
          className={data.transform ? 'secondary' : 'primary'}
        >
          <PowertableRowData
            value={this.rowTitles[data.index][rowIndex]?.name}
            additionalConfiguration={this.additionalConfiguration}
            templateType={templateType}
            lastColumn={data.index === this.rowTitles.length - 1}
          ></PowertableRowData>
        </RowHeaderContainer>
      </div>
    )
  }

  private Header({
    columnIndex,
    style,
    data,
  }: {
    columnIndex: number
    style: any
    data: number
  }) {
    return (
      <div style={style}>{this.headers[data]?.render(columnIndex) || ''}</div>
    )
  }

  render(renderKey: any) {
    const columnWidthCalculator = (header: any, idx: number, index: number) => {
      if (this.maxColumnSize === 1) return 150
      if (this.headers.length - 1 > idx)
        return this.columnWidth[header.subHeaders[index].value] * 100
      return this.maxColumnSize * 100
    }

    const templateType = this.additionalConfiguration?.template || 'none'
    const rowSize = spaceByTemplate[templateType] || 35
    const mainRowHeaderSize = widthByTemplate[templateType] || 200
    return (
      <>
        <PowerTableContainer
          key={renderKey}
          rowAmount={this.maxRowSpan}
          rowTitleNum={Object.keys(this.data.rows).length}
          headers={this.headers.length}
          mainRowHeaderSize={mainRowHeaderSize}
        >
          <HeaderGrids headers={this.headers.length}>
            <AutoSizer>
              {({ width }) => (
                <>
                  {this.headers.map((header, idx) => {
                    return (
                      <HeadersGrid
                        key={idx}
                        ref={this.headerRefs[idx]}
                        columnCount={header.subHeaders.length}
                        columnWidth={index =>
                          columnWidthCalculator(header, idx, index)
                        }
                        height={this.headers.length - 1 > idx ? 40 : 80}
                        rowCount={1}
                        rowHeight={() =>
                          this.headers.length - 1 > idx ? 40 : 80
                        }
                        width={width || 0}
                        itemData={idx}
                        style={{
                          overflowX: 'hidden',
                          overflowY: 'hidden',
                        }}
                      >
                        {this.Header.bind(this)}
                      </HeadersGrid>
                    )
                  })}
                </>
              )}
            </AutoSizer>
          </HeaderGrids>

          <RowHeaderGrids>
            <AutoSizer>
              {({ height, width }) => (
                <RowGrid height={height || 0} width={width || 0}>
                  <>
                    {Object.keys(this.data.rows).map((rowCategory, idx) => {
                      return (
                        <HeadersGrid
                          key={idx}
                          ref={this.rowHeaderRefs[idx]}
                          columnCount={1}
                          columnWidth={() =>
                            idx + 1 !== this.rowTitles.length
                              ? 45
                              : mainRowHeaderSize
                          }
                          height={
                            height! *
                            (idx + 1 !== this.rowTitles.length ? 1.03 : 1)
                          }
                          rowCount={this.maxRowSpan}
                          rowHeight={index =>
                            rowSize *
                            (this.rowHeight[this.rowTitles[idx][index]?.id] ||
                              1)
                          }
                          itemData={{
                            index: idx,
                            transform: idx + 1 !== this.rowTitles.length,
                            rowCategory,
                          }}
                          width={
                            idx + 1 !== this.rowTitles.length
                              ? 40
                              : mainRowHeaderSize
                          }
                          style={{
                            overflow: 'hidden',
                            top:
                              idx < Object.keys(this.data.rows).length - 1
                                ? '-15px'
                                : '',
                            marginLeft:
                              idx === this.rowTitles.length - 1 ? '10px' : '',
                          }}
                        >
                          {this.RowHeader.bind(this)}
                        </HeadersGrid>
                      )
                    })}
                  </>
                </RowGrid>
              )}
            </AutoSizer>
          </RowHeaderGrids>

          <MainDataContainer>
            <AutoSizer>
              {({ height, width }) => (
                <Grid
                  className="scrollbar-color scrollbar-vertical-color powertable-remove-overflow"
                  columnCount={this.maxColumnSize * this.data.metrics.length}
                  columnWidth={this.maxColumnSize === 1 ? 150 : 100}
                  height={height || 0}
                  rowCount={this.maxRowSpan}
                  rowHeight={rowSize}
                  width={width || 0}
                  onScroll={({ scrollLeft, scrollTop }) => {
                    this.headerRefs.forEach(headerRef => {
                      if (headerRef.current) {
                        // @ts-ignore
                        headerRef?.current!.scrollTo({ scrollLeft })
                      }
                    })

                    this.rowHeaderRefs.forEach(rowRef => {
                      if (rowRef.current) {
                        // @ts-ignore
                        rowRef?.current!.scrollTo({ scrollTop })
                      }
                    })
                  }}
                >
                  {this.Cell.bind(this)}
                </Grid>
              )}
            </AutoSizer>
          </MainDataContainer>
        </PowerTableContainer>
      </>
    )
  }
}
