import styled from 'styled-components'

export const IncludeTodayLabel = styled('label')`
  margin-top: -10px;
  border: none !important;
  border-left: 1px solid #dedfeb !important;
  border-radius: 0px !important;
  padding: 0 12px !important;
  user-select: none;
`
