import React from 'react'
import { HeaderMetric, HeaderRow, HeaderTitle, TableHeader } from '../style'
import { PowertableStructure } from '../types'

export class HeadersRow implements PowertableStructure {
  public subHeaders: Header[]
  public column: string[][]
  public metrics: { label: string; type: string }[]

  constructor(
    column: string[][],
    metrics: {
      label: string
      type: string
      sortEnabled?: boolean
      sortDirection?: 'asc' | 'desc'
    }[]
  ) {
    this.subHeaders = []
    this.column = column
    this.metrics = metrics
    this.populateHeader(metrics)
  }

  private populateHeader(metrics: { label: string; type: string }[]) {
    metrics.forEach(() => {
      this.column.forEach(headerGroup => {
        headerGroup.forEach((header, idx) => {
          const position =
            idx === 0
              ? 'first'
              : idx === headerGroup.length - 1
                ? 'last'
                : 'middle'
          this.subHeaders.push(new Header(header, position))
        })
      })
    })
  }

  render(idx: number) {
    return <HeaderRow>{this.subHeaders[idx]?.render() || ''}</HeaderRow>
  }
}

export class MetricHeaderRow implements PowertableStructure {
  public subHeaders: MetricHeader[]
  constructor(metrics: { label: string }[]) {
    this.subHeaders = []
    this.populateHeader(metrics)
  }

  private populateHeader(metrics: { label: string }[]) {
    metrics.forEach(metric => {
      this.subHeaders.push(new MetricHeader(metric))
    })
  }

  render(idx: number) {
    return (
      <HeaderMetric title={this.subHeaders[idx].value}>
        {this.subHeaders[idx]?.render() || ''}
      </HeaderMetric>
    )
  }
}

export class MetricHeader implements PowertableStructure {
  public value: string
  constructor(value: {
    label: string
    sortEnabled?: boolean
    sortDirection?: 'asc' | 'desc'
  }) {
    const arrow = value.sortEnabled
      ? value.sortDirection === 'asc'
        ? '▲'
        : '▼'
      : ''

    this.value = `${value.label} ${arrow}`
  }
  render() {
    return <div style={{ fontSize: '11px' }}>{this.value}</div>
  }
}

export class Header implements PowertableStructure {
  public value: string
  public position: string
  constructor(value: string, position: string) {
    this.value = value
    this.position = position
  }

  render() {
    return (
      <TableHeader className={`title ${this.position}`}>
        <HeaderTitle title={this.value}>{this.value}</HeaderTitle>
      </TableHeader>
    )
  }
}
