//@ts-nocheck
import useGetPowertileData from '@mm/api/src/powertiles/requests/useGetPowertilesData'
import React from 'react'
import Loader from '../Loader'
import { formatMetric } from '../Powertable/utils'
import ResizableText from './ResizableText'
import TooltipMenu from './TooltipMenu'
import WidgetIcon from '../WidgetIcon'

const PowerTile = ({
  dateChosen,
  onDelete,
  onEdit,
  onDuplicate,
  onCompare,
  handleToggleTooltip,
  hideActions,
  showTooltip,
  shareable,
  widget,
  pdf,
}) => {
  const {
    data: response,
    isFetching,
    error,
  } = useGetPowertileData({
    id: widget.id,
    shareable: shareable,
    pdf,
    dateChosen,
  })

  const compareParams =
    error || isFetching
      ? {}
      : {
          onCompare: () => onCompare(!response.data.previousPeriod),
          isCompared: !!response.data?.previousPeriod,
        }

  return (
    <>
      {isFetching ? (
        <Loader style={{ margin: 'auto' }} />
      ) : error ? (
        <ResizableText
          error={error.response.data.message}
          altMessageColor={'red'}
          hideActions={hideActions}
          handleToggleTooltip={handleToggleTooltip}
        />
      ) : (
        <>
          {response.show_icons && <WidgetIcon sources={[response.source]} />}
          <ResizableText
            formatedValue={formatMetric(
              response.metric || '',
              response.data.current,
              response.decimals
            )}
            currentValue={response.data.current}
            previousDataString={response.data.previousDataString}
            widgetId={widget.id}
            previousPeriod={response.data?.previousPeriod}
            formatedPreviousPeriod={formatMetric(
              response.metric,
              response.data?.previousPeriod,
              response.decimals
            )}
            metric={
              response.name
                ? { name: response.name, type: response.metric }
                : {
                    name: response.label[response.metric],
                    type: response.metric,
                  }
            }
            hideActions={hideActions}
            dateString={response.data.dateString ?? response.range}
            altMessageColor={''}
            handleToggleTooltip={handleToggleTooltip}
          />
        </>
      )}
      {hideActions && isFetching === false && (
        <TooltipMenu
          show={showTooltip}
          toggle={handleToggleTooltip}
          onDelete={onDelete}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          {...compareParams}
        />
      )}
    </>
  )
}

export default PowerTile
