import React, { Component } from 'react'
// @ts-ignore
import 'react-tagsinput/react-tagsinput.css'
import { Tooltip } from 'reactstrap'
import HelpIcon from '../../Icons/HelpIcon'

class SimpleInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      onFocus: '',
      onBlur: '',
      showHelpMessage: false,
    }
  }

  handleFocus = () => {
    this.setState({ onFocus: 'react-tagsinput--focused' })
  }

  handleBlur = () => {
    this.setState({ onFocus: '' })
  }

  render() {
    const { label, error, type, autoFocus, inputstyle, helpTooltip, name } =
      this.props
    const { showHelpMessage } = this.state

    return (
      <div className="forms-inputs">
        {label && (
          <div style={{ display: 'flex' }}>
            <label>{this.props.label}</label>
            {helpTooltip && (
              <div style={{ marginLeft: '10px' }}>
                <Tooltip
                  placement={'bottom'}
                  isOpen={showHelpMessage}
                  style={{ zIndex: 1000 }}
                  target={`input-${name}`}
                  toggle={() =>
                    this.setState({
                      showHelpMessage: !showHelpMessage,
                    })
                  }
                >
                  {helpTooltip}
                </Tooltip>
                <HelpIcon id={`input-${name}`} />
              </div>
            )}
          </div>
        )}

        <div
          className={'react-tagsinput ' + this.state.onFocus}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          style={{ padding: '5px' }}
        >
          <span className={type}>
            <input
              {...this.props}
              style={inputstyle}
              aria-autocomplete="both"
              aria-haspopup="false"
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              autoFocus={autoFocus}
              spellCheck="false"
              className="react-tagsinput-input"
            />
            <div className="search-icon" />
          </span>
        </div>
        {error && (
          <div className="error-message" style={{ opacity: error ? '1' : '0' }}>
            <span>{error}</span>
          </div>
        )}
      </div>
    )
  }
}
export default SimpleInput
