import styled from 'styled-components'

export const StyledInput = styled.input`
  outline: none;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  cursor: pointer;
`
export const InputContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
`
