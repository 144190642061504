import styled from 'styled-components'
import { Card, CardBody, CardTitle } from 'reactstrap'

export const StyledCard = styled(Card)`
  height: 100%;
  width: 100%;
  border-radius: 0.75rem;
  border: initial;
  background: white;
  box-shadow:
    0 1px 15px rgba(0, 0, 0, 0.04),
    0 1px 6px rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  top: 0;
  padding-top: 1.25rem;
  overflow: auto;
  border: 1px solid #d3d3d3;
`
export const StyledViewOptions = styled('div')`
  visibility: hidden;
  align-self: flex-end;
  padding: 7px 10px 7px 5px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`

export const StyledCardBody = styled(CardBody)`
  padding: 1.75rem;
  overflow: inherit;
  webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  display: flex !important;
  padding: 1.25rem;
  width: 100%;
  height: 100%;
  &:hover ${StyledViewOptions} {
    visibility: visible;
  }
`
export const StyledDots = styled('div')`
  width: 6px;
  height: 16px;
  background-size: contain;
  cursor: pointer;
`
export const StyledCardTitle = styled(CardTitle)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
`

export const StyledChartTitle = styled('h2')`
  color: #1b215c;
  font-size: 24px;
  margin: 0;
  font-weight: 600;
`

export const ErrorMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: red;
`

export const BarAndLineLabelOversize = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'labels'
    'graph';
  grid-template-columns: 100%;
  grid-template-rows: 11% 88%;
  .bar-line-legend {
    max-height: 80px;
  }
  .line-bar-chart-container {
    margin-top: 15px;
    height: 95%;
    grid-area: 'graph';
  }
  div {
    ul {
      justify-content: center;
      flex-direction: row !important;
      flex-wrap: wrap;
    }
    p {
      width: auto !important;
    }
  }
`

export const GraphLabelOversize = styled.div`
  display: grid;
  grid-template-areas: 'graph labels';
  grid-template-columns: 68% 28%;
  gap: 25px;
  height: 100%;
  .pie-chart-container {
    height: 100%;
  }
  @media (max-width: 768px) {
    grid-template-areas:
      'labels'
      'graph';
    grid-template-columns: 100%;
    grid-template-rows: 28% 68%;
  }
`

export const LabelsSection = styled.div`
  grid-area: labels;
  overflow-y: scroll;
  margin-right: 20px;
`

export const LegendSection = styled.div`
  grid-area: labels;
  overflow-y: scroll;
  margin-right: 20px;
  li span {
    width: 36px;
    height: 12px;
    display: inline-block;
    margin: 0 5px 8px 0;
    vertical-align: -9.4px;
  }
  ul {
    list-style: none;
    font: 12px;
    white-space: nowrap;
  }
  li {
    cursor: pointer;
    text-align: left;
  }
`
export const TitleContainer = styled.div`
  text-align: center;
  display: grid;
  justify-content: center;
  h2 {
    top: 0px !important;
  }
`

export const DateLabel = styled.span`
  position: relative;
  text-align: center;
  font-size: 14px;
`

export const TitleSection = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 98% 2%;
`
