import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import tablesApi from '../'
import { ClientData } from '../../../customComponents/forms/AddNewTableModal/ClientModal/ClientConfiguration'

const UPDATE_CLIENT_KEY = 'Tables_UpdateAllClients'

const updateAllClients = async ({
  tableId,
  data,
}: {
  tableId: string
  data: ClientData[]
}) => {
  if (!tableId) throw new Error('Missing Table ID')
  return tablesApi
    .put(`/${tableId}/clients`, data)
    .then(response => response.data)
}

const useUpdateAllClients = (
  queryOptions: Omit<
    UseMutationOptions<any, unknown, {} | undefined, unknown>,
    'mutationFn'
  >
) => {
  return useMutation(updateAllClients, {
    mutationKey: [UPDATE_CLIENT_KEY],
    ...queryOptions,
  })
}

export default useUpdateAllClients
