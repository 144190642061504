// @ts-nocheck

import React, { Component } from 'react'
import { Table } from 'reactstrap'
import {
  capitalizeString,
  formatDuration,
  isHubspotDuration,
  formatGoogleAnalyticsValue,
  formatGoogleValue,
  formatLinkedinValue,
  formatMetaValue,
  formatValue,
  formatCalculatedMetric,
  formatTiktokValue,
} from '@mm/utils/src/helpers/Utils'
import WarningIconTooltip from '../CarouselItem/WarningIconTooltip'
import WidgetIcon from '../WidgetIcon'
import { tableIconSource } from '../WidgetIcon/utils'

// COMPONENTS
import Loader from '../Loader'
import Tooltip from './Tooltip'

import {
  StyledCard,
  StyledCardBody,
  StyledCardTitle,
  StyledTableTitle,
  StyledDots,
  StyledViewOptions,
  StyledResponsiveTable,
  StyledTh,
  TableData,
} from './style'
import { isCalculatedMetric } from '../../helpers/calculatedMetrics'

const parseError = (data): string | undefined => {
  const rows = data?.rows

  if (rows) {
    for (const row of rows) {
      for (const cell of row) {
        if (cell.error) return cell.error
      }
    }
  }

  return undefined
}

class DashboardTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTooltip: false,
      hideActions: import.meta.env.VITE_APP_IS_PUBLIC === 'false',
    }
    this.tableRef = React.createRef()
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
  }

  handleToggleTooltip = () => {
    this.setState({ showTooltip: !this.state.showTooltip })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== true && this.props.loading) {
      window.scrollTo({
        top: this.tableRef.current.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  render() {
    const {
      name,
      data = {},
      onDuplicate,
      onEdit,
      onDelete,
      loading,
      metrics,
      id,
      widget,
    } = this.props
    const { showTooltip, hideActions } = this.state

    const error = parseError(data)

    return (
      <>
        <div ref={this.tableRef}></div>
        <StyledCard id={`table-${id}`}>
          {widget.dashboard_widgets.show_icons && (
            <WidgetIcon sources={tableIconSource(metrics)} />
          )}
          <StyledCardBody>
            <StyledCardTitle>
              <StyledTableTitle>{name}</StyledTableTitle>
              {hideActions && (
                <>
                  <StyledViewOptions
                    onClick={this.handleToggleTooltip}
                    className="view-options"
                    onMouseDown={e => e.stopPropagation()}
                    onMouseMove={e => e.stopPropagation()}
                  >
                    <StyledDots className="dots"></StyledDots>
                  </StyledViewOptions>

                  <Tooltip
                    show={showTooltip}
                    toggle={this.handleToggleTooltip}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    loading={loading}
                  />
                </>
              )}
            </StyledCardTitle>

            <StyledResponsiveTable className="scrollbar-color">
              <WarningIconTooltip error={error} elementId={id} />

              <Table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    {data.rows && data.rows.length ? (
                      data.headings.map((heading, index) => (
                        <StyledTh key={index} style={{ minWidth: '80px' }}>
                          {heading}
                        </StyledTh>
                      ))
                    ) : (
                      <th>Metrics</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={!data.headings ? 1 : data.headings.length}>
                        <Loader />
                      </td>
                    </tr>
                  ) : data.rows && data.rows.length ? (
                    data.rows.map((row, i) => {
                      return (
                        <tr key={i}>
                          {row.map((cell, ii) => {
                            if (ii) {
                              const metric = metrics[i]
                              let value
                              if (
                                isCalculatedMetric(metric?.type) &&
                                metric.numberType !== 'default'
                              ) {
                                value = formatCalculatedMetric(
                                  cell.value,
                                  metric
                                )
                              } else if (metric.type === 'AM') {
                                value = formatValue(cell.value, metric)
                              } else if (isHubspotDuration(metric)) {
                                value = formatDuration(cell.value)
                              } else if (metric.source === 'META') {
                                value = formatMetaValue(cell.value, metric)
                              } else if (metric.source === 'GOOGLE') {
                                value = formatGoogleValue(cell.value, metric)
                              } else if (metric.source === 'GOOGLE_ANALYTICS') {
                                value = formatGoogleAnalyticsValue(
                                  cell.value,
                                  metric
                                )
                              } else if (
                                isCalculatedMetric(metric?.type) &&
                                cell.source === 'META'
                              ) {
                                const formatMetric = { ...metric }
                                formatMetric.type = cell.numerator
                                value = formatMetaValue(
                                  cell.value,
                                  formatMetric
                                )
                              } else if (
                                isCalculatedMetric(metric?.type) &&
                                cell.source === 'GOOGLE'
                              ) {
                                const formatMetric = { ...metric }
                                formatMetric.type = cell.numerator
                                value = formatGoogleValue(
                                  cell.value,
                                  formatMetric
                                )
                              } else if (
                                isCalculatedMetric(metric?.type) &&
                                cell.source === 'GOOGLE_ANALYTICS'
                              ) {
                                const formatMetric = { ...metric }
                                formatMetric.type = cell.numerator
                                value = formatGoogleAnalyticsValue(
                                  cell.value,
                                  formatMetric
                                )
                              } else if (metric.source === 'LINKEDIN') {
                                value = formatLinkedinValue(cell.value, metric)
                              } else if (metric.source === 'TIKTOK') {
                                value = formatTiktokValue(cell.value, metric)
                              } else {
                                value = cell.value
                              }
                              return (
                                <TableData
                                  key={ii}
                                  title={cell.warning || cell.error || ''}
                                  hoverMessage={cell.warning || cell.error}
                                  warning={cell.warning}
                                >
                                  {value}
                                </TableData>
                              )
                            } else {
                              return (
                                <th
                                  key={ii}
                                  scope="row"
                                  style={{
                                    fontWeight: '400',
                                    textAlign: 'left',
                                  }}
                                >
                                  {capitalizeString(cell)}
                                </th>
                              )
                            }
                          })}
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <th>There are no metrics selected for this table!</th>
                    </tr>
                  )}
                </tbody>
              </Table>
            </StyledResponsiveTable>
          </StyledCardBody>
        </StyledCard>
      </>
    )
  }
}

export default DashboardTable
