import React, { useMemo, useState } from 'react'
import {
  Button,
  Popover,
  PopoverBody,
  FormGroup,
  PopoverHeader,
} from 'reactstrap'
import Select from 'react-select'
import { Dashboard } from '../SmartTable/types'

interface CopyMoveMenuProps {
  dashboards: Dashboard[]
  duplicateId: string
  dashboardIdDefault: number
  targetId: string
  type: string
  onDuplicateMove: (
    data: null | {
      duplicateId: string
      dashboardId: number
      targetId: string
      type: string
    }
  ) => void
  onClose: () => void
  open: boolean
}

const CopyMoveMenu: React.FC<CopyMoveMenuProps> = (
  props: CopyMoveMenuProps
) => {
  const popoverOpen = props.open
  const [dashboardId, setDashboardId] = useState<number>(
    props.dashboardIdDefault
  )
  const selectDashboard = useMemo(() => {
    const selectedDashboard = props.dashboards.find(d => d.id === dashboardId)
    if (!selectedDashboard) return null

    return {
      id: selectedDashboard.id,
      label: selectedDashboard.name,
      value: selectedDashboard.id,
    }
  }, [props.dashboards, dashboardId])

  if (!props.open || !props.targetId || !props.type || !props.dashboards) {
    return <></>
  }

  const handleOkClick = () => {
    if (!selectDashboard?.id) return

    props.onDuplicateMove({
      duplicateId: props.duplicateId,
      dashboardId: selectDashboard?.id,
      targetId: props.targetId,
      type: props.type,
    })
  }

  const target = document.getElementById(props.targetId)
  if (!target) {
    return <></>
  }

  return (
    <Popover
      placement="bottom"
      trigger="legacy"
      isOpen={popoverOpen}
      target={props.targetId}
      toggle={props.onClose}
      style={{ padding: 20, width: 250 }}
    >
      <PopoverHeader>Copy widget to </PopoverHeader>
      <PopoverBody>
        <FormGroup>
          <Select
            name="selectWidget"
            options={props.dashboards.map(dashboard => {
              return {
                id: dashboard.id,
                label: dashboard.name,
                value: dashboard.id,
              }
            })}
            value={selectDashboard}
            onChange={(selected: any) => {
              setDashboardId(selected.value)
            }}
            placeholder="Choose a widget"
            className="react-select"
            classNamePrefix="react-select"
          />
        </FormGroup>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              width: '75px',
              marginRight: '5px',
            }}
            color="danger"
            onClick={props.onClose}
            size="sm"
          >
            Cancel
          </Button>
          <Button style={{ width: '75px' }} size="sm" onClick={handleOkClick}>
            Copy
          </Button>
        </div>
      </PopoverBody>
    </Popover>
  )
}

export default CopyMoveMenu
