import { QueryFunctionContext, useQuery } from '@tanstack/react-query'

import tablesApi from '../'

export const GET_TABLES_METRICS = 'Tables_GetMetrics'

export const getTableMetrics = async (id: string) => {
  if (!id) return []
  return tablesApi.get(`/${id}/metrics`).then(response => response.data)
}

const useGetTableMetrics = ({
  id,
  ...queryOptions
}: Partial<QueryFunctionContext> & {
  id: string
}) => {
  return useQuery([GET_TABLES_METRICS, id], () => getTableMetrics(id), {
    ...queryOptions,
    staleTime: 1000 * 60 * 60 * 6,
    cacheTime: 1000 * 60 * 60 * 1,
  })
}

export default useGetTableMetrics
