import { NotFound } from '@mm/ui'
import Loader from '@mm/ui/src/components/Loader'
import { useEffect, useMemo, useState } from 'react'
import { fetchDashboardData, uptadeShareable } from '../api'
import GridLayoutComponent from '@mm/ui/src/components/GridLayout/GridLayoutComponent.jsx'
import { WidgetLayout } from '@mm/ui/src/components/GridLayout/types'
import DateSelectCard from '@mm/ui/src/components/DateSelectCard'
import moment from 'moment'
import { getDatesArray } from '@mm/utils/src/constants/ranges'
import { DateChosen } from '@mm/ui/src/components/Forms/WidgetForms/widget'
import queryClient from '../api/reactQuery'
import DashboardExportComponent from '@mm/ui/src/components/GridLayout/DashboardExportComponent'
import DownloadIcon from '@mm/ui/src/components/svg/DownloadIcon.jsx'
import { calculateDate } from '@mm/ui/src/components/DateSelectCompareCard/utils'

interface Dashboard {
  title: string
  logoUrl: string
  error: boolean
  layout: WidgetLayout[]
  allowFilter: boolean
  dateFilters: any
  widgets: any[]
}

const DashboardContainer = () => {
  const datesArray = getDatesArray()

  const [title, setTitle] = useState('Shared Dashboard')
  const [dateChosen, setDateChosen] = useState<DateChosen | null>({
    dateFilterFrom: '',
    dateFilterTo: '',
    dateFilterRange: '',
    dateFilterLastDays: '',
    dateFilterUntilToday: false,
  })
  const [allowFilter, setAllowFilter] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [widgets, setWidgets] = useState<any[]>([])
  const [error, setError] = useState(false)
  const [layout, setLayout] = useState<WidgetLayout[]>([])
  const [isExporting, setIsExporting] = useState(false)

  const uuid = window.location.pathname.replace('/', '')

  const formatDateForDashboardApi = (date: any) => {
    return {
      dateFilterRange: date ? date.name : '',
      dateFilterFrom: date ? moment(date.from).format('YYYY-MM-DD') : '',
      dateFilterTo: date ? moment(date.to).format('YYYY-MM-DD') : '',
      dateFilterLastDays: date ? date.lastDays : '',
      dateFilterUntilToday: date ? date.untilToday : false,
    }
  }

  const handleDateChange = async (
    startDate: any,
    endDate: any,
    optionId: any,
    lastDays: any,
    untilToday: any
  ) => {
    //@ts-ignore
    const name = datesArray.find(item => item.id === optionId).name
    const from =
      startDate === null || startDate === undefined ? moment() : startDate
    const to = endDate === null || endDate === undefined ? moment() : endDate
    handleChangeDate({ name, from, to, lastDays, untilToday })
  }

  const handleChangeDate = async (date: any) => {
    try {
      setLoading(true)
      const bodyToUpdate = formatDateForDashboardApi(date)
      await uptadeShareable(uuid, bodyToUpdate)
      setDateChosen(bodyToUpdate)
      await queryClient.invalidateQueries()
      await fetchData()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const onRemoveDateChosen = async () => {
    try {
      setLoading(true)
      await uptadeShareable(uuid, {})
      setDateChosen(null)
      await queryClient.invalidateQueries()
      await fetchData()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  const handleExportPDF = () => {
    setIsExporting(true)
  }
  const handleExportPDFFalse = () => {
    setIsExporting(false)
  }

  const fetchData = async () => {
    setLoading(true)
    // @ts-ignore
    const data: Dashboard = await fetchDashboardData(uuid)
    if (data && !data.error) {
      let updatedDateFilterRange: any = {}

      if (data.dateFilters.dateFilterRange) {
        updatedDateFilterRange = calculateDate(
          data.dateFilters.dateFilterRange,
          data.dateFilters.dateFilterFrom,
          data.dateFilters.dateFilterTo,
          data.dateFilters.dateFilterLastDays,
          data.dateFilters.dateFilterUntilToday,
          false
        )
      }

      const updatedDateFilter = {
        ...data.dateFilters,
        dateFilterFrom: updatedDateFilterRange.from,
        dateFilterTo: updatedDateFilterRange.to,
      }
      setLayout(data.layout)
      setTitle(data.title)
      setLogoUrl(data.logoUrl)
      setDateChosen(updatedDateFilter)
      setAllowFilter(data.allowFilter)
      const allWidgets = data.widgets
      setWidgets(allWidgets)
    } else {
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    // @ts-ignore
    fetchData()
  }, [uuid])

  const calculatedDate = useMemo(
    () =>
      datesArray.find(
        existentDate => dateChosen?.dateFilterRange === existentDate?.name
      ),
    [dateChosen, datesArray]
  )

  return (
    <>
      {error ? (
        <NotFound isPublic={true} />
      ) : (
        <>
          <div className="container">
            <div className="header">
              <img alt="User Logo" src={logoUrl!} />
              <h1>{title}</h1>
            </div>
            <div className="separator"></div>
          </div>

          <div style={{ padding: '0 50px' }} className="shareable-container">
            {isLoading ? (
              <Loader style={{ height: '50vh' }} />
            ) : (
              <>
                <div className="main-view-container">
                  {allowFilter && (
                    <DateSelectCard
                      selectedDateId={calculatedDate?.id}
                      dateChosen={dateChosen}
                      name="dateChosen"
                      handleDateChange={handleDateChange}
                      error={''}
                      styles={''}
                      onRemoveDateChosen={onRemoveDateChosen}
                      enableLastDays={true}
                    />
                  )}
                  <div style={{ marginLeft: 'auto' }}>
                    <button
                      onClick={handleExportPDF}
                      className="btn add-btn btn-desktop"
                      disabled={!layout || layout.length === 0 || isExporting}
                    >
                      <DownloadIcon small />
                      Export to PDF
                    </button>
                  </div>
                </div>
                {isExporting && (
                  <DashboardExportComponent
                    id={uuid}
                    title={title}
                    handleExportPDF={handleExportPDFFalse}
                    publicLink={true}
                  />
                )}
                <GridLayoutComponent
                  widgets={widgets}
                  editLayout={false}
                  resizableDash={false}
                  layout={layout}
                  shareable={uuid}
                  isLoading={false}
                  dateChosen={dateChosen}
                  compactType={'null'}
                  pdf=""
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default DashboardContainer
