/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DateObject, Calendar } from 'react-multi-date-picker'
import { format } from 'date-fns'
import 'react-multi-date-picker/styles/layouts/prime.css'
import {
  PickerWrapper,
  ClearButton,
  ButtonsContainer,
  ValueInput,
} from './styles'

interface Props {
  value: string
  onChange: (value: string | null) => void
  onBlur: () => void
  onKeyDown?: (event: React.KeyboardEvent<any>) => void
  initiallyOpen?: boolean
  autoFocus?: boolean
  formatString?: string
  disabled?: boolean
}

export const DATE_FNS_FORMAT_YMD = 'yyyy-MM-dd'
export const DATE_FNS_FORMAT = 'MM/dd/yyyy'
const CALENDAR_FORMAT = 'MM/DD/YYYY'
const MONTH_YEAR_SEPARATOR = '|'

const SingleDatePicker: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  initiallyOpen = false,
  autoFocus = true,
  formatString,
  disabled,
}) => {
  const [open, setOpen] = useState(initiallyOpen)

  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const normalizedcalendarValue = useMemo(() => {
    if (!value || value === '-') return null
    const date = new Date(value)
    return format(date, DATE_FNS_FORMAT)
  }, [value])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      onBlur()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handlePickerChange = (date: DateObject) => {
    if (formatString) {
      onChange(format(date?.toDate(), formatString))
    } else {
      onChange(date?.toDate().toDateString())
    }
    setOpen(false)
    if (inputRef) inputRef.current?.focus()
  }

  const handleInputBlur = () => {
    if (!open) onBlur()
  }

  const handleClose = () => {
    setOpen(false)
    onBlur()
  }

  const openCalendar = () => {
    setOpen(true)
  }

  const clearValue = () => {
    onChange(null)
    setOpen(false)
  }

  return (
    <PickerWrapper
      className="single-date-picker"
      ref={wrapperRef}
      onKeyDown={onKeyDown}
    >
      <ValueInput
        disabled={disabled}
        ref={inputRef}
        onClick={openCalendar}
        value={value || '-'}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        readOnly
        onBlur={handleInputBlur}
      />
      {open && (
        <Calendar
          value={normalizedcalendarValue}
          format={CALENDAR_FORMAT}
          monthYearSeparator={MONTH_YEAR_SEPARATOR}
          onChange={handlePickerChange}
          onKeyDown={onKeyDown}
          showOtherDays
          highlightToday={false}
          style={{ margin: '10px 0', position: 'absolute' }}
          children={
            <ButtonsContainer>
              <ClearButton onClick={clearValue}>Clear</ClearButton>{' '}
              {<ClearButton onClick={handleClose}>Close</ClearButton>}
            </ButtonsContainer>
          }
        />
      )}
    </PickerWrapper>
  )
}

export default SingleDatePicker
