import styled from 'styled-components'

export const StyledTextArea = styled.textarea`
  border: none;
  outline: none;
  box-shadow: '0 0 4px rgba(0, 0, 0, 0.2)';
  min-width: 200px;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`
