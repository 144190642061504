import React from 'react'

const BulletedList = ({ active }) => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Editor"
          transform="translate(-720.000000, 0.000000)"
          fillRule="nonzero"
        >
          <g id="list_check_line" transform="translate(720.000000, 0.000000)">
            <path
              d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
              id="MingCute"
              fillRule="nonzero"
            ></path>
            <path
              d="M4.5,17.5 C5.32843,17.5 6,18.1716 6,19 C6,19.8284 5.32843,20.5 4.5,20.5 C3.67157,20.5 3,19.8284 3,19 C3,18.1716 3.67157,17.5 4.5,17.5 Z M20,18 C20.5523,18 21,18.4477 21,19 C21,19.5523 20.5523,20 20,20 L9,20 C8.44772,20 8,19.5523 8,19 C8,18.4477 8.44772,18 9,18 L20,18 Z M4.5,10.5 C5.32843,10.5 6,11.1716 6,12 C6,12.8284 5.32843,13.5 4.5,13.5 C3.67157,13.5 3,12.8284 3,12 C3,11.1716 3.67157,10.5 4.5,10.5 Z M20,11 C20.5523,11 21,11.4477 21,12 C21,12.51285 20.613973,12.9355092 20.1166239,12.9932725 L20,13 L9,13 C8.44772,13 8,12.5523 8,12 C8,11.48715 8.38604429,11.0644908 8.88337975,11.0067275 L9,11 L20,11 Z M4.5,3.5 C5.32843,3.5 6,4.17157 6,5 C6,5.82843 5.32843,6.5 4.5,6.5 C3.67157,6.5 3,5.82843 3,5 C3,4.17157 3.67157,3.5 4.5,3.5 Z M20,4 C20.5523,4 21,4.44772 21,5 C21,5.51283143 20.613973,5.93550653 20.1166239,5.9932722 L20,6 L9,6 C8.44772,6 8,5.55228 8,5 C8,4.48716857 8.38604429,4.06449347 8.88337975,4.0067278 L9,4 L20,4 Z"
              id="形状"
              fill={active ? '#1C274C' : '#a3a3a3'}
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BulletedList
