export const availableFiltersBySource: {
  [k: string]: { label: string; value: string }[]
} = {
  ga4: [
    {
      label: 'Channel',
      value: 'defaultChannelGroup',
    },
    {
      label: 'Source',
      value: 'source',
    },
    {
      label: 'Medium',
      value: 'medium',
    },
    {
      label: 'Campaign',
      value: 'campaignName',
    },
    {
      value: 'sourceMedium',
      label: 'Source / medium',
    },
    {
      value: 'sessionCampaignName',
      label: 'Session campaign',
    },
    {
      value: 'sessionDefaultChannelGroup',
      label: 'Session default channel group',
    },
    {
      value: 'sessionMedium',
      label: 'Session medium',
    },
    {
      value: 'sessionSource',
      label: 'Session source',
    },
    {
      value: 'firstUserCampaignName',
      label: 'First user campaign',
    },
    {
      value: 'firstUserDefaultChannelGroup',
      label: 'First user default channel group',
    },
    {
      value: 'firstUserMedium',
      label: 'First user medium',
    },
    {
      value: 'firstUserSource',
      label: 'First user source',
    },
    {
      value: 'firstUserSourceMedium',
      label: 'First user source / medium',
    },
    {
      value: 'firstUserSourcePlatform',
      label: 'First user source platform',
    },
    {
      value: 'eventName',
      label: 'Event Name',
    },
  ],
}
