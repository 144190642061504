import React from 'react'

import {
  Wrapper,
  IconWrapper,
  Container,
  Description,
  IaDescriptionTitle,
  IaDescriptionTitleContainer,
  Footer,
  ErrorDescription,
  ErrorContainer,
} from './style'
import { IaIcon } from './IaIcon'

interface IaTextPanelProps {
  aiDescription?: string
  error?: string
  widgetId?: string
}

const VITE_AI_DESCRIPTION_ENABLED =
  (import.meta as any).env.VITE_AI_DESCRIPTION_ENABLED === 'true'

export const PowerAIDescription = ({
  aiDescription,
  error,
  widgetId,
}: IaTextPanelProps) => {
  if (!VITE_AI_DESCRIPTION_ENABLED) {
    return null
  }

  if (!aiDescription && !error) {
    return null
  }

  if (error) {
    return (
      <Wrapper key={`ai-description-${widgetId}-error`}>
        <IconWrapper>
          <IaIcon color="red"></IaIcon>
        </IconWrapper>
        <ErrorContainer>
          <ErrorDescription dangerouslySetInnerHTML={{ __html: error }} />
        </ErrorContainer>
      </Wrapper>
    )
  }

  return (
    <Wrapper key={`ai-description-${widgetId}-message`}>
      <IconWrapper>
        <IaIcon color="blue"></IaIcon>
      </IconWrapper>
      <Container>
        <Description
          dangerouslySetInnerHTML={{ __html: aiDescription }}
        ></Description>
        <Footer>
          <IaDescriptionTitleContainer>
            <IaDescriptionTitle>
              AI Powered by Master Metrics
            </IaDescriptionTitle>
          </IaDescriptionTitleContainer>
        </Footer>
      </Container>
    </Wrapper>
  )
}
